import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Heart from "../Assets/Assets_heart-filled-svgrepo-com.svg";
import Compare from "../Assets/git-compare-svgrepo-com.svg";
import { faUser, faTrashAlt, faMapMarkerAlt, faTags, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import '../css/Myadss.css';

const FavoritePageCard = ({ data }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [currentproduct,setCurrentproduct]=useState(null);
    const images = Array.isArray(data.images)
  ? data.images
  : typeof data.images === "string"
  ? decodeHtmlEntities(data.images)
      .replace(/^\[|]$/g, "") // Remove brackets if present
      .split(",")
      .map((image) => image.trim().replace(/^"|"$/g, "")) // Trim and remove quotes
  : [];

    const handlePrevImage = () => {
        setCurrentImage((prev) => (prev === 0 ? data.images.length - 1 : prev - 1));
    };
const handleNextImage = () => {
    setCurrentImage((prev) => {
        // If it's the last image, go back to the first image (index 0)
        return prev === images.length - 1 ? 0 : prev + 1;
    });
};


    

    const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonType) => {
    setActiveButton((prev) => (prev === buttonType ? null : buttonType));
  };
  function decodeHtmlEntities(str) {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = str;
    return textarea.value;
  }
  const handleLikeToggle = async () => {
    try {
      const requestBody ={productId:data.productId};
      console.log("hello",requestBody);
        await axios.post(`${process.env.REACT_APP_BACK_URL}/api/prod/unlike`, requestBody, { withCredentials: true });
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  return (
    <div className="fpc-container">
      <div className="fpc-image-section">
        <div className="fpc-image-slider" style={{ transform: `translateX(-${currentImage * 100}%)` }}>
          {images.map((image, index) => (
            <div key={index} className="fpc-image-container">
              <img src={image} alt={`Product ${index + 1}`} className="fpc-image" />
            </div>
          ))}
        </div>
        <button className="fpc-arrow-btn fpc-left" onClick={handlePrevImage} aria-label="Previous image">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button className="fpc-arrow-btn fpc-right" onClick={handleNextImage} aria-label="Next image">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <div className="fpc-carousel-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`fpc-carousel-dot ${index === currentImage ? 'fpc-active' : ''}`}
              onClick={() => setCurrentImage(index)}
            />
          ))}
        </div>
      </div>
      <div className="fpc-content">
        <div className="fpc-header">
          <h3 className="fpc-title">{data.adName}</h3>
       
        </div>
        <div className="fpc-info">
          <p className="fpc-location">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="fpc-info-icon" />
            {data.location}
          </p>
          <p className="fpc-price">
            <FontAwesomeIcon icon={faTags} className="fpc-info-icon" />
            ₹{data.price}/Day
          </p>
          <p className="fpc-category">
            <FontAwesomeIcon icon={faTags} className="fpc-info-icon" />
            {data.category}
          </p>
        </div>
        <div className="fpc-footer">
          <div className="fpc-user-info">
            <div className="fpc-user-avatar">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <span className="fpc-username">{data.Username}</span>
          </div>
          <div className="fpc-actions">
          <div className="actions">
   
          
   <button
   className={`compare ${activeButton === "compare" ? "active" : ""}`}
   onClick={() => handleButtonClick("compare")}
 >
   <img src={Compare} alt="Compare" />
   <span className="tooltip" >Add to Compare</span>
 </button>

 <button
   className={`like ${activeButton === "like" ? "active" : ""}`}
   onClick={handleLikeToggle}
 >
   <img src={Heart} alt="Like" />
   <span className="tooltip" >Remove from Favorites</span>
 </button>
   </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritePageCard;

