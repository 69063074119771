// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
    border: 5px solid white;
    border-radius: 15px;
    overflow: hidden;
    width: 20%;
    margin-left: 10px;
  }
  
  .carousel-root {
    margin-left: 2vw;
    margin-right: 2vw;
  }
  
  .carousel img {
    width: 100%;
    height: 19vh;
    object-fit: cover;
  }
  
  /* Skeleton loader styles */
  .carousel-skeleton {
    border: 5px solid white;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    margin-left: 10px;
    background-color: #f0f0f0;
  }
  
  .skeleton-slide {
    width: 100%;
    height: 19vh;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  /* For tablets (width between 768px and 1024px) */
  @media (min-width: 768px) and (max-width: 1024px) {
    .carousel img,
    .skeleton-slide {
      height: 25vh;
    }
  }
  
  @media (max-width: 767px) {
    .carousel-root {
      margin-top: 12px;
    }
  }
  
  /* For desktops (width above 1024px) */
  @media (min-width: 1024px) {
    .carousel img,
    .skeleton-slide {
      height: 30vh;
    }
  }
  
  `, "",{"version":3,"sources":["webpack://./src/css/corousal.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,UAAU;IACV,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;EAEA,2BAA2B;EAC3B;IACE,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,iBAAiB;IACjB,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yEAAyE;IACzE,0BAA0B;IAC1B,gCAAgC;EAClC;;EAEA;IACE;MACE,2BAA2B;IAC7B;IACA;MACE,4BAA4B;IAC9B;EACF;;EAEA,iDAAiD;EACjD;IACE;;MAEE,YAAY;IACd;EACF;;EAEA;IACE;MACE,gBAAgB;IAClB;EACF;;EAEA,sCAAsC;EACtC;IACE;;MAEE,YAAY;IACd;EACF","sourcesContent":[".carousel {\n    border: 5px solid white;\n    border-radius: 15px;\n    overflow: hidden;\n    width: 20%;\n    margin-left: 10px;\n  }\n  \n  .carousel-root {\n    margin-left: 2vw;\n    margin-right: 2vw;\n  }\n  \n  .carousel img {\n    width: 100%;\n    height: 19vh;\n    object-fit: cover;\n  }\n  \n  /* Skeleton loader styles */\n  .carousel-skeleton {\n    border: 5px solid white;\n    border-radius: 15px;\n    overflow: hidden;\n    width: 100%;\n    margin-left: 10px;\n    background-color: #f0f0f0;\n  }\n  \n  .skeleton-slide {\n    width: 100%;\n    height: 19vh;\n    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);\n    background-size: 200% 100%;\n    animation: loading 1.5s infinite;\n  }\n  \n  @keyframes loading {\n    0% {\n      background-position: 200% 0;\n    }\n    100% {\n      background-position: -200% 0;\n    }\n  }\n  \n  /* For tablets (width between 768px and 1024px) */\n  @media (min-width: 768px) and (max-width: 1024px) {\n    .carousel img,\n    .skeleton-slide {\n      height: 25vh;\n    }\n  }\n  \n  @media (max-width: 767px) {\n    .carousel-root {\n      margin-top: 12px;\n    }\n  }\n  \n  /* For desktops (width above 1024px) */\n  @media (min-width: 1024px) {\n    .carousel img,\n    .skeleton-slide {\n      height: 30vh;\n    }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
