import React, { useState, useRef, useEffect } from "react"
import ProductCard from "./productCard"
import axios from "axios"
import useStore from "../components/authmiddle/store"
import CompareView from "./compare-view"
import Compare from "../Assets/git-compare-svgrepo-com.svg"

const categories = [
  "All",
  "Bikes",
  "Cars",
  "Electronics",
  "Events",
  "Freelancers",
  "Furniture",
  "Heavy Vehicles",
  "Photoshoots",
  "Property",
]

const SkeletonProductCard = () => (
  <div className="product-card" style={{ border: "1px solid #eee", borderRadius: "8px", padding: "16px" }}>
    <div style={{ width: "100%", height: "190px", backgroundColor: "#f0f0f0", marginBottom: "16px" }}></div>
    <div className="product-info">
      <div style={{ height: "24px", width: "75%", backgroundColor: "#f0f0f0", marginBottom: "8px" }}></div>
      <div style={{ height: "16px", width: "50%", backgroundColor: "#f0f0f0", marginBottom: "8px" }}></div>
      <div style={{ height: "20px", width: "25%", backgroundColor: "#f0f0f0", marginBottom: "8px" }}></div>
    </div>
    <div className="product-actions" style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ height: "32px", width: "32px", borderRadius: "50%", backgroundColor: "#f0f0f0" }}></div>
      <div style={{ height: "32px", width: "32px", borderRadius: "50%", backgroundColor: "#f0f0f0" }}></div>
      <div style={{ height: "32px", width: "32px", borderRadius: "50%", backgroundColor: "#f0f0f0" }}></div>
    </div>
  </div>
)

const ProductList = () => {
  const { isAuthenticated } = useStore()
  const [activeCategory, setActiveCategory] = useState("All")
  const productListRef = useRef(null)
  const firstProductCardRef = useRef(null)
  const [productCardWidth, setProductCardWidth] = useState(0)
  const [distTrav, setDistTrav] = useState(0)
  const [isMobileCarousel, setIsMobileCarousel] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [products, setProducts] = useState([])
  const [compareProducts, setCompareProducts] = useState([])
  const [showComparison, setShowComparison] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleCompare = (product) => {
    if (compareProducts.find((p) => p.productId === product.productId)) {
      setCompareProducts(compareProducts.filter((p) => p.productId !== product.productId))
    } else if (compareProducts.length < 3) {
      setCompareProducts([...compareProducts, product])
    } else {
      alert("You can compare up to 3 products")
    }
  }

  const removeFromComparison = (productId) => {
    setCompareProducts(compareProducts.filter((p) => p.productId !== productId))
  }

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true)
      try {
        let response
        if (isAuthenticated) {
          response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/prod/fetchProd?cat=${activeCategory}`, {
            withCredentials: true,
          })
        } else {
          response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/prod/fetchProd-v1?cat=${activeCategory}`)
        }
        setProducts(response.data.data)
      } catch (error) {
        console.error("Error fetching products:", error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchProducts()
  }, [activeCategory, isAuthenticated])

  useEffect(() => {
    if (firstProductCardRef.current) {
      setProductCardWidth(firstProductCardRef.current.clientWidth)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setIsMobileCarousel(width >= 768 && width <= 991)
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleCategoryClick = (category) => {
    setActiveCategory(category)
  }

  const scrollToIndex = (newIndex) => {
    if (newIndex >= 0 && newIndex < products.length) {
      setActiveIndex(newIndex)
      const newDistTrav = newIndex * (productCardWidth + 15)
      setDistTrav(newDistTrav)
      productListRef.current.scrollTo({ left: newDistTrav, behavior: "smooth" })
    }
  }

  const handleResize = () => {
    if (firstProductCardRef.current) {
      const width = firstProductCardRef.current.clientWidth
      setProductCardWidth(width)
      const newDistTrav = activeIndex * (width + 15)
      setDistTrav(newDistTrav)
      productListRef.current.scrollTo({ left: newDistTrav })
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [activeIndex])

  const scrollLeft = () => scrollToIndex(activeIndex - 1)
  const scrollRight = () => scrollToIndex(activeIndex + 1)

  return (
    <div className="product-Supreme">
      <div className="product-list-container">
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Explore Featured Listings</h1>

        <div
          className="category-buttons"
          style={{ display: "flex", overflowX: "auto", gap: "10px", marginBottom: "20px" }}
        >
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${activeCategory === category ? "active" : ""}`}
              onClick={() => handleCategoryClick(category)}
              style={{
                padding: "8px 16px",
                border: "none",
                borderRadius: "4px",
                backgroundColor: activeCategory === category ? "#308616" : "#f2f4f8",
                color: activeCategory === category ? "white" : "#73818c",
                cursor: "pointer",
              }}
            >
              {category}
            </button>
          ))}
        </div>

        {compareProducts.length > 1 && (
          <button
            className="compare-button"
            onClick={() => setShowComparison(true)}
            style={{
              position: "fixed",
              bottom: "24px",
              right: "24px",
              // backgroundColor: "#ff6b4a",
              color: "white",
              padding: "12px 24px",
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              zIndex: 40,
            }}
          >
            <img
              src={Compare || "/placeholder.svg"}
              alt="Compare"
              className="compare-icon"
              style={{ width: "20px", height: "20px", filter: "brightness(0) invert(1)" }}
            />
            Compare ({compareProducts.length})
          </button>
        )}

        <div className="product-list-wrapper">
          <div
            className="product-list"
            ref={productListRef}
            style={{ display: "flex", overflowX: "auto", gap: "15px", padding: "20px 0" }}
          >
            {isLoading
              ? Array(5)
                  .fill()
                  .map((_, index) => <SkeletonProductCard key={index} />)
              : products.map((product, index) => {
                  const parsedImages = typeof product.images === "string" ? JSON.parse(product.images) : product.images
                  return (
                    <ProductCard
                      key={index}
                      ref={index === 0 ? firstProductCardRef : null}
                      images={parsedImages}
                      name={product.adName}
                      address={product.location}
                      price={product.price}
                      views={product.views}
                      initialLikes={product.likes}
                      category={product.category}
                      postDate={product.postDate}
                      email={product.email}
                      isliked={product.isLiked}
                      productId={product.productId}
                      onCompare={() =>
                        handleCompare({
                          productId: product.productId,
                          adName: product.adName,
                          images: parsedImages,
                          price: product.price,
                          category: product.category,
                          location: product.location,
                        })
                      }
                      isCompared={compareProducts.some((p) => p.productId === product.productId)}
                    />
                  )
                })}
          </div>
        </div>

        {!isMobileCarousel ? (
          <div
            className="arrow-btn-cont"
            style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}
          >
            <button
              className="arrow-btn left"
              onClick={scrollLeft}
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: activeIndex === 0 ? "#e5f4f4" : "#ddecf7",
                color: activeIndex === 0 ? "#d4e4e4" : "black",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              disabled={activeIndex === 0}
            >
              &larr;
            </button>
            <button
              className="arrow-btn right"
              onClick={scrollRight}
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: activeIndex === products.length - 1 ? "#e5f4f4" : "#ddecf7",
                color: activeIndex === products.length - 1 ? "#d4e4e4" : "black",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              disabled={distTrav === products.length - 1}
            >
              &rarr;
            </button>
          </div>
        ) : (
          <div
            className="carousel-navigator"
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}
          >
            <div className="dots" style={{ display: "flex", gap: "8px" }}>
              {products.slice(0, -1).map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === activeIndex ? "active" : ""}`}
                  style={{
                    width: index === activeIndex ? "20px" : "8px",
                    height: "8px",
                    backgroundColor: index === activeIndex ? "#308616" : "#dceaf2",
                    borderRadius: "4px",
                    transition: "width 0.3s",
                  }}
                />
              ))}
            </div>
            <div className="arrow-btn-cont" style={{ display: "flex", gap: "20px" }}>
              <button
                className="arrow-btn left"
                onClick={scrollLeft}
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: activeIndex === 0 ? "#e5f4f4" : "#ddecf7",
                  color: activeIndex === 0 ? "#d4e4e4" : "black",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                disabled={activeIndex === 0}
              >
                &larr;
              </button>
              <button
                className="arrow-btn right"
                onClick={scrollRight}
                style={{
                  width: "50px",
                  height: "50px",
                  backgroundColor: activeIndex === products.length - 1 ? "#e5f4f4" : "#ddecf7",
                  color: activeIndex === products.length - 1 ? "#d4e4e4" : "black",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                disabled={distTrav === products.length - 1}
              >
                &rarr;
              </button>
            </div>
          </div>
        )}

        {showComparison && compareProducts.length > 1 && (
          <CompareView
            products={compareProducts}
            onClose={() => setShowComparison(false)}
            onRemove={removeFromComparison}
          />
        )}
      </div>
    </div>
  )
}

export default ProductList

