// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cursor-dot {
    position: fixed;
    width: 15px;
    height: 15px;
    background-color: #c4ecca;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 1000;
    transition: transform 1.5s ease;
    transition-delay: 1s; /* Adds a delay before the transition starts */
}
`, "",{"version":3,"sources":["webpack://./src/css/cursorfollower.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,oBAAoB;IACpB,gCAAgC;IAChC,aAAa;IACb,+BAA+B;IAC/B,oBAAoB,EAAE,8CAA8C;AACxE","sourcesContent":[".cursor-dot {\n    position: fixed;\n    width: 15px;\n    height: 15px;\n    background-color: #c4ecca;\n    border-radius: 50%;\n    pointer-events: none;\n    transform: translate(-50%, -50%);\n    z-index: 1000;\n    transition: transform 1.5s ease;\n    transition-delay: 1s; /* Adds a delay before the transition starts */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
