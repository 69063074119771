import React,{useEffect} from 'react';
import '../css/AboutUs.css';
import Aboutus from '../Assets/AboutUs.png'
import Navbar from './navbar';
import Footer from './footer';
import { useNavigate } from 'react-router-dom';

export default function AboutUs() {
    const navigate=useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
      }, []);
  return (
    <>
        <Navbar/>
  
    <div className="container">
      {/* <header className="header">
        <div className="logo">
          <span className="logo-text">ABOUT</span>
          <span className="logo-highlight">US</span>
        </div>
        <nav className="nav-links">
          <a href="#home" className="nav-link">Home</a>
          <a href="#about" className="nav-link">About us</a>
        </nav>
      </header> */}

      <main>
        <section className="hero">
          <div className="hero-content">
            <div className="hero-image-wrapper">
              <div className="hero-image-overlay"></div>
              <img 
                src={Aboutus}
                alt="Coffee table with laptop" 
                className="hero-image"
              />
            </div>
            <div className="hero-text">
              <div className="hero-text-content">
                <p className="hero-description">
                launched in 2024 we are marketplace connects renters to renters where people can rent out their items and give things on rent nearby we help nearby rental business to grow with us and helping renting community to grow
                </p>
                <button className="cta-button" onClick={()=>{navigate('/search')}}>
                  Explore Listings
                  <span className="button-arrow">→</span>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="stats">
          <h2 className="section-title">
            <span className="title-line">We are the best</span>
            <span className="title-highlight">rental ads platform</span>
          </h2>
          <div className="stats-grid">
            <div className="stat-item">
              <h3 className="stat-number">120<span className="plus">+</span></h3>
              <p className="stat-label">Listings added</p>
            </div>
            <div className="stat-item">
              <h3 className="stat-number">2.7<span className="plus">+</span></h3>
              <p className="stat-label">Daily matches</p>
            </div>
            <div className="stat-item">
              <h3 className="stat-number">20k<span className="plus">+</span></h3>
              <p className="stat-label">Registered users</p>
            </div>
            <div className="stat-item">
              <h3 className="stat-number">50<span className="plus">+</span></h3>
              <p className="stat-label">Quality awards</p>
            </div>
          </div>
        </section>

        <section className="how-it-works">
          <h2 className="section-title">
            <span className="title-line">How Goleezy works</span>
            <span className="title-highlight">the best search experience</span>
          </h2>
          <div className="steps">
            <div className="step">
              <div className="step-icon">
                <span className="icon">🔍</span>
              </div>
              <h3 className="step-title">Search anything you want</h3>
              <p className="step-description">Find your perfect match with our advanced search</p>
            </div>
            <div className="step">
              <div className="step-icon">
                <span className="icon">👥</span>
              </div>
              <h3 className="step-title">Find your listing with</h3>
              <p className="step-description">Connect with the right opportunities</p>
            </div>
            <div className="step">
              <div className="step-icon">
                <span className="icon">📧</span>
              </div>
              <h3 className="step-title">Explore the best</h3>
              <p className="step-description">Discover top-rated options in your search</p>
            </div>
          </div>
        </section>
      </main>
    </div>
    <Footer/>
    </>
  );
}
