import React from "react"

const Skeleton = () => (
  <div className="skeleton-card">
    <div className="skeleton-image"></div>
    <div className="skeleton-text"></div>
  </div>
)

export default Skeleton

