import React,{useEffect} from "react";
import "../css/PrivacyPolicy.css";
import Navbar from "./navbar";
import Footer from "./footer";

const PrivacyPolicy = () => {
 useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  return (
    <>
    <Navbar/>
    <div className="privacy-policy-container">
      <h1 className="privacy-h1">Privacy Policy</h1>
      <p className="privacy-p">Last Updated: 06/06/2023</p>

      <p className="privacy-p">
        Welcome to goleezy. We respect your privacy and are committed to protecting
        your personal information. This Privacy Policy explains how we collect,
        use, and safeguard your information when you visit our Website.
      </p>

      <div className="privacy-section">
        <h2 className="privacy-h2">1. Information We Collect</h2>
        <p className="privacy-p">We collect the following personal information from you:</p>
        <ul className="term-ul">
          <li className="term-li">Mobile Number: Collected for verification purposes.</li>
          <li className="term-li">Email Address: Collected for verification purposes and to communicate with you if necessary.</li>
        </ul>
      </div>

      <div className="privacy-section">
        <h2 className="privacy-h2">2. How We Use Your Information</h2>
        <p className="privacy-p">
          The personal information we collect is used solely for the following purposes:
        </p>
        <ul className="term-ul">
          <li className="term-li">Verification: To verify your identity and ensure the security of our marketplace.</li>
          <li className="term-li">Communication: To communicate with you regarding your account or any inquiries you may have.</li>
        </ul>
      </div>

      <div className="privacy-section">
        <h2 className="privacy-h2">3. Information Sharing</h2>
        <p className="privacy-p">
          We do not share, sell, or rent your personal information to third parties. Your information is used exclusively by goleezy for the purposes outlined in this Privacy Policy.
        </p>
      </div>

      <div className="privacy-section">
        <h2 className="privacy-h2">4. Data Security</h2>
        <p className="privacy-p">
          We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include:
        </p>
        <ul className="term-ul">
          <li className="term-li">Secure servers and encryption.</li>
          <li className="term-li">Regular security assessments and updates.</li>
        </ul>
      </div>

      <div className="privacy-section">
        <h2 className="privacy-h2">5. Your Rights</h2>
        <p className="privacy-p">You have the following rights regarding your personal information:</p>
        <ul className="term-ul">
          <li className="term-li">Access: You can request access to the personal information we hold about you.</li>
          <li className="term-li">Correction: You can request correction of any inaccurate or incomplete information.</li>
          <li className="term-li">Deletion: You can request the deletion of your personal information.</li>
        </ul>
        <p className="privacy-p">
          To exercise any of these rights, please contact us at info@goleezy.in.
        </p>
      </div>

      <div className="privacy-section">
        <h2 className="privacy-h2">6. Cookies and Tracking Technologies</h2>
        <p className="privacy-p">
          We do not use cookies or other tracking technologies to collect personal information.
        </p>
      </div>

      <div className="privacy-section">
        <h2 className="privacy-h2">7. Changes to This Privacy Policy</h2>
        <p className="privacy-p">
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new Privacy Policy on our Website and updating the “Last Updated” date.
        </p>
      </div>

      <div className="privacy-section">
        <h2 className="privacy-h2">8. Contact Us</h2>
        <p className="privacy-p">
          If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </p>
        <p className="privacy-p">Email: <a href="mailto:info@goleezy.in" className="refund-a">info@goleezy.in</a></p>
        <p className="privacy-p">Address: Sector 32, Ludhiana, India</p>
      </div>

      <p className="privacy-p">
        By using our Website, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
      </p>

      <p className="privacy-p">Thank you for trusting goleezy with your personal information.</p>

      <p className="privacy-p">Team Goleezy</p>
    </div>
    <Footer/>
    </>
  );
};

export default PrivacyPolicy;
