

// import React, { useState } from "react";
// import "../../css/MyOrders.css";

// const OrderList = [
//   { title: "30 days showcase", order: "#11686", created: "13/10/2024", price: "₹0.00", status: "Completed" },
//   { title: "30 days showcase", order: "#11182", created: "11/07/2024", price: "₹99.00", paymentMethod: "PhonePe Payment Solutions", status: "Failed" },
//   { title: "7 days showcase", order: "#10294", created: "16/06/2024", price: "₹49.00", paymentMethod: "PhonePe Payment Solutions", status: "Failed" },
//   { title: "30 days showcase", order: "#11687", created: "13/10/2024", price: "₹0.00", status: "Completed" },
//   { title: "30 days showcase", order: "#11189", created: "11/07/2024", price: "₹99.00", paymentMethod: "PhonePe Payment Solutions", status: "Completed" },
//   { title: "7 days showcase", order: "#10296", created: "16/06/2024", price: "₹49.00", paymentMethod: "PhonePe Payment Solutions", status: "Pending" },
// ];

// const MyOrders = () => {
//   const [selectedStatus, setSelectedStatus] = useState("All");

//   // Function to handle the tab selection
//   const handleTabClick = (status) => {
//     setSelectedStatus(status);
//   };

//   // Filter orders based on the selected status
//   const filteredOrders = selectedStatus === "All"
//     ? OrderList
//     : OrderList.filter(order => order.status === selectedStatus);

//   return (
//     <div className="my-order-container">
//       <div className="my-order-tab-container">
//         <button
//           className={`my-order-tab ${selectedStatus === "All" ? "active" : ""}`}
//           onClick={() => handleTabClick("All")}
//         >
//           All <span className='my-order-tab-span'>{OrderList.length}</span>
//         </button>
//         <button
//           className={`my-order-tab ${selectedStatus === "Completed" ? "active" : ""}`}
//           onClick={() => handleTabClick("Completed")}
//         >
//           Completed <span className='my-order-tab-span'>{OrderList.filter(order => order.status === "Completed").length}</span>
//         </button>
//         <button
//           className={`my-order-tab ${selectedStatus === "Pending" ? "active" : ""}`}
//           onClick={() => handleTabClick("Pending")}
//         >
//           Pending <span className='my-order-tab-span'>{OrderList.filter(order => order.status === "Pending").length}</span>
//         </button>
//         <button
//           className={`my-order-tab ${selectedStatus === "Failed" ? "active" : ""}`}
//           onClick={() => handleTabClick("Failed")}
//         >
//           Failed <span className='my-order-tab-span'>{OrderList.filter(order => order.status === "Failed").length}</span>
//         </button>
//       </div>

//       <div className="my-order-table-container">
//         <div className="my-order-row" id="offInMobile">
//           <div className="my-order-info" id="my-order-gen">
//             General Info
//           </div>
//           <div className="my-order-status" id="my-order-stat">
//             Status
//           </div>
//         </div>
        
//         {filteredOrders.map((order, index) => (
//           <div className="my-order-row" key={index}>
//             <div className="my-order-info">
//               <h3 className="my-order-title">
//                 {order.title} <span className="my-order-title-span">({order.order})</span>
//               </h3>
//               <p className="my-order-date-text">Created: <span className="my-order-date-text-span">{order.created}</span></p>
//               <p className="my-order-price-text">{order.price}</p>
//               {order.paymentMethod && <span className="my-order-payment-method">via {order.paymentMethod}</span>}
//             </div>
//             <div className="my-order-status">
//               <span className={`my-order-status-badge ${order.status.toLowerCase()}`}>
//                 {order.status}
//               </span>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Result Counter */}
//       <div className="my-order-result-counter">
//         Showing <span className="my-order-result-counter-span">1</span> to <span className="my-order-result-counter-span">{filteredOrders.length}</span> of <span className="my-order-result-counter-span">{OrderList.length}</span> results
//       </div>
//     </div>
//   );
// };

// export default MyOrders;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/MyOrders.css";

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  // Fetch orders from the backend
  const fetchOrders = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/order/getOrders?page=${page}&limit=5`,{
        withCredentials:true
      });
      const { data, totalPages } = response.data;

      setOrders((prevOrders) => [...prevOrders, ...data]); // Append new data
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Failed to fetch orders", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrders(); // Initial fetch
  }, []);

  // Filter orders based on selected status
  const filteredOrders =
    selectedStatus === "All"
      ? orders
      : orders.filter((order) => order.status === selectedStatus);

  // Handle Load More
  const loadMore = () => {
    if (currentPage < totalPages) {
      fetchOrders(currentPage + 1);
    }
  };

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits for day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits for month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  



  return (
    <div className="my-order-container">
      <div className="my-order-tab-container">
        <button
          className={`my-order-tab ${selectedStatus === "All" ? "active" : ""}`}
          onClick={() => setSelectedStatus("All")}
        >
          All <span className="my-order-tab-span">{orders.length}</span>
        </button>
        <button
          className={`my-order-tab ${
            selectedStatus === "completed" ? "active" : ""
          }`}
          onClick={() => setSelectedStatus("completed")}
        >
          Completed{" "}
          <span className="my-order-tab-span">
            {orders.filter((order) => order.status === "completed").length}
          </span>
        </button>
        <button
          className={`my-order-tab ${
            selectedStatus === "failed" ? "active" : ""
          }`}
          onClick={() => setSelectedStatus("failed")}
        >
          Failed{" "}
          <span className="my-order-tab-span">
            {orders.filter((order) => order.status === "failed").length}
          </span>
        </button>
      </div>

      <div className="my-order-table-container">
        <div className="my-order-row" id="offInMobile">
          <div className="my-order-info" id="my-order-gen">
            General Info
          </div>
          <div className="my-order-status" id="my-order-stat">
            Status
          </div>
        </div>

        {filteredOrders.map((order, index) => (
          <div className="my-order-row" key={index}>
            <div className="my-order-info">
              <h3 className="my-order-title">
                {order.packageName}{" "}
                <span className="my-order-title-span">({order.id})</span>
              </h3>
              <p className="my-order-date-text">
                Created:{" "}
                <span className="my-order-date-text-span">
                  {order.createdAt}
                </span>
              </p>
              <p className="my-order-date-text">
                ExpiredAt:{" "}
                <span className="my-order-date-text-span">
                  { formatTimestampToDate(order.expiredAt)}
                </span>
              </p>
              <p className="my-order-price-text">₹{order.packagePrice}</p>
              {order.paymentMethod && (
                <span className="my-order-payment-method">
                  via {order.paymentMethod}
                </span>
              )}
            </div>
            <div className="my-order-status">
              <span
                className={`my-order-status-badge ${order.status.toLowerCase()}`}
              >
                {order.status}
              </span>
            </div>
          </div>
        ))}
      </div>

      {currentPage < totalPages && (
        <button
          className="my-order-load-more-button"
          onClick={loadMore}
          disabled={loading}
        >
          {loading ? "Loading..." : "Load More"}
        </button>
      )}

      <div className="my-order-result-counter">
        Showing{" "}
        <span className="my-order-result-counter-span">{filteredOrders.length}</span>{" "}
        of{" "}
        <span className="my-order-result-counter-span">{orders.length}</span> results
      </div>
    </div>
  );
};

export default MyOrders;
