// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.term-container {
    padding: 20px;
    margin: auto;
    max-width: 900px;
    line-height: 1.6;
    color: #333;
  }
  
  .term-h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: #374b5c;
    font-weight: 800;
  }
  
  .term-h2 {
    font-size: 2rem;
    margin-top: 20px;
    color: #374b5c;
    font-weight: 800;
  }
  
  .term-p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #73818c;
    text-align: justify;
  }
  
  .term-ul {
    margin: 20px 0;
    padding-left: 20px;
    list-style-type: disc; /* Ensures bullets appear */
    list-style-position: inside; /* Positions bullets inside the container */
  }
  
  .term-li {
    margin-bottom: 10px;
    font-size: 1.2rem;
   color: #73818c;
    text-align: justify;
  }
  
  .term-ul li::marker { /* Targets the bullet points */
    color: #374b5c; /* Sets the bullet point color */
  }
  
  
  @media (max-width: 768px) {
    .term-container {
      padding: 15px;
    }
  
    .term-h1 {
      font-size: 2rem;
    }
  
    .term-h2 {
      font-size: 1.5rem;
    }
  
    .term-p,
    .term-li {
      font-size: 1rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/terms.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,qBAAqB,EAAE,2BAA2B;IAClD,2BAA2B,EAAE,2CAA2C;EAC1E;;EAEA;IACE,mBAAmB;IACnB,iBAAiB;GAClB,cAAc;IACb,mBAAmB;EACrB;;EAEA,sBAAsB,8BAA8B;IAClD,cAAc,EAAE,gCAAgC;EAClD;;;EAGA;IACE;MACE,aAAa;IACf;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;;MAEE,eAAe;IACjB;EACF","sourcesContent":[".term-container {\n    padding: 20px;\n    margin: auto;\n    max-width: 900px;\n    line-height: 1.6;\n    color: #333;\n  }\n  \n  .term-h1 {\n    font-size: 2.5rem;\n    text-align: center;\n    margin-bottom: 20px;\n    color: #374b5c;\n    font-weight: 800;\n  }\n  \n  .term-h2 {\n    font-size: 2rem;\n    margin-top: 20px;\n    color: #374b5c;\n    font-weight: 800;\n  }\n  \n  .term-p {\n    font-size: 1.2rem;\n    margin-bottom: 10px;\n    color: #73818c;\n    text-align: justify;\n  }\n  \n  .term-ul {\n    margin: 20px 0;\n    padding-left: 20px;\n    list-style-type: disc; /* Ensures bullets appear */\n    list-style-position: inside; /* Positions bullets inside the container */\n  }\n  \n  .term-li {\n    margin-bottom: 10px;\n    font-size: 1.2rem;\n   color: #73818c;\n    text-align: justify;\n  }\n  \n  .term-ul li::marker { /* Targets the bullet points */\n    color: #374b5c; /* Sets the bullet point color */\n  }\n  \n  \n  @media (max-width: 768px) {\n    .term-container {\n      padding: 15px;\n    }\n  \n    .term-h1 {\n      font-size: 2rem;\n    }\n  \n    .term-h2 {\n      font-size: 1.5rem;\n    }\n  \n    .term-p,\n    .term-li {\n      font-size: 1rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
