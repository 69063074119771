import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Skeleton from './skeleton';
import "../css/discovermore.css"
const DiscoverMore = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/fetchdiscovermore`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearchSubmit = (query) => {
    if (query.trim()) {
      navigate("/search", { state: { query: query.trim() } });
    }
  };

  return (
    <div className='discover-Supreme'>
      <div className="discover-more-container">
        <div className="header">
          <h2>Discover more</h2>
          <button className="view-more">View More</button>
        </div>
        <div className="product-grid-discover">
          {isLoading
            ? Array(4).fill().map((_, index) => <Skeleton key={index} />)
            : data.map((product, index) => (
                <div className="product-card-discover" key={index} onClick={() => handleSearchSubmit(product.name.toLowerCase())}>
                  <div className='Product-image'>
                    <img src={product.image || "/placeholder.svg"} alt={product.name} className="product-image-discover" />
                    <div className="overlay"></div>
                    <button className="circular-button">
                      <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '20px' }} />
                    </button>
                  </div>
                  <div className="product-info-discover">
                    <p>{product.name}</p>
                  </div>
                </div>
              ))
          }
        </div>
      </div>
    </div>
  );
};

export default DiscoverMore;
