// CategoryCard.js
import React from "react";

function CategoryCard({ name, image ,handleSearchSubmit}) {
  return (
    <div className="category-card" onClick={()=>{handleSearchSubmit((name.toLowerCase()))}}>
      <img src={image} alt={name} className="category-image" />
      <h3>{name}</h3>
    </div>
  );
}

export default CategoryCard;
