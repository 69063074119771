import React, { useState, useRef } from "react"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "../../css/AddNew.css"
import axios from "axios"
import { motion, AnimatePresence } from "framer-motion"

const AddNew = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [category, setCategory] = useState("")
  const [adName, setAdName] = useState("")
  const [location, setLocation] = useState("")
  const [suggestions, setSuggestions] = useState([])
  const [images, setImages] = useState([])
  const [price, setPrice] = useState("")
  const [markerPosition, setMarkerPosition] = useState({ lat: 28.7041, lng: 77.1025 }) // Default: Delhi
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [autoCompleteEnabled, setAutoCompleteEnabled] = useState(false)
  const [description, setDescription] = useState("")
  const [submissionStatus, setSubmissionStatus] = useState(null) // Added submission status
  const mapRef = useRef(null)
  const autocompleteService = useRef(null)
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState)
    // Extract plain text from the editor content
    const textContent = newEditorState.getCurrentContent().getPlainText()
    setDescription(textContent) // Save plain text description
  }

  const categories = [
    "Bikes",
    "Books",
    "Cars",
    "Clothes",
    "Electronics",
    "Events",
    "Freelancers",
    "Furniture",
    "Games",
    "Heavy Vehicles",
    "Jewellery",
    "Laptops",
    "Others",
    "Photoshoots",
    "Property",
    "Shoes",
    "Shops",
  ]

  const handleEmptyState = () => {
    setCategory("")
    setAdName("")
    setLocation("")
    // setMapSrc("");
    setImages([])
    setPrice("")
    setEditorState(EditorState.createEmpty())
  }

  const handlePostAd = async () => {
    // const description = convertToRaw(editorState.getCurrentContent());

    if (!adName || !category || !description || !location || !images.length || !price) {
      alert("All fields are required, including a valid price, and you must upload between 3 and 5 images!")
      return
    }

    const parsedPrice = Number.parseFloat(price)

    if (isNaN(parsedPrice) || parsedPrice <= 0) {
      alert("Please enter a valid price greater than 0.")
      return
    }

    setIsSubmitting(true)

    const formData = new FormData()
    formData.append("adName", adName)
    formData.append("category", category)
    formData.append("description", description)
    formData.append("location", location)
    formData.append("lat", markerPosition.lat)
    formData.append("lng", markerPosition.lng)
    // formData.append("mapUrl", mapSrc);

    formData.append("price", parsedPrice)

    images.forEach((image, index) => {
      formData.append("images", image)
    })

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/prod/addProd`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })

      if (response.status === 201) {
        setSubmissionStatus("success")
        setTimeout(() => {
          setSubmissionStatus(null)
          handleEmptyState()
        }, 3000)
      }
      console.log(response)
    } catch (error) {
      console.error("Error posting ad:", error)
      setSubmissionStatus("error")
      setTimeout(() => {
        setSubmissionStatus(null)
      }, 3000)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files)
    if (files.length < 1) {
      alert("Please select a minimum 1 image")
      return
    }

    if (files.length > 5) {
      alert("You can upload a maximum of 5 images.")
      return
    }
    setImages(files)
  }

  const handleLocationInputChange = (e) => {
    const value = e.target.value
    setLocation(value)

    if (autocompleteService.current) {
      autocompleteService.current.getPlacePredictions({ input: value }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setSuggestions(predictions)
        } else {
          setSuggestions([])
        }
      })
    }
  }

  const handleSuggestionClick = (suggestion) => {
    setLocation(suggestion.description)
    setSuggestions([])
    geocodeAddress(suggestion.description)
  }

  const handleLocationEnter = () => {
    if (location.trim()) {
      geocodeAddress(location)
    }
  }

  const geocodeAddress = (address) => {
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results[0]) {
        const { lat, lng } = results[0].geometry.location
        setMarkerPosition({ lat: lat(), lng: lng() })
        if (mapRef.current) {
          mapRef.current.panTo({ lat: lat(), lng: lng() })
        }
      } else {
        console.error("Geocode was not successful for the following reason:", status)
      }
    })
  }

  const handleMapClick = async (event) => {
    const newLat = event.latLng.lat()
    const newLng = event.latLng.lng()

    setMarkerPosition({ lat: newLat, lng: newLng })

    if (autoCompleteEnabled && mapRef.current) {
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ location: { lat: newLat, lng: newLng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          const address = results[0].formatted_address
          setLocation(address) // Update location field
        } else {
          setLocation("") // Reset location if no address is found
          alert("No address found for the selected location.")
        }
      })
    }
  }

  const containerStyle = {
    width: "100%",
    height: "400px",
  }

  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   libraries: ["places"],
  // });

  // if (!isLoaded) return <div>Loading...</div>;

  return (
    <motion.div
      className="post-ad-page"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1>General Info</h1>

      <label htmlFor="adName">Ad Name*</label>
      <input
        id="adName"
        type="text"
        value={adName}
        onChange={(e) => setAdName(e.target.value)}
        className="inputbartext"
        placeholder="Enter Ad Name"
      />

      <label htmlFor="category">Category</label>
      <select id="category" value={category} onChange={(e) => setCategory(e.target.value)}>
        <option value="">Select a category</option>
        {categories.map((cat, index) => (
          <option key={index} value={cat}>
            {cat}
          </option>
        ))}
      </select>

      <label>Description</label>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={handleEditorChange}
      />

      <label>Address</label>
      <input
        type="text"
        value={location}
        onChange={handleLocationInputChange}
        onKeyDown={(e) => e.key === "Enter" && handleLocationEnter()}
        placeholder="Enter location"
        className="inputbartext"
      />
      <ul className="suggestions-list">
        {suggestions.map((suggestion, index) => (
          <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
            {suggestion.description}
          </li>
        ))}
      </ul>

      <div className="checkbox-container">
        <input
          type="checkbox"
          checked={autoCompleteEnabled}
          onChange={() => setAutoCompleteEnabled(!autoCompleteEnabled)}
          className="location-checkbox"
        />
        <label>Autocomplete address when marker position is changed</label>
      </div>

      <label>Google Map</label>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={12}
        onClick={handleMapClick}
        onLoad={(map) => {
          mapRef.current = map
          autocompleteService.current = new window.google.maps.places.AutocompleteService()
        }}
      >
        <Marker position={markerPosition} />
      </GoogleMap>

      <label htmlFor="imageUpload">Upload Images (1 to 5)</label>
      <div className="image-upload-container">
        <input
          id="imageUpload"
          type="file"
          multiple
          accept="image/*"
          onChange={handleImageUpload}
          className="image-upload-input addNewBtn"
        />
        <label htmlFor="imageUpload" className="image-upload-label">
          Choose Files
        </label>
      </div>
      <div className="image-preview">
        {images.map((image, index) => (
          <motion.div
            key={index}
            className="image-container"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <img
              src={URL.createObjectURL(image) || "/placeholder.svg"}
              alt={`Preview ${index + 1}`}
              className="image-preview-thumbnail"
            />
          </motion.div>
        ))}
      </div>

      <label htmlFor="price">Product Cost/Price*</label>
      <input
        id="price"
        type="number"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        placeholder="Enter price"
        className="inputbartext"
        step="0.01"
        min="0"
      />

      <motion.button
        className="addNewBtn"
        onClick={handlePostAd}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <motion.div
            className="spinner"
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Number.POSITIVE_INFINITY, ease: "linear" }}
          />
        ) : (
          "Post Your Ad"
        )}
      </motion.button>
      <AnimatePresence>
        {submissionStatus === "success" && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            style={{
              position: "fixed",
              bottom: 20,
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#4CAF50",
              color: "white",
              padding: "10px 20px",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            }}
          >
            Product posted successfully!
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default AddNew

