import React, { useState, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faPlus,faStar } from '@fortawesome/free-solid-svg-icons';
import {
  FaUser

} from "react-icons/fa";
const ReviewForm = ({productId}) => {
  const [text, setText] = useState(''); // Review text
  const [image, setImage] = useState(null); // Selected image
  const [minCharacters] = useState(10); // Minimum characters for review
  const [maxCharacters] = useState(200); // Maximum characters for review
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);
    const [rating, setRating] = useState(0); // Final saved rating
    const [hoverRating, setHoverRating] = useState(0); // Temporary hover rating
  
    // Update hover rating based on current star hover
    const handleMouseEnter = (star) => {
      setHoverRating(star);
    };
  
    // Save the last hovered rating when the mouse leaves
    const handleMouseLeave = () => {
      setRating(hoverRating);
    };

  // Handle text change
  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  // Handle image file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Validate the file type (optional)
    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (file && validFileTypes.includes(file.type)) {
      setImage(file);
      setErrorMessage('');
    } else {
      setErrorMessage('Please select a valid image (JPEG, PNG, JPG)');
    }
  };

  // Handle file input click
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(rating<=0)
    {
      setErrorMessage('please give rating');
      return;
    }
    // Validation checks
    if (text.length < minCharacters) {
      setErrorMessage(`Review must be at least ${minCharacters} characters.`);
      return;
    }
    if (!image) {
      setErrorMessage('Please upload an image.');
      return;
    }

    console.log(rating)

    // Prepare data for the API call
    const formData = new FormData();
    formData.append('reviewText', text);
    formData.append('image', image); // Append image to FormData
    formData.append('rating',rating)
    formData.append('productId',productId)
    try {
      // Call the API (replace the URL with your actual backend URL)
      const response = await axios.post(
       `${process.env.REACT_APP_BACK_URL}/api/prod/addReview`, // API endpoint
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Important for file upload
          },
          withCredentials: true,
        }
      );
      console.log('Review submitted successfully:', response.data);
      // Reset the form state
      setText('');
      setImage(null);
      setErrorMessage('');
      alert('Review submitted successfully!');
    } catch (error) {
      console.error('Error submitting review:', error);
     
    }
  };

  return (
    <div>
                        <div className="Ratings">
                        <div className="profile-section-cont">
                          <div className="profile-section">
                            <FaUser
                              style={{
                                fontSize: "30px",
                                color: "#374B5C",
                                marginRight: "10px",
                              }}
                            />
                          </div>
    
                          <p>
                            <b>Me</b>
                            <br></br>
                            Your opinion matters
                          </p>
                          
                          </div>
                          
    
                          {/* Rating */}
                          <div className="rating">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                onMouseEnter={() => handleMouseEnter(star)}
                onMouseLeave={handleMouseLeave}
                style={{
                  cursor: 'pointer',
                  color: star <= (hoverRating || rating) ? '#E9E017' : '#ccc',
                }}
              >
                <FontAwesomeIcon icon={faStar}  style={{height:'24px',width:'24px'}}/>
              </span>
            ))}
          </div>
                        </div>
    <form className="review-form" onSubmit={handleSubmit}>
      {/* Image upload section */}
      <div className="add-photos" onClick={handleUploadClick} style={{ cursor: 'pointer' }}>
        <FontAwesomeIcon className="add-photo-sp" icon={faPaperclip} />
        <p>{image ? 'Image Selected' : 'Add Photos'}</p> {/* Conditionally render text */}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }} // Hide the file input
        />
      </div>

      {/* Review textarea */}
      <textarea
        placeholder="Write your review"
        maxLength={maxCharacters}
        className="Rev_txt"
        value={text}
        onChange={handleTextChange}
      ></textarea>

      {/* Character count and minimum character validation */}
      <div className="character-info">
        <span className={`min-characters ${text.length >= minCharacters ? 'valid' : ''}`}>
          Minimum characters: {minCharacters}
        </span>
        <span className="character-count">
          {text.length}/{maxCharacters} characters
        </span>
      </div>

      {/* Error message */}
      {errorMessage && <p className="error-message" style={{bottom:'0.5rem'}}>{errorMessage}</p>}

      {/* Submit button */}
      <button type="submit" className="submit-rev-btn">
        <span>Submit Review</span> <FontAwesomeIcon icon={faPlus} />
      </button>
    </form>
    </div>
  );
};

export default ReviewForm;
