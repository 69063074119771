import React, { useState ,useEffect} from 'react';
import '../../css/settings.css';
import leftarrowmobile from "../../Assets/left-arrow-next-svgrepo-com.svg";
import leftarrowweb from "../../Assets/left-arrow-svgrepo-com (5).svg";
import Plus from "../../Assets/plus-svgrepo-com.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareFacebook, faSquareTwitter, faSquareInstagram, faSquareYoutube, faLinkedin, faTiktok, faTelegram, faEbay } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import Loader from '../loader';
const Settings = () => {
  const [formData, setFormData] = useState({
    displayName: '',
    accountType: 'public',
    companyInfo: '',
    phoneNumber: '',
    profileDescription: '',
    email: '',
    address: '',
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [emailData, setEmailData] = useState({
    currentEmail: formData.email, // Prefill with current email
    newEmail: '',
  });
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };
  const [profileImage, setProfileImage] = useState(); // For storing the selected profile image
  const [Profileblob, SetProfileblob] = useState(); // For storing the selected profile image
  
  const UploadProfilePic = async () => {
    console.log(profileImage)
    if (!profileImage) {
      alert("Please select an image before uploading.");
      return;
    }
  
    const formData = new FormData();
    formData.append("file", profileImage); // Key 'file' should match backend's expectation
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/update-profile-image`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data", // Required for file uploads
          },
        }
      );
  
      if (response.status === 200) {
        alert("Image uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading image:", error.response?.data || error.message);
      alert("Failed to upload image. Please try again.");
    }
  };
  useEffect(()=>{
    const GetProfilePic = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACK_URL}/api/get-profile-image`,{},
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response);
    setProfileImage("");
    setProfileImage(response.data.profileImageUrl);
      } catch (error) {
        console.error("Error fetching image:", error.response?.data || error.message);
        alert("Failed to fetch image. Please try again.");
      }
    };
    GetProfilePic();
  },[]);
  const user={
      displayName: '',
      accountType: '',
      companyInfo: '',
      phoneNumber: '',
      profileDescription: '',
      email: '',
      address: '',
  }
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACK_URL}/api/getUserDetails`,
          {},
          { withCredentials: true }
        );

        const data = response.data.data;

        setFormData({
          displayName: data.displayName,
          accountType: data.accountType,
          companyInformation: data.companyInformation,
          phoneNumber: data.phone,
          profileDescription: data.profileDescription,
          email: data.email,
          address: data.address,
        });
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    getUserDetails();
  }, []);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!emailData.currentEmail || !emailData.newEmail) {
      alert('Please enter both the current and new email.');
      return;
    }
    console.log('Email Change Data:', emailData);
  };
    const [isMobile,SetiisMobile]=useState(window.innerWidth)
  const [activeSection, setActiveSection] = useState('accountDetails');
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };
  
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (!passwordData.oldPassword || !passwordData.newPassword) {
      alert('Please fill in all fields');
      return;
    }
    console.log('Password Change Data:', passwordData);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formData);
  };

  const handleSubmitprofile = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/update-profile`, 
        formData, 
        { withCredentials: true },
        {
          headers: {
            'Content-Type': 'application/json', // Set content type
          },
        }
      );


      console.log('Updated profile:', response.data);
    } catch (error) {
      console.error('Error updating profile:', error.response?.data || error.message);
      alert('Failed to update profile. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? '' : section);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
    }
  };
  const handleImageChangeprofile = (e) => {
    const file = e.target.files[0];
    SetProfileblob(URL.createObjectURL(file));
    setProfileImage(file); // Ensure the file is set properly for upload
  };
  
  const SocialLinkField = ({ icon, label, name, placeholder, value, onChange }) => (
    <div className="social-link-field">
      {/* <label>{icon}</label> */}
        <label>{label}</label>
      <div className="input-container">
        <FontAwesomeIcon icon={icon} className="input-icon" />
        <input
          type="url"
          name={name}
          placeholder={placeholder}
          value={value || ''}
          onChange={onChange}
          className="social-link-input"
        />
      </div>
    </div>
  );
  
  
  if (isLoading) {
  return <Loader />;
}
  return (
    <div className="settings">
      {/* Account Details Section */}
      <Section
        title="Account Details"
        isVisible={activeSection === 'accountDetails'}
        toggleVisibility={() => toggleSection('accountDetails')}
      >
      <form className="account-details-form newform" onSubmit={handleSubmit}>
  <label>
    <label className='label-email1'> Display Name</label>
    <input
      type="text"
      name="displayName"
      value={formData.displayName}
      onChange={handleChange}
      className="common-input"
    />
  </label>
  <label>
    <label className='label-email1'> Account Type</label> 
    <select
      name="accountType"
      value={formData.accountType}
      onChange={handleChange}
      className="common-input"
    >
      <option value="Personal">Personal</option>
      <option value="Business">Business</option>
    </select>
  </label>
  <label>
    <label className='label-email1'> Company Information</label> 
    <input
      type="text"
      name="companyInfo"
      value={formData.companyInformation}
      onChange={handleChange}
      className="common-input"
    />
  </label>
  <label>
    <FontAwesomeIcon icon="fa-solid fa-mobile" />
    <label className='label-email1'>Phone Number</label>
    <input
      type="tel"
      name="phoneNumber"
      value={formData.phoneNumber}
      onChange={handleChange}
      className="common-input"
    />
  </label>
  <label>
    <label className='label-email1'>Profile Description</label>  
    <textarea
      name="profileDescription"
      value={formData.profileDescription}
      onChange={handleChange}
      className="common-input common-textarea"
    />
  </label>
  <label>
    <label className='label-email1'>Address</label> 
    <input
      type="text"
      name="address"
      value={formData.address}
      onChange={handleChange}
      className="common-input"
    />
  </label>
  <button className='Setting-Btn' type="submit" onClick={handleSubmitprofile} disabled={isLoading}>
    {isLoading ? 'Saving...' : 'Save Changes'}
  </button>
</form>

      </Section>

      {/* Profile Image Section */}
      <Section
        title="Profile Image"
        isVisible={activeSection === 'profileImage'}
        toggleVisibility={() => toggleSection('profileImage')}
      >
        <div className="profile-image-container">
          {/* Display the Profile Image */}
          {profileImage ? (
            <div className="profile-image">
             <img
  src={typeof profileImage === 'string' ? profileImage : Profileblob}
  alt="Profile"
/>

            </div>
          ) : (
            <div className="profile-image-placeholder">
              <p>No image selected</p>
            </div>
          )}

          {/* Add Image Section with dotted border */}
          <div className="add-image-section">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChangeprofile}
              style={{ display: 'none' }} // Hide the input
              id="file-input"
            />
            <img src={Plus}></img>
            <label htmlFor="file-input" className="add-image-label">
              <span className="add-image-text">Add new photo</span>
            </label>
          
          </div>
          <button className='Setting-Btn' onClick={UploadProfilePic}>submit</button>
        </div>
      </Section>

      {/* Social Links Section */}
      {/* Social Links Section */}
      <Section
  title="Social Links"
  isVisible={activeSection === 'socialLinks'}
  toggleVisibility={() => toggleSection('socialLinks')}
>
  <form className="account-details-form" onSubmit={handleSubmit}>
    <div className="social-links-container">
      <SocialLinkField
        icon={faSquareFacebook}
        label="Facebook"
        name="facebook"
        placeholder="Enter the url to your Facebook profile"
        value={formData.facebook}
        onChange={handleChange}
      />
      <SocialLinkField
        icon={faSquareTwitter}
        label="Twitter"
        name="twitter"
        placeholder="Enter the url to your Twitter profile"
        value={formData.twitter}
        onChange={handleChange}
      />
      <SocialLinkField
        icon={faSquareInstagram}
        label="Instagram"
        name="instagram"
        placeholder="Enter the url to your Instagram profile"
        value={formData.instagram}
        onChange={handleChange}
      />
      <SocialLinkField
        icon={faSquareYoutube}
        label="YouTube"
        name="youtube"
        placeholder="Enter the url to your YouTube profile"
        value={formData.youtube}
        onChange={handleChange}
      />
      <SocialLinkField
        icon={faLinkedin}
        label="LinkedIn"
        name="linkedin"
        placeholder="Enter the url to your LinkedIn profile"
        value={formData.linkedin}
        onChange={handleChange}
      />
    
      <SocialLinkField
        icon={faTelegram}
        label="Telegram"
        name="telegram"
        placeholder="Enter the url to your Telegram profile"
        value={formData.telegram}
        onChange={handleChange}
      />
  
    </div>
    <button className='Setting-Btn' type="submit">Save Changes</button>
  </form>
</Section>



      {/* Change Password Section */}
      <Section
  title="Change Password"
  isVisible={activeSection === 'changePassword'}
  toggleVisibility={() => toggleSection('changePassword')}
>
<form className="change-email-details-form" onSubmit={handlePasswordSubmit}>
<div className="two-columns">
  <label>
  <label className='label-email'>Old Password</label>  
    <input
      type="password"
      name="oldPassword"
      placeholder="Enter your old password"
      value={passwordData.oldPassword}
      onChange={handlePasswordChange}
      className='change-email-input'
      required
    />
  </label>
  <label>
   <label className='label-email'> New Password</label>
    <input
      type="password"
      name="newPassword"
      placeholder="Enter your new password"
      value={passwordData.newPassword}
      onChange={handlePasswordChange}
      required
       className='change-email-input'
    />
  </label>
  </div>
  <button className='Setting-Btn' type="submit">Change Password</button>
</form>

</Section>


      {/* Change Email Section */}
     {/* Change Email Section */}
<Section
  title="Change Email"
  isVisible={activeSection === 'changeEmail'}
  toggleVisibility={() => toggleSection('changeEmail')}
>
  <form className="change-email-details-form" onSubmit={handleEmailSubmit}>
  <div className="two-columns">
    <label>
      <label className='label-email'>Current Email</label>
      <input
        type="email"
        name="currentEmail"
        value={emailData.currentEmail}
        onChange={handleEmailChange}
        placeholder="Enter your current email"
        className="change-email-input"
        required
      />
    </label>
    <label>
     <label className='label-email'>New Email</label> 
      <input
        type="email"
        name="newEmail"
        value={emailData.newEmail}
        onChange={handleEmailChange}
        placeholder="Enter a new email"
        className="change-email-input"
        required
      />
    </label>
    </div>
    <button className='Setting-Btn' type="submit">Change Email</button>
  </form>
</Section>

    </div>
  );
};

const Section = ({ title, isVisible, toggleVisibility, children }) => (
  <div>
    <div className="Head-Setting">
      <h2>{title}</h2>
      <div className="downarrowsettingtab" onClick={toggleVisibility}>
        {window.innerWidth<= 768?(<img src={leftarrowmobile} alt="Toggle" />):(<img src={leftarrowweb} alt="Toggle" />)}
      </div>
    </div>
    <div className={`form-group ${isVisible ? 'slide-in' : 'slide-out'}`}>
      {children}
    </div>
  </div>
);

export default Settings;

