import React,{useEffect} from 'react';
import '../css/RefundPolicy.css';
import Navbar from './navbar';
import Footer from './footer';

const RefundPolicy = () => {
   useEffect(() => {
      window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

  return (
    <>
    <Navbar/>
    <div className="refund-policy-container">
      <h1 className="refund-h1">Goleezy.com Refund Policy</h1>
      <section className="refund-section">
        <h2 className="refund-h2">1. Introduction</h2>
        <p className="refund-p">
          At Goleezy.com, we strive to provide the best possible service to our users. This Refund Policy outlines the conditions
          under which we offer refunds for our services. Please read this policy carefully before purchasing any services from
          our website.
        </p>
      </section>
      <section className="refund-section">
        <h2 className="refund-h2">2. No Refund Policy for Featured Ad Packages</h2>
        <h3 className="refund-h3">2.1. Non-Refundable Services</h3>
        <p className="refund-p">
          All purchases of featured ad packages on Goleezy.com are final and non-refundable. Once you purchase a featured ad
          package, you will not be eligible for a refund under any circumstances.
        </p>
        <h3 className="refund-h3">2.2. Reason for No Refunds</h3>
        <p className="refund-p">
          The nature of featured ad packages requires significant resources and immediate allocation of space on our platform.
          Therefore, we cannot offer refunds for these services.
        </p>
      </section>
      <section className="refund-section">
        <h2 className="refund-h2">3. Exceptional Circumstances</h2>
        <h3 className="refund-h3">3.1. Service Interruptions</h3>
        <p className="refund-p">
          In the event that there are significant service interruptions or technical issues caused by Goleezy.com that prevent your
          ad from being displayed as purchased, we may, at our discretion, extend the duration of your ad display.
        </p>
        <h3 className="refund-h3">3.2. No Discretionary Refunds</h3>
        <p className="refund-p">There will be no discretionary refunds offered under any circumstances. All sales are final.</p>
      </section>
      <section className="refund-section">
        <h2 className="refund-h2">4. Contact Us</h2>
        <p className="refund-p">
          If you have any questions or concerns about our Refund Policy, please contact our customer service team:
        </p>
        <p className="refund-p">
          Email: <a href="mailto:info@goleezy.in" className="refund-a">info@goleezy.in</a>
        </p>
      </section>
      <section className="refund-section">
        <h2 className="refund-h2">5. Policy Updates</h2>
        <p className="refund-p">
          Goleezy.com reserves the right to update or change this Refund Policy at any time. Any changes will be posted on this
          page, and it is your responsibility to review this policy periodically to stay informed of any updates.
        </p>
        <p className="refund-p">
          By purchasing any services from Goleezy.com, you acknowledge that you have read, understood, and agree to be bound by
          this Refund Policy.
        </p>
      </section>
    </div>
    <Footer/>
    </>
  );
};

export default RefundPolicy;
