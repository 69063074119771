import React, { useRef,useState ,useEffect,useCallback} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser ,faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import '../css/message.css';
import axios from 'axios'
import useStore from '../components/authmiddle/store.js'
const ChatInterface = () => {

  const { socket, setSocket,userId } = useStore();

  const [currentUser, setCurrentUser] = useState(null); // To store the current user's data
  const [friends, setFriends] = useState([]); // To store friends' data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const [myMsg, setMyMsg] = useState("");
  const [friendId, setFriendId] = useState(null);
  const [myId, setMyId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [friendName, setFriendName] = useState("");
  const [onlineOrOffline, setOnlineOrOffline] = useState({});
  const messageContainerRef = useRef(null);
  const [friendImg,setFriendImg]=useState("")


  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  useEffect(() => {
    getFriends();
  }, []);


   // Handle friend online/offline status
   const handleOfflineUser = (data) => {
    handleOnlineOrOffline(data.email, 0);
  };

  const handleOnlineUser = (data) => {
    handleOnlineOrOffline(data.email, 1);
  };

  const handleOnlineOrOffline = useCallback(
    (email, isOnline) => {
      setOnlineOrOffline((prevStatus) => ({
        ...prevStatus,
        [email]: isOnline,
      }));
    },
    [onlineOrOffline]
  );

  const handleLoadNewChats = (data) => {
    console.log(data,'handleLoadNewChat')
    if (myId === data.receiver_email && friendId === data.sender_email) {
      if (data.message.trim() !== "") {
        setMessages((prevState) => [
          ...prevState,
          { who: "received",...data },
        ]);
      }
    }
  };

  const handleExistChats = (data) => {
    console.log(data)
    data.chats.forEach((chat) => {
      if (chat.message.trim() !== "") {
        if (myId === chat.sender_email && friendId === chat.receiver_email) {
          setMessages((prevState) => [
            ...prevState,
            { ...chat, who: "sent" },
          ]);
        } else if (myId === chat.receiver_email && friendId === chat.sender_email) {
          setMessages((prevState) => [
            ...prevState,
            { ...chat, who: "received" },
          ]);
        }
      }
    });
  };

  useEffect(() => {
    // if (!socket) return;

    socket?.on("getOnlineUser", handleOnlineUser);
    socket?.on("getOfflineUser", handleOfflineUser);
    socket?.on("loadNewChats", handleLoadNewChats);
    socket?.on("loadExistChats", handleExistChats);

    return () => {
      socket?.off("getOnlineUser", handleOnlineUser);
      socket?.off("getOfflineUser", handleOfflineUser);
      socket?.off("loadNewChats", handleLoadNewChats);
      socket?.off("loadExistChats", handleExistChats);
    };
  });


  const handleMyMsg = (e) => {
    setMyMsg(e.target.value);
  };

  const handleSubmit = (e) => {
    // if (e.key === "Enter") {
    //   postData();
    // }
    postData();
  };

  const postData = async () => {
    const data = {
      sender_email: myId,
      receiver_email: friendId,
      message: myMsg,
    };
   console.log(data)
    setMyMsg("")

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/saveChats`,
        data,
        {
          withCredentials:true
        }
      );
      if (response.status === 200) {
        console.log(response.data)
        if (response.data.chat.message.trim() !== "") {
          setMessages((prevState) => [
            ...prevState,
            { who: "sent", ...response.data.chat },
          ]);
           const d1={
            message:response.data.chat.message,
            receiver_email:response.data.chat.receiver_email,
           sender_email:response.data.chat.sender_email,
           date:response.data.chat.date,
           time:response.data.chat.time
           }
           console.log(d1)
          socket?.emit("newChats", d1);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFriends = async () => {
    try {
      // Call the backend API
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/getFriends`, {
        withCredentials: true, // Ensure cookies are sent with the request
      });

      // Destructure response data
      // const { currentUser: userData, friends: friendsData } = response.data;

      // Update the state
      setCurrentUser(response.data.currentUser);
      console.log(response.data.friends)
      setFriends((prevCard) =>
        prevCard
          ? [...prevCard, ...response.data.friends]
          : response.data.friends
      );
      console.log(response.data.friends)
      setMyId(response.data.currentUser.email)
      response.data.friends.map((friend) => {
        return handleOnlineOrOffline(friend.email, friend.isOnline);
      });
      setLoading(false); // Loading complete
    
    } catch (err) {
      console.error('Error fetching friends:', err);
      setError(err.message || 'Something went wrong');
      setLoading(false);
    }
  };

 

 



  const [open,setOpen]=useState(false);

  const handleOpen=()=>{
    setOpen(!open);
  }


  const handleFriend = ( name,email,img) => {
    // const id = e.currentTarget.getAttribute("data-id");
    setFriendName(name);
    setFriendId(email);
    setFriendImg(img)
    setMessages([]);
    setOpen(!open);
    console.log(name,email,myId)
    socket?.emit("existChats", { sender_email: myId, receiver_email: email });
  };

  return (
    <div className="chat-container">
      {/* Left Sidebar */}
      <div className={`sidebar ${open? 'hideSide':''}`} >
        <div className="sidebar-header">
          <h1>Conversations</h1>
        </div>
        <div className="conversation-list">
          {friends.map((conversation) => (
            <div key={conversation.email} className="conversation-item"  onClick={(event) => handleFriend(conversation.displayName,conversation.email,conversation.profileimage)}>
              <div className="avatar-container">
              {/* <div className='avatar-icon-cont'>
              <FontAwesomeIcon icon={faUser} className='avatar-icon'/>
              </div> */}
              <div className='avatar-icon-cont' >
              
              <img src={conversation.profileimage} className="avatar-icon"/>
              </div>
                {/* <img src={conversation.avatar} alt={conversation.name} className="avatar" /> */}
                <span className={`status-indicator ${onlineOrOffline[conversation.email]? 'online' : 'offline'}`}></span>
              </div>
              <div className="conversation-details">
                <div className="conversation-top">
                  <p className="username">{conversation.displayName}</p>
                  <span className="timestamp">{`${conversation?.latestMessage?.date==='Invalid Date'?'':conversation?.latestMessage?.date}`||''}</span>
                </div>
                <p className="preview">{conversation?.latestMessage?.sender_email===conversation.email?'Other:':'You:'} {conversation?.latestMessage?.message?.length > 10
    ? `${conversation?.latestMessage?.message.substring(0, 10)}...`
    : conversation?.latestMessage?.message}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="main-chat" style={{display:open?'flex':''}}>
      
        {/* Chat Header */}
        <div className="chat-header">
        <div className='head-back-btn' onClick={handleOpen}>
          <FontAwesomeIcon icon={faArrowLeft} />
      </div>
          <h2>{friendName}</h2>
          <button className="view-profile-btn">View Profile →</button>
        </div>

        {/* Chat Messages */}
        <div className="chat-messages" ref={messageContainerRef}>
          {messages.map((data,index) => (
            <div key={index} className="message-date-group">
              <div className="date-header">{data.date}</div>
              
              {/* {date.messages.map((message) => ( */}
                
                {data.who === 'received' ?(<div  className="message">
                
                    {/* <img src={message.avatar} alt="User avatar" className="avatar" /> */}
                    {/* <div className='avatar-icon-cont' style={{alignSelf:'end'}}>
              <FontAwesomeIcon icon={faUser} className='avatar-icon'/>
              <img src={data.profileimage} className="avatar-icon"/>
              </div> */}
              <div className="profile-avatar-container">
  <img src={friendImg} alt="Profile" className="profile-avatar-image" />
</div>

                <div  className={`message ${data.who}`}>
                  
                  <div className="message-content">
                    <p>{data.message}</p>
                    <span className="timestamp " style={{color:'#374B5C'}}>{data.time}</span>
                  </div>
                </div>
                </div>):
                (<div  className={`message ${data.who}`}>
                  
                  <div className="message-content">
                    <p>{data.message}</p>
                    <span className="timestamp">{data.time}</span>
                  </div>
                </div>)
                }
                
              {/* ))} */}
            </div>
          ))}
        </div>

        {/* Message Input */}
        <div className="message-input">
          <textarea 
            placeholder="Write your message here"
            rows="4"
            className="message-textarea"
            value={myMsg}
            onChange={handleMyMsg}
          ></textarea>
          <div className="send-button-container" >
            <button className="send-btn" onClick={handleSubmit}>
              Send
              <span className="send-icon">→</span>
            </button>
            
          </div>
          <button className="send-btn-mobile" onClick={handleSubmit}>
              <span className="send-icon">→</span>
            </button>
        </div>
      </div>

      {/* Mobile Empty State */}
      {/* <div className="mobile-empty-state">
        <p>Select a conversation to start chatting</p>
      </div> */}
    </div>
  );
};

export default ChatInterface;



