import React, { useState ,useEffect} from "react";
import Logo from "../Assets/logo.png";
import "../css/navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock, faHouse, faCompass, faRightFromBracket, faUserCircle, faPlus, faSearch, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { useNavigate, useLocation, Link } from "react-router-dom"; 
import useAuth from "../components/authmiddle/useAuth";
import axios from 'axios';
import SearchComponent from "../components/SearchComponent";

const Navbar = () => {
    const { authenticated, setAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchText, setSearchText] = useState("");
    const [results, setResults] = useState([]);
    const [selectedResult, setSelectedResult] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [placeholders, setPlaceholders] = useState([]);
    const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);

    useEffect(() => {
        const fetchPlaceholders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/placeholders`);
                const fetchedPlaceholders = response.data.map((item) => item.text);
                setPlaceholders(fetchedPlaceholders);
            } catch (error) {
                console.error("Error fetching placeholder texts:", error);
            }
        };

        fetchPlaceholders();
    }, []);

    useEffect(() => {
        if (placeholders.length > 0) {
            const interval = setInterval(() => {
                setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
            }, 3000); // Change placeholder every 3 seconds
            return () => clearInterval(interval);
        }
    }, [placeholders]);

    const handleLogout = async () => {
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_BACK_URL}/api/auth/logout`,
                {},
                { withCredentials: true }
            );

            setAuthenticated(false);
            if (result.status === 201) {
                // alert('You are Logged Out Successfully!');
                navigate('/login');
                window.location.reload();
            }
        } catch (error) {
            console.error('Logout error:', error);
            window.location.reload();
        }
    };

    const fetchData = async (query) => {
        try {
            if (!query) {
                setResults([]);
                return;
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BACK_URL}/api/prod/get-products?data=${query}`
            );
            setResults(response.data.results || []);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchText(value);
        fetchData(value);
    };

    const handleResultClick = (result) => {
        setSelectedResult(result);
        navigate("/search", { state: { query: result } });
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        if (searchText.trim()) {
            navigate("/search", { state: { query: searchText.trim() } });
        }
    };

    const highlightMatch = (text, query) => {
        if (!query) return text;
        const parts = text.split(new RegExp(`(${query})`, "gi"));
        return parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <span key={index} className="highlight">{part}</span>
            ) : (
                part
            )
        );
    };

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const hiddenSearchPaths = ["/search"]; // Add routes here

    const shouldHideSearchComponents = hiddenSearchPaths.includes(location.pathname);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (searchText.trim()) {
                navigate("/search", { state: { query: searchText.trim() } });
            }
        }
      };


    return (
        <>
            <div id="Container_1">
                <div className="Logo">
                    <Link to="/">
                        <img src={Logo} alt="Logo" />
                    </Link>
                </div>
                { <div style={{visibility:shouldHideSearchComponents? "hidden":"visible"}} className="search-container">
                    <input
                        className="Search_Input"
                        // placeholder="Find cars, bikes, freelancers..."
                        placeholder={
                                placeholders.length > 0
                                    ? `Search "${placeholders[currentPlaceholderIndex]}"`
                                    : "Search ..."
                            }
                        value={searchText}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button className="Search_Button" onClick={handleSearchSubmit}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                    {results.length > 0 && (
                        <div className="results-wrapper">
                            {results.map((result, index) => (
                                <div
                                    key={index}
                                    className="result-item"
                                    onClick={() => handleResultClick(result)}
                                >
                                    <h4 className="result-title">
                                        {highlightMatch(result, searchText)}
                                    </h4>
                                </div>
                            ))}
                        </div>
                    )}
                </div>}
                <div className="Navbar-Home-icon">
                    <Link to="/">
                        <FontAwesomeIcon icon={faHouse} />
                        <p>Home</p>
                    </Link>
                </div>
                <div className="Navbar-Explore-icon">
                    <Link to="/search">
                        <FontAwesomeIcon icon={faCompass} />
                        <p>Explore</p>
                    </Link>
                </div>
                {authenticated && (
                    <div className="Navbar-logout-icon">
                        <FontAwesomeIcon icon={faRightFromBracket} />
                        <button onClick={handleLogout}>
                            <p>Logout</p>
                        </button>
                    </div>
                )}
                {authenticated && (
                    <div className="Navbar-profile-icon">
                        <Link to="/profile">
                            <FontAwesomeIcon icon={faUserCircle} className="Navbar-profile-icon-desktop" />
                            <FontAwesomeIcon icon={faCircleUser} className="Navbar-profile-icon-mobile" />
                        </Link>
                    </div>
                )}
                {!authenticated && (
                    <div className="Navbar-profile-icon">
                        <Link to="/auth">
                            <FontAwesomeIcon icon={faUserLock} className="Navbar-profile-icon-desktop" />
                            <FontAwesomeIcon icon={faCircleUser} className="Navbar-profile-icon-mobile" />
                        </Link>
                    </div>
                )}
                <div className="Navbar-Create-btn" role="button">
                    <Link to="/profile" className="Navbar-Create-btn-link">
                        <FontAwesomeIcon icon={faPlus} className="Navbar-Create-btn-icon" />
                        Post Ad
                    </Link>
                </div>
                <div className="Navbar-Toggle-btn" onClick={handleToggleMenu}>
                    <FontAwesomeIcon icon={faBars} />
                </div>
            </div>
            {!shouldHideSearchComponents && <div className="container_2">
                <div className="search-containe2">
                    <SearchComponent/>
                </div>
            </div>}
            {isMenuOpen && (
                <div className="toggle-menu">
                    <button className="close-menu" onClick={handleToggleMenu}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <div className="menu-items">
                        <Link to="/" onClick={handleToggleMenu}>
                            <FontAwesomeIcon icon={faHouse} />
                            <span>Home</span>
                        </Link>
                        <Link to="/product" onClick={handleToggleMenu}>
                            <FontAwesomeIcon icon={faCompass} />
                            <span>Explore</span>
                        </Link>
                        {authenticated ? (
                            <>
                                <Link to="/profile" onClick={handleToggleMenu}>
                                    <FontAwesomeIcon icon={faUserCircle} />
                                    <span>Profile</span>
                                </Link>
                                <button onClick={() => { handleLogout(); handleToggleMenu(); }}>
                                    <FontAwesomeIcon icon={faRightFromBracket} />
                                    <span>Logout</span>
                                </button>
                            </>
                        ) : (
                            <Link to="/auth" onClick={handleToggleMenu}>
                                <FontAwesomeIcon icon={faUserLock} />
                                <span>Login</span>
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Navbar;

