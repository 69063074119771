

import React, { useState,useEffect } from "react";
import "../css/productCard.css"; // Import CSS file
import "../css/productPageCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import {
  FaMapMarkerAlt,
} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';


const ProductPageCard = ({ listings }) => {
  const navigate=useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [Val, setVal] = useState(100); // Initial value of Val

  // Update Val based on screen width
  const updateVal = () => {
    const width = window.innerWidth;
    if (width >= 1050) {
      setVal(100); // Full width for PC
    } else if (width >= 768 && width < 1050) {
      setVal(50); // Half width for tablets
    } else {
      setVal(100); // Full width for smaller devices
    }
  };

  useEffect(() => {
    updateVal(); // Set initial Val based on current screen width
    window.addEventListener("resize", updateVal); // Add resize listener
    return () => {
      window.removeEventListener("resize", updateVal); // Clean up listener
    };
  }, []);

  // Handlers for navigation
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? listings.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === listings.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleViewMoreClick = (productId) => {
    navigate(`/product?id=${productId}`);
  };

  return (
    <div className="productPageCard-Supreme" >
     
      <div className="slider-wrapper">
        <div
          className="card-slider"
          style={{
            transform: `translateX(-${currentIndex * Val}%)`,
            display: "flex",
            transition: "transform 0.5s ease-in-out",
          }}
        >
          {listings.map((listing, index) => {
            const parsedImages = typeof listing.images === "string" ? JSON.parse(listing.images) : listing.images;
           return(
            <div
              key={index}
              className="product-card productPageCard "
              // style={{
              //   width: "100%",
              //   padding: "30px",
              //   display:  "block",
              // }}
              onClick={()=>{handleViewMoreClick(listing.productId)}}
            >
              <img
                src={parsedImages[0]}
                alt={listing.name}
                className="product-image"
              />
              <div
                className="product-info productPageCard-info"
                style={{ padding: "0px" }}
              >
                <h3 className="product-name">{listing.adName}</h3>
                <div className="product-card-location">
     <div className="product-card-Icon-l">
    <FaMapMarkerAlt className="loc_icon" />
  </div>
  {listing.location.length <= 45 ? (
    listing.location
  ) : (
    <>
      {listing.location.substring(0, 45)}
     
    </>
  )}
</div>
 {listing.location.length > 45 &&<span className="more-text" >
        more
      </span>}

          <p className="product-price" style={{marginTop:listing.location.length > 45?'0px':'10px'}}>₹{listing.price}/Day</p>
                {/* <p className="product-address">{listing.location}</p>
                <p className="product-price">{listing.price}</p> */}
              </div>
            </div>
           )})}
        </div>
      </div>
      


      <div className="listing-controls">
                      <span className="pageno">
                        {currentIndex + 1}/{listings.length}
                      </span>
                      <div className="buttons">
                        <button className="prev" onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#dcecdc':'#308616'}} disabled={(currentIndex+1)===1}>
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <button className="next" onClick={handleNextClick} style={{backgroundColor:Val===50 ? (currentIndex+1===listings.length-1?'#dcecdc':'#308616'):(currentIndex+1===listings.length?'#dcecdc':'#308616') }}  disabled={Val===50 ? currentIndex+1===listings.length-1:currentIndex+1===listings.length }>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </div>
                    </div>
    </div>
  );
};

export default ProductPageCard;
