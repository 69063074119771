
import React, { useState,useEffect } from 'react';
import '../css/SuggestionProductPageSlider.css';
import Heart from "../Assets/heart-svgrepo-com.svg";
import Eye from "../Assets/eye-svgrepo-com.svg";
import QuickViewCard from '../components/QuickViewCard';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import ImageForProductPage from './ImageForProductPage';
import SuggestionImageCom from './SuggestionImageCom';
import axios from 'axios'
import useStore from '../components/authmiddle/store';
import {
  FaMapMarkerAlt,
} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

export default function SuggestionProductPageSlider({sellerEmail,headingMsg,buttonMsg,buttonMobMsg}) {
  const [products, setProducts] = useState([]);
  const { isAuthenticated} = useStore();
  const navigate=useNavigate()

  // Fetch data from the API
  useEffect(() => {
      
    const fetchProducts = async () => {
      try {
        let response;
        if(isAuthenticated){
        response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/prod/fetchProdSpecificSeller?email=${sellerEmail}`,{withCredentials:true}); // Replace with your backend API URL
        }else{
         response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/prod/fetchProdSpecificSeller-v1?email=${sellerEmail}`); // Replace with your backend API URL
      
        }
        setProducts(response.data.data);
        
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);


  const [currentIndex, setCurrentIndex] = useState(0);
  const [Val, setVal] = useState(100); // Initial value of Val

  // Update Val based on screen width
  const updateVal = () => {
    const width = window.innerWidth;
    if (width >= 1050) {
      setVal(27); // Full width for PC
    } else if (width >= 768 && width < 1050) {
      setVal(32); // Half width for tablets
    } else {
      setVal(100); // Full width for smaller devices
    }
  };

  useEffect(() => {
    updateVal(); // Set initial Val based on current screen width
    window.addEventListener("resize", updateVal); // Add resize listener
    return () => {
      window.removeEventListener("resize", updateVal); // Clean up listener
    };
  }, []);

  // Handlers for navigation
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? products.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === products.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleViewMoreClick = (productId) => {
    navigate(`/product?id=${productId}`);
    window.scrollTo(0, 0);
  }; 
  return (
    <>
      <div className='Suggest-Prod-Supreme'>


      <div className="Suggest-Prod-Card-listing-controls">

                     <h3 className='Suggest-Prod-headingOfLi'>{headingMsg}</h3>
                     <div className='Suggest-Prod-buttons-cont'>
                     <button className="Spec-btn " onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#f4fcfc':'#DDECF7',color:(currentIndex+1)===1?'#dce4e4':'#374B5C'}} disabled={(currentIndex+1)===1}>
                         {buttonMsg}
                        </button>
                      <div className="Suggest-Prod-buttons">
                      
                        <button className=" btn prev" onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#f4fcfc':'#DDECF7',color:(currentIndex+1)===1?'#dce4e4':'#374B5C'}} disabled={(currentIndex+1)===1}>
                          <FontAwesomeIcon icon={faArrowLeft}  />
                        </button>
                        <button className="btn next" onClick={handleNextClick} style={{backgroundColor:Val===50 ? (currentIndex+1===products.length-1?'#f4fcfc':'#DDECF7'):(currentIndex+1===products.length?'#f4fcfc':'#DDECF7') ,color:Val===50 ? (currentIndex+1===products.length-1?'#dce4e4':'#374B5C'):(currentIndex+1===products.length?'#dce4e4':'#374B5C') }}  disabled={Val===50 ? currentIndex+1===products.length-1:currentIndex+1===products.length }>
                          <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                        </div>
                        </div>
                   
      </div>

      <div className="slider-wrapper">
        <div
          className="card-slider"
          style={{
            transform: `translateX(-${currentIndex * Val}%)`,
            display: "flex",
            transition: "transform 0.5s ease-in-out",
            gap:"20px"
          }}
        >
        {products.map((listing, index) => {

          const parsedImages = typeof listing.images === "string" ? JSON.parse(listing.images) : listing.images;
          return(
            <div
              key={index}
              className="product-card Suggest-Prod-Card "
              style={{
                flex:"0 0 auto"
              }}
              // onClick={() => handleViewMoreClick(listing.productId)}
            >
             
              <SuggestionImageCom images={parsedImages}/>
              
              <div
                className="product-info productPageCard-info"
                
              >
                <h3 className="product-name">{listing.adName}</h3>
                <div className="product-card-location">
                     <div className="product-card-Icon-l">
                    <FaMapMarkerAlt className="loc_icon" />
                  </div>
                  {listing.location.length <= 45 ? (
                    listing.location
                  ) : (
                    <>
                      {listing.location.substring(0, 45)}
                     
                    </>
                  )}
                </div>
                 {listing.location.length > 45 &&<span className="more-text" >
                        more
                      </span>}
                
                <p className="product-price" style={{marginTop:listing.location.length > 45?'30px':'50px'}}>₹{listing.price}</p>
                       

              </div>

              <div className="product-actions">
          <div className="actions">
            <button className="quick-view" >
              <img src={Eye} alt="View" />
              <span className="tooltip">Quick View</span>
            </button>
            
            <button className="compare">
              <img src={Heart} alt="Compare" />
              <span className="tooltip">Add to Compare</span>
            </button>
            
            <button className="like">
              <img src={Heart} alt="Like" />
              <span className="tooltip">Add to Favorites</span>
            </button>
          </div>
          
          <p className="views-count">{listing.views} views</p>
        </div>
            </div>
        )})}
        </div>
        </div>


        <div className='Suggest-Prod-buttons-cont-forMobile'>
                     <button className="Spec-btn " onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#f4fcfc':'#DDECF7',color:(currentIndex+1)===1?'#dce4e4':'#374B5C'}} disabled={(currentIndex+1)===1}>
                         {buttonMobMsg}
                        </button>
                      <div className="Suggest-Prod-buttons">
                      
                        <button className=" btn prev" onClick={handlePrevClick} style={{backgroundColor:(currentIndex+1)===1?'#f4fcfc':'#DDECF7',color:(currentIndex+1)===1?'#dce4e4':'#374B5C'}} disabled={(currentIndex+1)===1}>
                          <FontAwesomeIcon icon={faArrowLeft}  />
                        </button>
                        <button className="btn next" onClick={handleNextClick} style={{backgroundColor:Val===50 ? (currentIndex+1===products.length-1?'#f4fcfc':'#DDECF7'):(currentIndex+1===products.length?'#f4fcfc':'#DDECF7') ,color:Val===50 ? (currentIndex+1===products.length-1?'#dce4e4':'#374B5C'):(currentIndex+1===products.length?'#dce4e4':'#374B5C') }}  disabled={Val===50 ? currentIndex+1===products.length-1:currentIndex+1===products.length }>
                          <FontAwesomeIcon icon={faArrowRight}/>
                        </button>
                        </div>
          </div>

        

      </div>

     
    </>
  );
};