import React, { useState, useEffect } from 'react'
import { ArrowLeft, X, Home, MessageCircle, HelpCircle, Send } from 'lucide-react'

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [activeTab, setActiveTab] = useState('home')
    const [chatHistory, setChatHistory] = useState([])
    const [inputMessage, setInputMessage] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [activeCategory, setActiveCategory] = useState(null)
    const [showQuestions, setShowQuestions] = useState(false)
    const [showSatisfactionQuestion, setShowSatisfactionQuestion] = useState(false)

    const categories = [
        {
            title: "Account and Profile Management",
            questions: [
                {
                    if: "I can't log in to my account",
                    response: "Have you tried resetting your password? If not, click here to reset it."
                },
                {
                    if: "I need to update my profile details",
                    response: "You can update your profile by navigating to the 'Profile Settings' page in your account."
                },
                {
                    if: "How do I delete my account?",
                    response: "You can request account deletion in the 'Account Settings' section. Keep in mind that this action is irreversible."
                }
            ],
            else: "Would you like me to connect you to a support agent for assistance with your account?"
        },
        {
            title: "Listing Items",
            questions: [
                {
                    if: "How do I list an item for rent?",
                    response: "To list an item, go to 'Add Listing' on your dashboard, upload images, and fill out the details like price and description."
                },
                {
                    if: "I can't upload images for my listing",
                    response: "Please ensure the images are in the correct format (JPEG/PNG) and under 5MB in size."
                },
                {
                    if: "Can I edit or delete my listing?",
                    response: "Yes, go to your listings under 'My Dashboard' and select 'Edit' or 'Delete' for the specific item."
                }
            ],
            else: "Would you like to speak with a support agent for help with your listing?"
        },
        {
            title: "Renting Items",
            questions: [
                {
                    if: "How do I search for an item to rent?",
                    response: "Use the search bar on the homepage and filter by category, location, or price range."
                },
                {
                    if: "What if the owner doesn't respond to my request?",
                    response: "You can send a reminder or contact support if the owner remains unresponsive after 24 hours."
                },
                {
                    if: "What happens if I damage a rented item?",
                    response: "Any damages will be subject to the platform's policies. Please contact support immediately for assistance."
                }
            ],
            else: "Would you like assistance from a customer support representative for renting-related issues?"
        },
        {
            title: "Payments and Transactions",
            questions: [
                {
                    if: "How do I make a payment?",
                    response: "You can make payments securely via the platform using your preferred payment method (credit card, UPI, etc.)."
                },
                {
                    if: "Why is my payment failing?",
                    response: "Please check if your payment details are correct or try using a different payment method."
                },
                {
                    if: "How do I get paid for renting out my item?",
                    response: "Payments will be processed to your linked account once the rental is confirmed and completed."
                }
            ],
            else: "Would you like me to connect you with support for payment-related issues?"
        },
        {
            title: "Policies and Safety",
            questions: [
                {
                    if: "What happens if I cancel a rental?",
                    response: "Cancellations are subject to the platform's cancellation policy. You may be charged a cancellation fee."
                },
                {
                    if: "How do I report suspicious activity?",
                    response: "Please use the 'Report' button on the listing or user profile. Support will review the report and take appropriate action."
                },
                {
                    if: "Is there insurance for rented items?",
                    response: "The platform offers optional insurance. Please check the rental terms for details."
                }
            ],
            else: "Would you like to connect with a customer support representative for policy-related concerns?"
        }
    ]

    useEffect(() => {
        if (activeTab === 'messages' && chatHistory.length === 0) {
            setChatHistory([
                {
                    type: 'system',
                    content: 'Welcome to Goleezy support Team!',
                    isWelcome: true
                },
                {
                    type: 'agent',
                    content: 'Hey there! 😊 Customer\nExcited to help! How can we get started?',
                    isAgent: true
                }
            ])
            setShowQuestions(true)
        }
    }, [activeTab])

    const handleSendMessage = () => {
        if (inputMessage.trim()) {
            setChatHistory(prev => [...prev, { type: 'user', content: inputMessage }])
            setInputMessage('')
            setShowQuestions(true)
            setShowSatisfactionQuestion(false)
        }
    }

    const handleCategoryClick = (category) => {
        setActiveCategory(category)
        setActiveTab('messages')
    }

    const handleQuestionClick = (question) => {
        setChatHistory(prev => [
            ...prev,
            { type: 'user', content: question.if },
            { type: 'agent', content: question.response, isAgent: true }
        ])
        setShowQuestions(true)
        setShowSatisfactionQuestion(true)
    }

    const handleSatisfactionResponse = (response) => {
        if (response === 'no') {
            setChatHistory(prev => [...prev, {
                type: 'agent',
                content: "I'm sorry to hear that. Let me connect you to a customer support representative for further assistance.",
                isAgent: true
            }])
        }
        setShowSatisfactionQuestion(false)
    }

    const renderTabs = () => (
        <div className="bottom-tabs">
            <button
                className={`tab-button ${activeTab === 'home' ? 'active' : ''}`}
                onClick={() => setActiveTab('home')}
            >
                <Home size={24} />
                <span>Home</span>
            </button>
            <button
                className={`tab-button ${activeTab === 'messages' ? 'active' : ''}`}
                onClick={() => setActiveTab('messages')}
            >
                <MessageCircle size={24} />
                <span>Messages</span>
            </button>
            <button
                className={`tab-button ${activeTab === 'help' ? 'active' : ''}`}
                onClick={() => setActiveTab('help')}
            >
                <HelpCircle size={24} />
                <span>Help</span>
            </button>
        </div>
    )

    return (
        <>
            {isOpen && (
                <div className="chatbot-container">
                    {activeTab === 'home' ? (
                        <div className="chatbot-interface">
                            <div className="logo">Goleezy</div>

                            <div style={{ textAlign: "center", display: "flex", flexDirection: "column" }} className='header'>
                                <h2
                                    style={{
                                        textShadow: "rgba(255, 255, 255, 0.3) 0px 0px 30px",
                                        color: "#ffffff",
                                        display: "block",
                                    }}
                                >
                                    Hi there
                                </h2>
                                <h1
                                    style={{
                                        color: "#ffffff",
                                        display: "block",
                                    }}
                                >
                                    How can we help?
                                </h1>
                            </div>

                            <div className="search-container1">
                                <input
                                    type="text"
                                    placeholder="Search for help"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <button className="search-button">
                                    <svg viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2">
                                        <circle cx="11" cy="11" r="8" />
                                        <path d="M21 21l-4.35-4.35" />
                                    </svg>
                                </button>
                            </div>

                            <div className="topics-list">
                                {categories.map((category, index) => (
                                    <button
                                        key={index}
                                        className="topic-button"
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        {category.title}
                                        <span className="chevron">›</span>
                                    </button>
                                ))}
                            </div>

                            {renderTabs()}
                        </div>
                    ) : activeTab === 'messages' ? (
                        <div className="messages-interface">
                            <div className="messages-header">
                                <button className="back-button" onClick={() => setActiveTab('home')}>
                                    <ArrowLeft size={24} />
                                </button>
                                <h1>Goleezy</h1>
                                <button className="close-button" onClick={() => setIsOpen(false)}>
                                    <X size={24} />
                                </button>
                            </div>

                            <div className="logo-container">
                                <div className="logo-group">
                                    <div className="logo-circle">G</div>
                                    <div className="logo-circle">G</div>
                                    <div className="logo-circle">G</div>
                                </div>
                            </div>

                            <div className="messages-content">
                                {chatHistory.map((message, index) => (
                                    <div
                                        key={index}
                                        className={`message ${message.type} ${message.isAgent ? 'agent' : ''} ${message.isWelcome ? 'welcome' : ''}`}
                                    >
                                        {message.content.split('\n').map((text, i) => (
                                            <p key={i}>{text}</p>
                                        ))}
                                    </div>
                                ))}
                                {showQuestions && activeCategory && (
                                    <div className="questions-container">
                                        {activeCategory.questions.map((question, index) => (
                                            <button key={index} className="question-button" onClick={() => handleQuestionClick(question)}>
                                                {question.if}
                                            </button>
                                        ))}
                                        <button className="question-button" onClick={() => handleQuestionClick({ if: 'Other', response: activeCategory.else })}>
                                            Other
                                        </button>
                                    </div>
                                )}
                                {showSatisfactionQuestion && (
                                    <div className="satisfaction-question">
                                        <p>Are you satisfied with the response?</p>
                                        <button onClick={() => handleSatisfactionResponse('yes')}>Yes</button>
                                        <button onClick={() => handleSatisfactionResponse('no')}>No</button>
                                    </div>
                                )}
                            </div>

                            <div className="message-input-container">
                                <input
                                    type="text"
                                    placeholder="Type your message..."
                                    value={inputMessage}
                                    onChange={(e) => setInputMessage(e.target.value)}
                                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                                />
                                <button
                                    className="send-button"
                                    onClick={handleSendMessage}
                                >
                                    <Send size={20} />
                                </button>
                            </div>

                            {renderTabs()}
                        </div>
                    ) : (
                        <div className="help-interface">
                            <div className="help-header">
                                <button className="back-button" onClick={() => setActiveTab('home')}>
                                    <ArrowLeft size={24} />
                                </button>
                                <h1>Help Center</h1>
                                <button className="close-button" onClick={() => setIsOpen(false)}>
                                    <X size={24} />
                                </button>
                            </div>
                            <div className="help-content">
                                <h2>How can we assist you?</h2>
                                <p>This is the help center. You can find FAQs and guides here.</p>
                            </div>
                            {renderTabs()}
                        </div>
                    )}
                </div>
            )}

            <button className="chatbot-toggle" onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? (
                    <X size={24} />
                ) : (
                    <svg viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>
                )}
            </button>

            <style jsx>{`
        .chatbot-container {
          position: fixed;
          bottom: 92px;
          right: 15px;
          width: 352px;
           z-index: 10;
          height: 600px;
          background: white;
          border-radius: 16px;
          color: #1a1a1a;
          font-family: system-ui, -apple-system, sans-serif;
          overflow: hidden;
          box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
        }

        .chatbot-interface, .messages-interface, .help-interface {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
.message.welcome {
justify-content:center;            
}
        .chatbot-interface {
          background: linear-gradient(135deg, #1a237e 0%, #784c4c 100%);
          color: white;
        }

        .messages-interface, .help-interface {
          background: #ffffff;
          overflow-x: auto;
        }

        .messages-header, .help-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          border-bottom: 1px solid #eee;
          background: white;
        }

        .messages-header h1, .help-header h1 {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
        }

        .back-button, .close-button {
          background: none;
          border: none;
          padding: 8px;
          cursor: pointer;
          color: #666;
        }

        .logo {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
          padding-left: 20px;
          padding-top: 20px;
        }

        .header {
          margin-bottom: 32px;
        }

        .header h2 {
          font-size: 24px;
          font-weight: 500;
          margin: 0;
          opacity: 0.9;
        }

        .header h1 {
          font-size: 32px;
          font-weight: bold;
          margin: 4px 0 0 0;
        }

        .search-container1 {
          position: relative;
          padding: 10px;
          margin-bottom: 16px;
        }

        .search-container1 input {
          background: white;
          border: none;
          border-radius: 12px;
          color: #1a237e;
          font-size: 16px;
          padding: 16px 48px 16px 20px;
          width: 100%;
        }

        .search-container1 input::placeholder {
          color: #9fa8da;
        }

        .search-button {
          background: none;
          border: none;
          color: #4051b5;
          cursor: pointer;
          padding: 8px;
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }

        .topics-list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-bottom: auto;
          flex-grow: 1;
          padding: 24px;
          overflow-y: auto;
        }

        .topic-button {
          background: white;
          border: none;
          border-radius: 12px;
          color: #1a237e;
          cursor: pointer;
          font-size: 16px;
          padding: 16px 20px;
          text-align: left;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: transform 0.2s;
        }

        .topic-button:hover {
          transform: translateY(-1px);
        }

        .chevron {
          color: #9fa8da;
          font-size: 20px;
        }

        .logo-container {
          padding: 24px 0;
          display: flex;
          justify-content: center;
          background: white;
        }

        .logo-group {
          display: flex;
          gap: 8px;
        }

        .logo-circle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: #000033;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
        }

        .messages-content {
          flex: 1;
          overflow-y: auto;
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .message {
          max-width: 80%;
          padding: 12px 16px;
          border-radius: 12px;
          font-size: 15px;
          line-height: 1.4;
        }

        .message.welcome {
          text-align: center;
          max-width: 100%;
          color: #666;
          padding: 0;
          margin-bottom: 16px;
        }

        .message.user {
          background: #f0f0f0;
          margin-left: auto;
          color: #1a1a1a;
        }

        .message.agent {
          background: #e8f0fe;
          color: #1a1a1a;
          border-left: 4px solid #4285f4;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .message p {
          margin: 0;
        }

        .message p + p {
          margin-top: 8px;
        }

        .questions-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 16px;
          margin-bottom: 16px;
        }

        .question-button {
          background: #f0f0f0;
          border: none;
          border-radius: 8px;
          padding: 8px 12px;
          text-align: left;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .question-button:hover {
          background-color: #e0e0e0;
        }

        .message-input-container {
          display: flex;
          gap: 8px;
          padding: 16px;
          background: white;
          border-top: 1px solid #eee;
        }

        .message-input-container input {
          flex: 1;
          padding: 12px 16px;
          border: 1px solid #eee;
          border-radius: 100px;
          font-size: 14px;
          outline: none;
        }

        .message-input-container input:focus {
          border-color: #000033;
        }

        .send-button {
          padding: 12px;
          background: #000033;
          color: white;
          border: none;
          border-radius: 50%;
          font-size: 14px;
          cursor: pointer;
          transition: background 0.2s;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .send-button:hover {
          background: #000066;
        }

        .bottom-tabs {
          display: flex;
          justify-content: space-around;
          align-items: center;
          background-color: #f8f9fa;
          border-top: 1px solid #eee;
          padding: 8px 0;
        }

        .tab-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: none;
          border: none;
          color: #666;
          cursor: pointer;
          font-size: 12px;
        }

        .tab-button.active {
          color: #000033;
        }

        .tab-button svg {
          margin-bottom: 4px;
        }

        .help-content {
          padding: 24px;
          overflow-y: auto;
        }

        .help-content h2 {
          font-size: 24px;
          margin-bottom: 16px;
        }

        .help-content p {
          font-size: 16px;
          line-height: 1.5;
          color: #333;
        }

        .chatbot-toggle {
          position: fixed;
          bottom: 20px;
          right: 20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #000033;
          color: white;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s;
        }

        .chatbot-toggle:hover {
          transform: scale(1.05);
        }

        .chatbot-interface::-webkit-scrollbar,
        .messages-content::-webkit-scrollbar,
        .help-content::-webkit-scrollbar {
          width: 6px;
        }

        .chatbot-interface::-webkit-scrollbar-track,
        .messages-content::-webkit-scrollbar-track,
        .help-content::-webkit-scrollbar-track {
          background: rgba(255, 255, 255, 0.1);
        }

        .chatbot-interface::-webkit-scrollbar-thumb,
        .messages-content::-webkit-scrollbar-thumb,
        .help-content::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 3px;
        }

        .satisfaction-question {
          margin-top: 16px;
          text-align: center;
        }

        .satisfaction-question p {
          margin-bottom: 8px;
        }

        .satisfaction-question button {
          margin: 0 8px;
          padding: 6px 12px;
          background-color: #f0f0f0;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .satisfaction-question button:hover {
          background-color: #e0e0e0;
        }
      `}</style>
        </>
    )
}

export default Chatbot

