import React, { useState, forwardRef, useEffect } from 'react';
import axios from 'axios';
import '../css/productCard.css';
// import { useNavigate } from 'react-router-dom';
import Heart from "../Assets/heart-svgrepo-com.svg";
import Compare from "../Assets/git-compare-svgrepo-com.svg";
import HeartFilled from "../Assets/Assets_heart-filled-svgrepo-com.svg";
import Eye from "../Assets/eye-svgrepo-com.svg";
import QuickViewCard from '../components/QuickViewCard';
import { useNavigate } from 'react-router-dom';
import useStore from '../components/authmiddle/store';
import {
  FaMapMarkerAlt,
} from "react-icons/fa";
const ProductCard = forwardRef(({ 
  images, 
  name, 
  isliked, 
  address, 
  price, 
  views, 
  postDate, 
  category, 
  email, 
  productId, 
  initialLikes,
  onCompare,
  isCompared 
}, ref) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isQuickViewVisible, setIsQuickViewVisible] = useState(false);
  const [likes, setLikes] = useState(initialLikes || 0);
  const [isLiked, setIsLiked] = useState(isliked || false);
  const { isAuthenticated } = useStore();
  const navigate = useNavigate();
  // const navigate = useNavigate();

  useEffect(() => {
    setIsLiked(isliked);
  }, [isliked]);

  const handlePrevClick = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const openQuickView = (e) => {
    e.stopPropagation();
    setIsQuickViewVisible(true);
  };

  const closeQuickView = () => {
    setIsQuickViewVisible(false);
  };

  const [likeClicks, setLikeClicks] = useState(0);


  const handleLikeToggle = async (e) => {
    e.stopPropagation();
  
    if (!isAuthenticated) {
      navigate('/auth');
      return;
    }
  
    if (likeClicks >= 5) {
      alert("You have reached the maximum like attempts. Please refresh the page to continue.");
      return;
    }
  
    try {
      const newIsLiked = !isLiked;
      setIsLiked(newIsLiked);
      setLikes((prevLikes) => (newIsLiked ? prevLikes + 1 : prevLikes - 1));
      setLikeClicks((prevCount) => prevCount + 1);
  
      const requestBody = { productId };
      const endpoint = newIsLiked
        ? `${process.env.REACT_APP_BACK_URL}/api/prod/like`
        : `${process.env.REACT_APP_BACK_URL}/api/prod/unlike`;
  
      await axios.post(endpoint, requestBody, { withCredentials: true });
    } catch (error) {
      console.error("Error toggling like:", error);
      setIsLiked((prevIsLiked) => !prevIsLiked);
      setLikes((prevLikes) => (isLiked ? prevLikes - 1 : prevLikes + 1));
    }
  };
  

  const handleViewMoreClick = (productId) => {
    navigate(`/product?id=${productId}`);
  };

  const handleCompareClick = (e) => {
    e.stopPropagation();
    onCompare();
  };

  return (
    <>
      <div className="product-card" ref={ref} onClick={() => handleViewMoreClick(productId)}>
        <div className="carousel-product-card">
          <button
            className="product-card-arrow-btn left"
            style={{ display: currentImageIndex === 0 ? 'none' : 'flex' }}
            onClick={handlePrevClick}
          >
            &larr;
          </button>

          <div className="slider-wrapper">
            <div
              className="image-slider"
              style={{
                transform: `translateX(-${currentImageIndex * 100}%)`,
              }}
            >
              {images.map((image, index) => (
                <div
                  key={index}
                  className="slider-image-container"
                >
                  <img
                    src={image}
                    alt={`image-${index}`}
                    className="product-image"
                  />
                </div>
              ))}
            </div>
          </div>

          <button
            className="product-card-arrow-btn right"
            style={{ display: currentImageIndex === images.length - 1 ? 'none' : 'flex' }}
            onClick={handleNextClick}
          >
            &rarr;
          </button>

          <div className="carousel-dots-cont">
            <div className="carousel-dots">
              {images.map((_, index) => {
                const distance = Math.abs(index - currentImageIndex);
                const size = Math.max(10 - distance * 2, 4);

                return (
                  <span
                    key={index}
                    className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
                    style={{
                      width: `${size}px`,
                      height: `${size}px`,
                    }}
                  ></span>
                );
              })}
            </div>
          </div>
        </div>

        <div className="product-info">
          <h3 className="product-name">{name}</h3>
          {/* <p className="product-address">{address}</p> */}
          <div className="product-card-location">
     <div className="product-card-Icon-l">
    <FaMapMarkerAlt className="loc_icon" />
  </div>
  {address.length <= 45 ? (
    address
  ) : (
    <>
      {address.substring(0, 45)}
     
    </>
  )}
</div>
 {address.length > 45 &&<span className="more-text" >
        more
      </span>}

          <p className="product-price" style={{marginTop:address.length > 45?'30px':'50px'}}>₹{price}</p>
        </div>

        <div className="product-actions">
          <div className="actions">
            <button className="quick-view" onClick={openQuickView}>
              <img src={Eye} alt="View" />
              <span className="tooltip">Quick View</span>
            </button>
            <button 
              className={`compare ${isCompared ? 'active' : ''}`}
              onClick={handleCompareClick}
            >
              <img src={Compare} alt="Compare" />
              <span className="tooltip">
                {isCompared ? 'Remove from Compare' : 'Add to Compare'}
              </span>
            </button>
            <button
              className="like"
              onClick={handleLikeToggle}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <img 
                src={isLiked ? HeartFilled : Heart} 
                alt={isLiked ? "Unlike" : "Like"} 
                style={{
                  width: '24px',
                  height: '24px',
                  transition: 'transform 0.2s ease',
                  transform: isLiked ? 'scale(1.2)' : 'scale(1)',
                }}
              />
              <span className="tooltip">
                {isLiked ? 'Unlike' : 'Add to Favorites'}
              </span>
            </button>
          </div>

          <p className="likes-count">{likes} likes</p>
          <p className="views-count">{views} views</p>
        </div>
      </div>

      {isQuickViewVisible && (
        <QuickViewCard
          data={{ 
            images, 
            name, 
            address, 
            initialLikes, 
            price, 
            category, 
            timeAgo: postDate, 
            views, 
            email, 
            productId 
          }}
          onClose={closeQuickView}
        />
      )}
    </>
  );
});

export default ProductCard;
