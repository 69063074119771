import { useState, useEffect } from 'react';
import axios from 'axios';
import useStore from './store';
// import Cookies from 'js-cookie';

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(null);
  const [loading, setLoading] = useState(true);
  const setIsAuthenticated = useStore((state) => state.setIsAuthenticated);
  useEffect(() => {
    const checkAuth = async () => {
      try {
      
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/userdetails`, {
          withCredentials: true // Automatically include cookies
        });

        setAuthenticated(response.status === 200);
        setIsAuthenticated(response.status === 200)
      } catch (error) {
        console.error('Authentication error:', error);
        setAuthenticated(false);
        setIsAuthenticated(false)
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  return { authenticated, loading,setAuthenticated };
};

export default useAuth;
