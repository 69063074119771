
import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faUserPlus, faKey, faComment } from '@fortawesome/free-solid-svg-icons';
import '../css/auth.css';
import Navbar from './navbar';
import Register from '../components/Register';
import axios from 'axios';
import AUTH_LOGO from '../Assets/auth_logo.WEBP';
import { useNavigate, Link } from 'react-router-dom';
import Footer from './footer';
import useStore from '../components/authmiddle/store';

export default function Auth() {
   useEffect(() => {
      window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    
  const setIsAuthenticated = useStore((state) => state.setIsAuthenticated);
  const [toggle, setToggle] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [loginMode, setLoginMode] = useState('password'); // 'password' or 'otp'
  const [verifyOtpBtn, setVerifyOtpBtn] = useState(false);
  const [timer, setTimer] = useState(0);
  const [resendEnabled, setResendEnabled] = useState(false);
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(true);
  const [errors, setErrors] = useState({});
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  
  

  const handleToggle = () => {
    setToggle(!toggle);
    setPassword('');
    setOtp('');
    setEmailOrPhone('');
    setLoginMode('password');
    setVerifyOtpBtn(false);
    setTimer(0);
    setResendEnabled(false);
    setIsTimerRunning(false);
  };

  const handleLoginWithPass = async () => {
    if (!emailOrPhone || !password) {
      setErrors({ emailOrPhone: !emailOrPhone ? 'Email or phone is required' : '', password: !password ? 'Password is required.' : '' });

      return;
    }
    setLoginMode('password');

    try {
      // console.log(password,emailOrPhone)
      const result = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/login-with-pass`, {
        identifier: emailOrPhone,
        password: password
      }, { withCredentials: true });
      console.log(result.status)
      
      if (result.status === 201) {
        // Redirect to home or dashboard
        setIsAuthenticated(true)
        // alert('You are Logged In Successfully!')
        navigate('/profile');
        // navigate('/');
    }
    } catch (error) {
      
      console.error("Error in Login Pass!", error);
      window.location.reload();
    }
  };

  const startOtpTimer = () => {
    if (isTimerRunning) return;

    setTimer(60); // Set timer to 60 seconds
    setResendEnabled(true);
    setIsTimerRunning(true)
    
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setResendEnabled(true);
          setIsTimerRunning(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleLoginWithOtp = async () => {
    if (!emailOrPhone) {
      setErrors({ emailOrPhone: !emailOrPhone ? 'Email or phone is required' : '' });

      return;
    }

    setLoginMode('otp');
    // startOtpTimer(); // Start the timer when OTP is requested
    try {
     
      if(!isTimerRunning){
      const result = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/get-otp-login`, {
        identifier: emailOrPhone
      }, { withCredentials: true });
      if(result.status===201)
      {
      setVerifyOtpBtn(true);
      
      
      startOtpTimer();
      }
    }
    } catch (error) {
     
      console.error("Error in OTP Request!", error);
      window.location.reload();
    }
  };

  const handleVerifyOtp = async () => {

    if(!otp)
    {
      setErrors({ otp: !otp ? 'Otp is required' : '' });
      return;
    }

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/verify-otp-login`, {
        identifier: emailOrPhone,
        otpCode: otp
      }, { withCredentials: true });
     
      
      if (result.status === 201) {
        // Redirect to home or dashboard
        setIsAuthenticated(true)
        // alert('You are Logged In Successfully!')
        navigate('/');
    }
    } catch (error) {
     
      if(error.response.data.msg==='Invalid OTP')
        {
          setErrors({ ...errors, otp: 'Invalid Otp' });
       
        }else{
          console.log(error.response.data.message)
          alert(error.response.data.message)
          
      console.error("Error in OTP Verification!", error);
      // window.location.reload();
        }
    }
  };

  const handleResendOtp = () => {
    handleLoginWithOtp(); // Re-request OTP
    setOtp(''); // Clear the OTP input field
    // startOtpTimer(); // Restart the timer
  };

  return (
    <>
      <Navbar />
      <div className={`auth-container ${animate ? 'animate-box' : ''}`}>
        <div className='auth-logo-container'>
          <img src={AUTH_LOGO} className='auth-logo-img' alt="Auth Logo" />
        </div>
        <div className='auth-box'>
          <div className='auth-toggle'>
            <button 
              className={`auth-toggle-button toggle-btn1 ${!toggle ? 'active' : 'inactive'}`} 
              onClick={handleToggle} 
              disabled={!toggle}
            >
              Login
            </button>
            <button 
              className={`auth-toggle-button toggle-btn2 ${toggle ? 'active' : 'inactive'}`} 
              onClick={handleToggle} 
              disabled={toggle}
            >
              SignUp
            </button>
          </div>

          {!toggle && (
            <>
              <div className="input-group">
                
                <input 
                  className={`input-field ${errors.emailOrPhone ? 'input-error' : ''}`}
                  type="text" 
                  placeholder=" " 
                  value={emailOrPhone} 
                  onChange={(e) => {setEmailOrPhone(e.target.value)
                    setErrors({ ...errors, emailOrPhone: '' });
                  }}
                  disabled={loginMode==='otp'}
                />
                <label className={`${errors.emailOrPhone? 'label-error-floating':'label-floating' }`}>Email/Phone</label>
                {errors.emailOrPhone && <p className="error-message">{errors.emailOrPhone}</p>}
              </div>

              {loginMode === 'password' && (
                <div className="input-group ">
                  
                  <input 
                    type="password" 
                    placeholder=" " 
                    className={`input-field ${errors.password ? 'input-error' : ''}`}
                    value={password} 
                    onChange={(e) =>{ setPassword(e.target.value)
                      setErrors({ ...errors, password: '' });
                    }}
                  />
                  <label className={`${errors.password? 'label-error-floating':'label-floating' }`}>Password</label>
                  {errors.password && <p className="error-message">{errors.password}</p>}
                </div>
              )}

              {loginMode === 'otp' && (
                <div className="input-group ">
                  <input 
                    type="text" 
                    placeholder=" " 
                    className={`input-field ${errors.otp ? 'input-error' : ''}`}
                    value={otp} 
                    onChange={(e) => {setOtp(e.target.value)
                      setErrors({ ...errors, otp: '' });
                    }}
      
                  />
                  <label className={`${errors.otp? 'label-error-floating':'label-floating' }`}>OTP</label>
                  {errors.otp && <p className="error-message">{errors.otp}</p>}
                </div>
              )}

              <div className="auth-footer">
                <label className="auth-footer-label">
                  <input type="checkbox" className="mr-2" />
                  <span>Remember Me</span>
                </label>
                <Link to="/forgot-pass" className="auth-footer-link">Forgot Password?</Link>
              </div>

              {loginMode === 'password' && (
                <button 
                  className='auth-button' 
                  onClick={handleLoginWithPass} 
                  disabled={loginMode === 'otp'}
                >
                  Login With Pass
                </button>
              )}
              {!verifyOtpBtn && (
                <button 
                  className='auth-button' 
                  onClick={handleLoginWithOtp} 
                >
                  Login With OTP
                </button>
              )}

              {verifyOtpBtn && (
                <button 
                  className='auth-button' 
                  onClick={handleVerifyOtp}
                >
                  Verify OTP
                </button>
              )}

              {loginMode === 'otp' && timer > 0 && (
                <div className="otp-timer">
                  Resend OTP in {timer} seconds
                </div>
              )}

              {loginMode === 'otp' && resendEnabled && (
                <button 
                  className='auth-button resend-button' 
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </button>
              )}
            </>
          )}

          {toggle && <Register />}
        </div>
      </div>
      <Footer/>
    </>
  );
}
