import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrashAlt, faMapMarkerAlt, faTags, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import '../css/Myadss.css';
import { useNavigate } from 'react-router-dom';
import siteLogo from '../Assets/siteLogo.webp'
import { PDFDocument, rgb } from "pdf-lib";

const FavoritePageCard = ({ data }) => {
  const navigate=useNavigate()
  const [currentImage, setCurrentImage] = useState(0);
  const [isBumping, setIsBumping] = useState(false);

  function decodeHtmlEntities(str) {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = str;
    return textarea.value;
  }
  const handlePromoteClick = (productId) => {
    navigate(`/package?prodId=${productId}`);
  };

  const images = Array.isArray(data.images)
    ? data.images
    : typeof data.images === "string"
    ? decodeHtmlEntities(data.images)
        .replace(/^\[|]$/g, "")
        .split(",")
        .map((image) => image.trim().replace(/^"|"$/g, ""))
    : [];

  const handlePrevImage = () => {
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  const handleNextImage = () => {
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const handleDelete = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/prod/deleteProd`,
        { productId: data.productId },
        { withCredentials: true }
      );

      if (response.status === 200) {
        alert('Item deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('Failed to delete item');
    }
  };

  const [prodId,setProdId]=useState(null)
  const [responseId,setResponseId]=useState()
  const [userData, setUserData] = useState(null);
  const [orderData, setOrderData] = useState(null);

  const handleBumpUp = async(productId) => {
    setProdId(productId)
    setIsBumping(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/order/bumpUp/gateway`,
        { productId: productId },
        { withCredentials: true }
      );

      if (response.status === 200) {
        handleRazorpayScreen(response)
      }else{
        alert('failed')
      }
    } catch (error) {
      console.error('Error bumping up product:', error);
      alert('Failed to bump up product');
    } finally {
      setIsBumping(false);
    }
  }



  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script);
    })
  }




   const handleRazorpayScreen = async(response) => {
      const { key_id, amount ,bumpName,bumpId} = response.data;
      const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")
    
      if (!res) {
        alert("Some error at razorpay screen loading")
        return;
      }
    
      const options = {
        key: key_id,
        amount: amount,
        currency: 'INR',
        name: bumpName,
        description: `payment to Goleezy`,
        image:`${siteLogo}`,
        handler: function (response){
          setResponseId(response.razorpay_payment_id)
          paymentFetch(response.razorpay_payment_id,bumpId)
        },
        prefill: {
          name: "Goleezy",
          email: `${process.env.REACT_APP_EMAIL}`
        },
        theme: {
          color: "#F4C430"
        }
      }
    
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }

    
  

    const paymentFetch = (data,bumpId) => {
  
      const paymentId =data;
    
      axios.get(
        `${process.env.REACT_APP_BACK_URL}/api/order/bumpUp/gateway/payment/${paymentId}`,
        {
          params: {bumpId,prodId}, // Sending the additional field in the query
          withCredentials: true,
        }
      )
        .then((response) => {
        console.log(response.data);
        if(response.status && response.data.paymentStatus==='authorized' && response.data.order.status==='completed')
        {
          // handleConfirmationResponse()
          setOrderData(response.data.order)
          setUserData(response.data.user)
        }else{
          alert('Something went wrong')
        }
      })
      .catch((error) => {
        console.log("error occures", error)
      })
    }


    useEffect(()=>{
      if(userData){
          createPDF()
      }
    },[userData,setUserData])
  
    function formatTimestampToDate(timestamp) {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits for day
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits for month
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    
    // Example usage
  
  
    const createPDF = async () => {
      try {
        const pdfUrl = "/assets/invoice.pdf";
        const existingPdfBytes = await fetch(pdfUrl).then((res) => {
          if (!res.ok) throw new Error("Failed to fetch the PDF file");
          return res.arrayBuffer();
        });
    
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        const { height,width } = firstPage.getSize();
  
        
       // **************** for Invoice uppr part **************
  
       firstPage.drawText(`${orderData.id.slice(1)}`, {
        x: 120,
        y: height-89,
        size: 24,
        // font: boldFont,
        color: rgb(0, 0, 0),
      });
  
  
      let xPositionForInvoUppr = 180;
        let yPositionForInvoUppr = height-140;
        const lineHeightInvoUppr = 18; // Space between lines
    
        // Draw Display Name
        firstPage.drawText(`${orderData.id.slice(1)}`, {
          x: xPositionForInvoUppr,
          y: yPositionForInvoUppr,
          size: 12,
          color: rgb(0, 0, 0),
        });
    
        yPositionForInvoUppr -= lineHeightInvoUppr;
  
       
        firstPage.drawText(`${orderData.createdAt}`, {
          x: xPositionForInvoUppr,
          y: yPositionForInvoUppr,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
        yPositionForInvoUppr -= lineHeightInvoUppr;
  
        
        firstPage.drawText(`${orderData.transactionId}`, {
          x: xPositionForInvoUppr,
          y: yPositionForInvoUppr,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
        yPositionForInvoUppr -= lineHeightInvoUppr;
  
        // Draw Address Parts
        firstPage.drawText(`${orderData.paymentMethod}`, {
          x: xPositionForInvoUppr,
          y: yPositionForInvoUppr,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
  
  
  
        // **************** for seller **************
        const seller = {
          name: "Shubham Sharma",
          address: {
            part1: "Curepipe, Ludhiana",
            part2: "Curepipe, 141010, Mauritius",
          },
          email: "subamsharmaa@gmail.com",
          phone: "9821592852",
        };
  
        // Starting position for the first line
        let xPositionForSeller = 25;
        let yPositionForSeller = 585;
        const lineHeight = 20; // Space between lines
    
        // Draw Display Name
        firstPage.drawText(`${seller.name}`, {
          x: xPositionForSeller,
          y: yPositionForSeller,
          size: 12,
          color: rgb(0, 0, 0),
        });
    
        yPositionForSeller -= lineHeight;
  
        // Draw Address Parts
        firstPage.drawText(`${seller.address.part1}`, {
          x: xPositionForSeller,
          y: yPositionForSeller,
          size: 12,
          color: rgb(0, 0, 0),
        });
    
        yPositionForSeller -= lineHeight;
    
        firstPage.drawText(`${seller.address.part2}`, {
          x: xPositionForSeller,
          y: yPositionForSeller,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
        yPositionForSeller -= lineHeight;
        
        // Draw Email
        firstPage.drawText(`${seller.email}`, {
          x: xPositionForSeller,
          y: yPositionForSeller,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
    
        yPositionForSeller -= lineHeight;
    
        // Draw Phone
        firstPage.drawText(`Phone: ${seller.phone}`, {
          x: xPositionForSeller,
          y: yPositionForSeller,
          size: 12,
          color: rgb(0, 0, 0),
        });
        
  
        // ************ for purchaser *************
  
        // Starting position for the first line
        let xPositionForPurchaser = 297;
        let yPositionForPurchaser = 585;
    
        // Draw Display Name
        firstPage.drawText(`${userData.displayName}`, {
          x: xPositionForPurchaser,
          y: yPositionForPurchaser,
          size: 12,
          color: rgb(0, 0, 0),
        });
    
        // Split the address into two parts
        const addressParts = userData.address.split(',');
        const firstPartPurchaserAddr = addressParts.slice(0, 2).join(',').trim(); // First two parts
        const secondPartPurchaserAddr = addressParts.slice(2).join(',').trim(); // Remaining parts
    
        yPositionForPurchaser -= lineHeight;
  
        // Draw Address Parts
        firstPage.drawText(`${firstPartPurchaserAddr}`, {
          x: xPositionForPurchaser,
          y: yPositionForPurchaser,
          size: 12,
          color: rgb(0, 0, 0),
        });
    
        yPositionForPurchaser -= lineHeight;
    
        firstPage.drawText(`${secondPartPurchaserAddr}`, {
          x: xPositionForPurchaser,
          y: yPositionForPurchaser,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
        yPositionForPurchaser -= lineHeight;
    
        // Draw Email
        firstPage.drawText(`${userData.email}`, {
          x: xPositionForPurchaser,
          y: yPositionForPurchaser,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
    
        yPositionForPurchaser -= lineHeight;
    
        // Draw Phone
        firstPage.drawText(`Phone: ${userData.phone}`, {
          x: xPositionForPurchaser,
          y: yPositionForPurchaser,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
  
  
        // ********** invoice items **********
  
        let xPositionForInvoItem=350
        firstPage.drawText(`${orderData.packageName}`, {
          x:30,
          y: xPositionForInvoItem,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
        const timestamp = orderData.expiredAt;
        const formattedDate = formatTimestampToDate(timestamp);
  
        firstPage.drawText(`${formattedDate}`, {
          x:188,
          y: xPositionForInvoItem,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
        firstPage.drawText(`${orderData.packagePrice} INR`, {
          x:280,
          y: xPositionForInvoItem,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
        
  
        firstPage.drawText(`${orderData.packagePrice} INR`, {
          x:width-90,
          y: xPositionForInvoItem,
          size: 12,
          color: rgb(0, 0, 0),
        });
  
        firstPage.drawText(`${orderData.packagePrice} INR`, {
          x:width-90,
          y:298 ,
          size: 15,
          color: rgb(0, 0, 0),
        });
    
        // Save the updated PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
  
        const file = new File([blob], "invoice.pdf", { type: "application/pdf" });
        sendInvoice(file)
    
        // Automatically trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "invoice.pdf"; // Set the name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
        
      } catch (error) {
        console.error("Error updating PDF:", error);
      }
    };
  
    const sendInvoice = async (file) => {
      try {
        // Create a FormData object to handle file uploads
        const formData = new FormData();
        
        // Append the PDF file
        formData.append("pdf", file);
        
        // Append the order data and user data
        formData.append("orderData", JSON.stringify(orderData));
        formData.append("userData", JSON.stringify(userData));
        
        // Call the API
        const response = await axios.post(
          `${process.env.REACT_APP_BACK_URL}/api/order/send-invoice`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true, // Send cookies along with the request
          }
        );
    
         console.log(response.data," sendInvoice")
      } catch (error) {
        console.error("Error sending invoice:", error);
    
      }
    };
  
  
 

  return (
    <div className="fpc-container">
      <div className="fpc-image-section">
        <div className="fpc-image-slider" style={{ transform: `translateX(-${currentImage * 100}%)` }}>
          {images.map((image, index) => (
            <div key={index} className="fpc-image-container">
              <img src={image} alt={`Product ${index + 1}`} className="fpc-image" />
            </div>
          ))}
        </div>
        <button className="fpc-arrow-btn fpc-left" onClick={handlePrevImage} aria-label="Previous image">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button className="fpc-arrow-btn fpc-right" onClick={handleNextImage} aria-label="Next image">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <div className="fpc-carousel-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`fpc-carousel-dot ${index === currentImage ? 'fpc-active' : ''}`}
              onClick={() => setCurrentImage(index)}
            />
          ))}
        </div>
      </div>
      <div className="fpc-content">
        <div className="fpc-header">
          <h3 className="fpc-title">{data.adName}</h3>
          <button className="fpc-delete-btn" onClick={handleDelete} aria-label="Delete item">
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
        <div className="fpc-info">
          <p className="fpc-location">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="fpc-info-icon" />
            {data.location}
          </p>
          <p className="fpc-price">
            <FontAwesomeIcon icon={faTags} className="fpc-info-icon" />
            ₹{data.price}/Day
          </p>
          <p className="fpc-category">
            <FontAwesomeIcon icon={faTags} className="fpc-info-icon" />
            {data.category}
          </p>
        </div>
        <div className="fpc-footer">
          <div className="fpc-user-info">
            <div className="fpc-user-avatar">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <span className="fpc-username">{data.Username}</span>
          </div>
          <div className="fpc-actions">
            <button className="fpc-promote-btn" onClick={()=>{handlePromoteClick(data.productId)}}>Promote</button>
            <button 
              className={`fpc-bump-btn ${isBumping ? 'fpc-bumping' : ''}`}
              onClick={()=>{handleBumpUp(data.productId)}}
              disabled={isBumping}
            >
              {isBumping ? 'Bumping...' : 'Bump Up'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritePageCard;

