import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faLocationDot,faAt } from '@fortawesome/free-solid-svg-icons';
import logo from "../Assets/siteLogo.webp";
import instagramicon from "../Assets/instagram-svgrepo-com.svg";
import Linkedinicon from "../Assets/linkedin-svgrepo-com.svg";
import Youtubeicon from "../Assets/youtube-168-svgrepo-com.svg";
import '../css/footer.css';
import { faInstagram ,faLinkedinIn,faYoutube} from '@fortawesome/free-brands-svg-icons';
import NewestListings from '../components/NewestListings';
import { Link } from 'react-router-dom';

const Footer = () => {

  function handleRedir(link) {
    window.open(link, '_blank');
  }

  return (
    <>
    <footer>
      <div className="footer-container">
        {/* Contact Info */}
        <div className="footer-contact">
          <img src={logo} alt="Goleezy Logo" className="footer-logo" />
          <p><span className='cont-ico'><FontAwesomeIcon icon={faMobile}/></span>  +91 12345 67890</p>
          <p><span className='cont-ico'><FontAwesomeIcon icon={faLocationDot} /></span> Sector 32, Ludhiana, Punjab, India</p>
          <p><span className='cont-ico'><FontAwesomeIcon icon={faAt} /></span> <a href="mailto:info@goleezy.com" >info@goleezy.com</a></p>
          <h3>Follow our social media</h3>
          <div className="footer-social-icons">
             <div onClick={()=>{handleRedir("https://www.linkedin.com/in/go-leezy-b342b126b/?originalSubdomain=in")}}> <FontAwesomeIcon className='footer-social-icons-fa' icon={faLinkedinIn}  color="white" /></div>
             <div onClick={()=>{handleRedir("https://www.instagram.com/goleezy.au/")}}> <FontAwesomeIcon className='footer-social-icons-fa' icon={faInstagram}  color="white" /></div>
             <div onClick={()=>{handleRedir("https://www.youtube.com/channel/UCutgv9U2FfWM2d2gNLecPkw")}}> <FontAwesomeIcon className='footer-social-icons-fa' icon={faYoutube}  color="white" /></div>
            
            
          </div>
        </div>

        {/* Useful Links */}
        <div className="footer-links">
          <h3>Usefull links</h3>
          <ul>
          <li className='spec-About'><Link to="/about-us">About Us</Link></li>
<li><Link to="/contact">Contact Us</Link></li>
<li><Link to="/auth">Login / Sign up</Link></li>
<li><Link to="/privacy-policy">Privacy Policy</Link></li>
<li><Link to="/terms">Terms & Conditions</Link></li>
<li><Link to="/refund-policy">Refund Policy</Link></li>
          </ul>
        </div>


        <NewestListings/>
      </div>

      
      
    </footer>
    <div className="footer-bottom">
        <p>© 2024 Goleezy. All Rights Reserved.</p>
      </div>
      </>
  );
};

export default Footer;
