import React, { useEffect, useState,useRef } from "react";
import "../css/productpage.css";
import {
  FaMapMarkerAlt,
  FaUser,
  FaClock,
  FaEye,
  FaRocketchat,
} from "react-icons/fa";
// import {  FaTimes } from 'react-icons/fa';
import Navbar from "./navbar";
import Footer from "./footer";
import ProductCard from "../pages/productPageCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { Navigation, Pagination, Zoom } from "swiper/modules";
import Zoomin from "../Assets/zoom-in-1-svgrepo-com.svg";
import {
  FaThumbsUp,
  FaExclamationTriangle,
  FaRegThumbsUp,
  FaHeart,
  FaBalanceScale,
  FaFacebook,
  FaPrint,
} from "react-icons/fa";
// import "swiper/css/navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faCircle,
  faMobileAlt,faPaperclip,
  faStar,
faPlus
} from "@fortawesome/free-solid-svg-icons";

import { faFacebookF, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faUser, faMessage } from "@fortawesome/free-regular-svg-icons";
import AttachIcon from "../Assets/attach-svgrepo-com.svg";
import reportIcon from '../Assets/diamond-exclamation.svg'
import ImageForProductPage from "../components/ImageForProductPage";
import ImagePopup from "../components/imageForProdPop";
import SuggestionProductPageSlider from "../components/SuggestionProductPageSlider";

import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import ReviewForm from "../components/ReviewForm";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import useStore from '../components/authmiddle/store';


const ProductPage = () => {
  const { isAuthenticated} = useStore();
  useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
      }, []);


  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  // });

 
  const navigate = useNavigate();
  const [productData, setProductData] = useState(null); // State for product data
  const [sellerData, setSellerData] = useState(null);   // State for seller data
  const location = useLocation(); // To access the query params from the URL
  
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        // Get the productId from the query string
        const queryParams = new URLSearchParams(location.search);
        const productId = queryParams.get('id'); // Get the 'id' query parameter
  
        if (productId ) {
          let productResponse;
          // Fetch the product data
          if(isAuthenticated){
           productResponse = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/prod/fetchProdById?id=${productId}`,
            { withCredentials: true }
          );
        }else{
          productResponse = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/prod/fetchProdById-v1?id=${productId}`
          );
        }
  console.log(productResponse.data.data)
          if (productResponse.data && productResponse.data.data) {
            
            const parsedProductData = productResponse.data.data
  ? {
      ...productResponse.data.data, // Spread the existing product properties
      images: typeof productResponse.data.data.images === "string"
        ? JSON.parse(productResponse.data.data.images) // Parse images if they are in string format
        : productResponse.data.data.images, // Otherwise, use the existing images
  }
  : null; // If no data, set as null

setProductData(parsedProductData); 
fetchSellerData(parsedProductData.email)
console.log(parsedProductData.email)
          }
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
  
    fetchProductData();
  }, [location.search]);
  
  // useEffect(() => {
    const fetchSellerData = async (email) => {
      console.log("SellerData",email)
      try {
        let sellerResponse;
        if(isAuthenticated){
           sellerResponse = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/prod/fetchSellerByEmail?email=${email}`,
            { withCredentials: true }
          );
        }else{
          sellerResponse = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/prod/fetchSellerByEmail-v1?email=${email}`
           
          );
        }
          console.log(sellerResponse.data.data);
          setSellerData(sellerResponse.data.data); // Store the seller data
      
      } catch (error) {
        console.error('Error fetching seller data:', error);
      }
    };
  
    
      // fetchSellerData(); // Fetch seller data only if productData is available
   
  // }, [productData]); // This will trigger when productData is updated
  

  const [listings, setListings] = useState([]);

  useEffect(() => {
    const fetchRandomListings = async () => {
      try {
        let response;
        if(isAuthenticated){
         response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/prod/fetchRandomListings`,{ withCredentials: true });
        }else{
          response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/prod/fetchRandomListings-v1`);
        }
       
        if (response.data && response.data.listings) {
          setListings(response.data.listings);
        } else {
          console.error("No listings data found in the response.");
        }
      } catch (error) {
        console.error("Error fetching random listings:", error);
      }
    };

    fetchRandomListings();
  }, []); // Empty dependency array ensures this runs only once when the component mounts



  const [width, setwidth] = useState(0);
  // const [rating, setRating] = useState(0);  
  const [review, setReview] = useState(""); // State to track the review text
  const [viewarrow, setviewarrow] = useState("block"); // State to track the review text
  const [currentListingIndex, setCurrentListingIndex] = useState(0); // State to track the current listing index
  const [isZoomed, setIsZoomed] = useState(false); // State to control zoom modal visibility
  const [currentImage, setCurrentImage] = useState(""); // State for the current zoomed image

  const productListRef = useRef(null);
  const firstProductCardRef = useRef(null);
  
  // Open the zoom modal with the selected image
  const handleZoomIn = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsZoomed(true);
  };

  // Close the zoom modal
  const handleZoomOut = () => {
    setIsZoomed(false);
  };


  const handleNextListing = () => {
    setCurrentListingIndex((prevIndex) => (prevIndex + 1) % listings.length);
  };

  const handlePrevListing = () => {
    setCurrentListingIndex(
      (prevIndex) => (prevIndex - 1 + listings.length) % listings.length
    );
  };
  const isMobile = window.innerWidth <= 768;
  useEffect(() => {
    setwidth(window.innerWidth);
    setviewarrow(isMobile ? "none" : "block");
  });
  

  const fileInputRef = useRef(null);

  // Function to trigger the file input when clicking on the div
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file);
      // Handle file upload logic here
    }
  };


  const [text, setText] = useState('');
  const maxCharacters = 200;
  const minCharacters = 0; // Change this if you have a specific minimum character requirement

  const handleTextChange = (e) => {
    if (e.target.value.length <= maxCharacters) {
      setText(e.target.value);
    }
  };

  const [isZoom,setIsZoom]=useState(false);
 

  const handleZoom=()=>{
    
    setIsZoom(!isZoom)

  }


  const MakeFriendButton = async() => {
    if(isAuthenticated){
      try {
        // Send a POST request to the backend to make a friend
        const response = await axios.post(
          `${process.env.REACT_APP_BACK_URL}/api/addFriends`, // Replace with your endpoint
          { sellerEmail:sellerData.email }, // Payload with the seller's ID
          { withCredentials: true } // Include cookies for authentication
        );
        console.log("Friend request sent successfully:", response.data);
        

        if(response.status===200){
          // alert("Friend request sent successfully!");
          navigate("/profile", { state: { fromButton: "Messages" } });
        }
      } catch (error) {
        console.error("Error sending friend request:", error);
        alert("Failed to send friend request. Please try again.");
      }
    }else{
      navigate('/auth')
    }
    };


    // if (!isLoaded) return <div>Loading map...</div>;

  // const category = "Cars";
  return (
    <>
    {productData &&<div className="prodP-Supreme">
      <Navbar />

      {isZoom &&<ImagePopup
           handleZoom={handleZoom}
           images={productData.images}
                
    />}

      {/* <div className="breadcrumb-container-parent">
      <div className="breadcrumb-container">
          <div className="breadcrumb">
            <span className="black-ur">Home</span>
            <span className="arrow-ur">›</span>
            <span className="black-ur">Search Results</span>
            <span className="arrow-ur">›</span>
            <span className="black-ur">Cars</span>
            <span className="arrow-ur">›</span>
            <span className="current">Creta automatic</span>
          </div>
          <div className="breadcrumb-icons">
            <div className="breadcrumb-icon fas-sh">
              <FontAwesomeIcon icon={faFacebookF} />
              <span className="product-icons-tooltip">
                        Share on Facebook
              </span>
            </div>
            <div className="breadcrumb-icon twit-sh">
              <FontAwesomeIcon icon={faXTwitter} />
              <span className="product-icons-tooltip">
                        Share on Twitter
              </span>
            </div>
          </div>
        </div>
      </div> */}


      <div className='product-page-cnt'>
        
          {/* <div className="product-page-sub-cnt"> */}
            <div className="product-page">
              <div className="product-content">
                
                

                
          <div className="product-page-img-cont" >
           
              <ImageForProductPage
                handleZoom={handleZoom}
                images={productData.images}
               
              />
            
          </div>
       




                <div className="Prod_desc">
                  <div className="prod-icons">
                    <div className="prod_left_icons">
                      <div className="prod_icon">
                        <FaUser
                          className="icons"
                          style={{
                            backgroundColor: "#DDECF7",
                            fontSize: "12px",
                          }}
                        />
                      </div>
                      <span>{sellerData && sellerData.accountType}</span>
                    </div>
                    <div className="prod_right_icons">
                      <div className="prod_icon">
                        <FaClock
                          className="icons"
                          style={{ backgroundColor: "#DDECF7" }}
                        />
                      </div>
                      <span>{productData.postDate}</span>
                      <div className="prod_icon">
                        <FaEye
                          className="icons"
                          style={{ backgroundColor: "#DDECF7" }}
                        />
                      </div>
                      <span>{productData.views} Views</span>
                    </div>
                  </div>
                  <div className="Product-heading">
                    <h2 style={{ color: "#374B5C", fontSize: "30px" }}>
                      {productData.adName}
                    </h2>
                  </div>

                  <p className="Category">Category: {productData.category}</p>

                  <div className="product-location">
                    <div className="Icon-l">
                      <FaMapMarkerAlt className="loc_icon" />
                    </div>
                    {productData.location}{" "}
                    <a href="#map-sec" className="SeeMap-btn">
                      See map
                    </a>
                  </div>

                  <div className="product-price">
                    <p className="Price">
                      <strong>₹{productData.price}/Day</strong>
                    </p>
                  </div>
                  <div className="price-period-div">
                    <p className="price-period">Price Per Day: ₹{productData.price}/Day</p>
                  </div>
                  <div className="product-description">
                    <h3 style={{ marginTop: "20px" }}>Description</h3>
                    {/* {desc.sections.map((section, index) => (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        <h2>{section.heading}</h2>
                        <p>{section.paragraph}</p>
                      </div>
                    ))} */}

                      <div  style={{ marginBottom: "20px" }}>
                        {/* <h2>{section.heading}</h2> */}
                        {productData.description}
                      </div>

                    <h3 style={{ marginTop: "20px" }}>Features</h3>
                    <div style={{ color: "#374B5C", marginTop: "15px" }}>
                      Price Per Day: ₹{productData.price}/Day
                    </div>

                    <h3 style={{ marginTop: "20px" }}>Location</h3>
                    {/* <div className="map-div">
                      <iframe
                        src={productData.mapUrl}
                        width="100%"
                        height="323"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Map of Goa"
                      ></iframe>
                    </div> */}
                    <section id="map-sec">
                   {productData && productData.lat&& <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: productData.lat, lng: productData.lng }}
        zoom={12}
      >
        <Marker position={{ lat: productData.lat, lng: productData.lng }} />
      </GoogleMap>}
      </section>
                  </div>

                  <h3
                    style={{
                      color: "#374B5C",
                      fontSize: "24px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "700",
                      borderTop: " 2px solid #F2F4F8",
                      borderBottom: "2px solid #F2F4F8",
                      marginTop: "30px",
                    }}
                  >
                    Write a Review
                  </h3>
                  
                    <ReviewForm productId={productData.productId}/>
                 
                </div>
              </div>
              <div className="site_imfo">
                {sellerData &&<div className="user_profile">
                  <div className="user_app_info">
                    {/* <div className="icon_area">
                      <FontAwesomeIcon icon={faUser} className="inner" />
                      <img src={sellerData.profileimage} className="inner"/>
                    </div> */}
                    <div className="icon_area">
                      <img src={sellerData.profileimage} className="inner"/>
                    </div>
                    <div className="user_per_info">
                      <a href="#">{sellerData?.displayName || "Seller"}</a>
                      <h3 style={{ marginTop: "5px" }}>
                        Member since: 4 months
                      </h3>
                      <h3 style={{ marginTop: "5px" }}>
                        Account type: {sellerData.accountType}
                      </h3>
                      <h4 className={sellerData.isOnline ? 'online' : ''}>User is {sellerData.isOnline?'Online':'Offline'}</h4>
                      <a href="#" id="adds">
                        See all ads
                      </a>
                    </div>
                  </div>
                  <div className="cont_info">
                    <FontAwesomeIcon icon={faMobileAlt} className="icon" />
                    <a href="#">{sellerData.phone}</a>
                  </div>
                  <div className="chat_button">
                    <Button type="button" onClick={MakeFriendButton}>
                      <FontAwesomeIcon
                        icon={faMessage}
                        style={{ height: "16px", width: "16px" }}
                      />{" "}
                      Chat
                    </Button>
                  </div>
                </div>}
                <div className="product-icons_">
                  <div className="product-icons-child">
                    <div className="icon hea">
                      <FaHeart style={{ fontSize: "16px" }} />
                      <span className="product-icons-tooltip">
                        Add to Favorites
                      </span>
                    </div>
                    <div className="icon com">
                      <FaBalanceScale style={{ fontSize: "16px" }} />
                      <span className="product-icons-tooltip">
                        Add to Compare
                      </span>
                    </div>
                    <div className="icon fas">
                      <FaFacebook style={{ fontSize: "16px" }} />
                      <span className="product-icons-tooltip">
                        Share on Facebook
                      </span>
                    </div>
                    <div className="icon prin">
                      <FaPrint
                        style={{ fontSize: "16px" }}
                        className="faPrin"
                      />
                      <span className="product-icons-tooltip">Print</span>
                    </div>
                  </div>
                </div>

                <div className='report-cnt'>

                   <img className="report-img" src={reportIcon} alt='report-error'></img>
                   <span className="report-span">Report abuse</span>
          
                </div>


                <div className="Featured listings">
                  <div className="headings">
                    <h1 >Featured listings</h1>
                  </div>
                  <div className="Listings">
                    {/* Show only the current listing */}
                    <ProductCard
                      listings={listings}
                    />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

         {sellerData && <SuggestionProductPageSlider sellerEmail={sellerData.email} buttonMsg={"Display all from goacarrentals"} headingMsg={"More from this user"}  buttonMobMsg={"Display All"}/>}
       
      
      
       
      <Footer />
    </div>}
    </>
  );
};       

export default ProductPage;
