import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./pages/Home";
import Auth from './pages/Auth';
import PrivateRoute from './components/authmiddle/PrivateRoute';
import useAuth from './components/authmiddle/useAuth';
import ForgotPass from './pages/ForgotPass';
import Productpage from "./pages/productpage";
import Profile from "./pages/profile";
import Comparison from "./pages/Comparison";
import ProductSearch from "./pages/productlistSearch"
import Package from "./pages/package"
import useStore from './components/authmiddle/store';
import DescriptionEditor from './DescriptionEditor';
import { Search } from 'lucide-react';
import { LoadScript } from '@react-google-maps/api';
import googleMapsConfig from './components/authmiddle/googleMapsConfig';
import RefundPolicy from './pages/RefundPolicy';
import Terms from './pages/terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
function App() {
  // const { authenticated } = useAuth();
  const {  isAuthenticated } = useStore();

  return (
    <>
    <LoadScript
    googleMapsApiKey={googleMapsConfig.googleMapsApiKey}
    libraries={googleMapsConfig.libraries}
    language={googleMapsConfig.language}
    region={googleMapsConfig.region}
  >
    <Routes>
      {/* Private route for Home, only accessible if authenticated */}
      <Route element={<PrivateRoute />}>
      <Route path="/" element={<Home/>} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/package" element={<Package/>} />
      <Route path="/desc" element={<DescriptionEditor/>}/>
      </Route>
      {/* Public routes for Login and Register, but hide them if authenticated */}
      <Route path="/auth" element={isAuthenticated? <Navigate to="/" /> : <Auth />} />
      <Route path="/forgot-pass" element={isAuthenticated? <Navigate to="/" /> : <ForgotPass />} />
      
    
      {/* Redirect any unknown routes to Home */}
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/product" element={<Productpage/>} />
      <Route path="/search" element={<ProductSearch/>} />
      <Route path="/compare" element={<Comparison/>} />
      <Route path="/contact" element={<Contact/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/refund-policy" element={<RefundPolicy/>} />
      <Route path="/terms" element={<Terms/>} />
      <Route path="/about-us" element={<AboutUs/>} />
      
    </Routes>
    </LoadScript>
    </>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
