import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Eye from "../Assets/eye-svgrepo-com.svg";
import "../css/quickViewCard.css";
import {
  FaClock,
  FaEye,
  FaMapMarkerAlt, 
  FaHeart,
  FaBalanceScale,
} from "react-icons/fa";

import Heart from "../Assets/heart-svgrepo-com.svg";

const QuickViewCard = ({ data, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { images, name, category, address, price, views, timeAgo } = data;

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/product?id=${data.productId}`);
  };

  return (
    <div className="quick-view-card-overlay">
      <div className="quick-view-card">
      <button className="quick-view-close-btn" onClick={onClose}>
            &#10005;
          </button>
        <div className="quick-view-image-section">
         

          
          <button
            className="quick-view-arrow-btn left"
            style={{ display: currentImageIndex === 0 ? 'none' : 'flex' }}
            onClick={(e)=>{
              e.stopPropagation();
              handlePrevClick()
              }}
          >
            &larr;
          </button>

          <div className="slider-wrapper">
          <div
          className="image-slider"
          style={{
            transform: `translateX(-${currentImageIndex * 100}%)`,
          }}
        >


          {images.map((image, index) => (
            <div
              key={index}
              className="slider-image-container"
            >
              <img
                src={image}
                alt={`image-${index}`}
                className="quick-image"
              />
            </div>
          ))}

          </div>
      </div>

          <button
            className="quick-view-arrow-btn right"
            style={{ display: currentImageIndex === images.length - 1 ? 'none' : 'flex' }}
            onClick={(e)=>{
              e.stopPropagation();
              handleNextClick()}}

          >
            &rarr;
          </button>

          <div className="carousel-dots-cont">
            <div className="carousel-dots">
              {images.map((_, index) => {
                const distance = Math.abs(index - currentImageIndex);
                const size = Math.max(10 - distance * 2, 4);

                return (
                  <span
                    key={index}
                    className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
                    style={{
                      width: `${size}px`,
                      height: `${size}px`,
                    }}
                  ></span>
                );
              })}
            </div>
          </div>
        </div>
       

        <div className="quick-view-info-section">
        
          <div className="quick-view-info-section-ch1">
          <div className="quick-card-time">
                 <div className="quick-card-time-ch">
                                <div className="prod_icon">
                                  <FaClock
                                    className="icons"
                                    style={{ backgroundColor: "#DDECF7" }}
                                  />
                                </div>
                                <span>2 Days</span>
                                </div>

                                <div className="quick-card-time-ch">
                                <div className="prod_icon">
                                  <FaEye
                                    className="icons"
                                    style={{ backgroundColor: "#DDECF7" }}
                                  />
                                </div>
                                <span>{views} Views</span>
                                </div>
                              </div>
          
          <h3 className="quick-view-name">{name}</h3>
          <div className="quick-view-category">
           <span>{category}</span>
          </div>
          <p className="quick-view-address">
          <div className="Icon-l">
                                <FaMapMarkerAlt className="loc_icon" />
          </div>
            {address}</p>
          <p className="quick-view-price">₹{price}</p>

          </div>

          <div className="quick-view-info-section-ch2">
          <button
            className="quick-view-more-btn"
            onClick={handleViewMoreClick}
          >
            View more
          </button>

          <div className="quick-view-icons_">
          <div className="product-icons-child">
          <div className="icon hear">
                                <FaHeart style={{ fontSize: "16px" }} />
                                <span className="quick-view-icons-tooltip">
                                  Add to Favorites
                                </span>
                              </div>
                              <div className="icon comp">
                                <FaBalanceScale style={{ fontSize: "16px" }} />
                                <span className="quick-view-icons-tooltip">
                                  Add to Compare
                                </span>
                              </div>
                              </div></div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default QuickViewCard;
