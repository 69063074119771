import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "../css/corousal.css";
import axios from 'axios';

const CarouselComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/carousel`);
        setImages(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching carousel data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="carousel-skeleton">
        <div className="skeleton-slide"></div>
      </div>
    );
  }

  return (
    <Carousel 
      infiniteLoop={true} 
      autoPlay={true} 
      interval={3000} 
      showArrows={false} 
      showThumbs={false} 
      showStatus={false} 
    >
      {images.map((image, index) => (
        <div key={index}>
          <img src={image.image || "/placeholder.svg"} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
