import React, { useState, useEffect } from 'react';
import Navbar from "./navbar";
import "../css/home.css";
import CorousalContainer from "./corousal";
import CategoryCard from "./categorycard";
import ProductList from './productlist';
import "../css/categorypage.css";
import Tutorial from './tutorial';
import DiscoverMore from './discovermore';
import Footer from "./footer";
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

export default function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [categories, setCategories] = useState([]); // State to store fetched categories
  const navigate =useNavigate()
  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios(`${process.env.REACT_APP_BACK_URL}/api/fetchCat`); // Adjust URL based on your server setup
        // Sort categories by priority (ascending order)
        const sortedCategories = response.data.sort((a, b) => a.priority - b.priority);
        setCategories(sortedCategories); // Set the sorted categories
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    fetchCategories();
  }, []);

  // Detect screen size on initial load and resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Capitalize the first letter of each category name
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

const handleSearchSubmit = (data) => {
      
        if (data.trim()) {
            navigate("/search", { state: { query: data.trim() } });  // Pass query to search page
        }
    };

  
  return (
    <>
      <Navbar />
      
      {/* Render categories above carousel on larger screens */}
      {!isMobile && (
        <div className="category-page">
          <div className="category-grid">
            {categories.map((category, index) => (
              <CategoryCard 
                key={index} 
                name={capitalizeFirstLetter(category.categoryName)} // Capitalize name
                image={category.image} 
                handleSearchSubmit={handleSearchSubmit}
              />
            ))}
          </div>
        </div>
      )}

      <CorousalContainer/>
      
      {/* Render categories below carousel on mobile */}
      {isMobile && (
        <div className="category-page">
          <h1>What are you looking to rent?</h1>
          <div className="category-grid">
            {categories.map((category, index) => (
              <CategoryCard 
                key={index} 
                name={capitalizeFirstLetter(category.categoryName)} // Capitalize name
                image={category.image} 
                handleSearchSubmit={handleSearchSubmit}
              />
            ))}
          </div>
        </div>
      )}

      <ProductList />
      <Tutorial />
      <DiscoverMore />
      <Footer />
    </>
  );
}
