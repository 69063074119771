import React, { useState, forwardRef, useEffect } from 'react';
import '../css/productCardSearch.css';
import Heart from "../Assets/heart-svgrepo-com.svg";
import Compare from "../Assets/git-compare-svgrepo-com.svg";
import Eye from "../Assets/eye-svgrepo-com.svg";
import QuickViewCard from '../components/QuickViewCard';
import HeartFilled from "../Assets/Assets_heart-filled-svgrepo-com.svg";
import axios from 'axios';
import useStore from '../components/authmiddle/store';
import { useNavigate } from 'react-router-dom';
import {
  FaMapMarkerAlt,
} from "react-icons/fa";
const ProductCard = forwardRef(({ images, name, address, price, BumpUp,views, Priority,postDate,expiredAT,initialLikes, isliked, category, email, productId, isCompared, onCompare }, ref) => {
  const navigate=useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isQuickViewVisible, setIsQuickViewVisible] = useState(false);
  const [likeState, setLikeState] = useState({
    count: initialLikes || 0,
    isLiked: isliked || false,
    isLoading: false,
    error: null,
  });
  const { isAuthenticated } = useStore();
  // const navigate = useNavigate();
  function checkIfFeatured(expiredAT) {
    const isNotExpired = expiredAT > Date.now(); // Check if expiredAt is in the future
    return isNotExpired; // Both conditions must be true
  }
  console.log("isliked",isliked)
  console.log("expiredAt",expiredAT)
  const isFeatured = checkIfFeatured(expiredAT);
console.log("isfeatured:",isFeatured);
  useEffect(() => {
    setLikeState(prevState => ({
      ...prevState,
      isLiked: isliked,
      count: initialLikes || 0,
    }));
  }, [isliked, initialLikes]);
console.log(BumpUp)
  const handleLikeToggle = async (e) => {
    if (!isAuthenticated) {
      navigate('/auth');
      return;
    }
    e.stopPropagation();
    if (likeState.isLoading) return;

    setLikeState(prevState => ({ ...prevState, isLoading: true, error: null }));

    const newIsLiked = !likeState.isLiked;
    try {
      const endpoint = newIsLiked
        ? `${process.env.REACT_APP_BACK_URL}/api/prod/like`
        : `${process.env.REACT_APP_BACK_URL}/api/prod/unlike`;

      await axios.post(endpoint, { productId }, { withCredentials: true });

      setLikeState(prevState => ({
        ...prevState,
        isLiked: newIsLiked,
        count: newIsLiked ? prevState.count + 1 : prevState.count - 1,
        isLoading: false,
      }));
    } catch (error) {
      setLikeState(prevState => ({
        ...prevState,
        isLiked: newIsLiked,
        count: newIsLiked ? prevState.count + 1 : prevState.count - 1,
        isLoading: false,
      }));
      // setLikeState(prevState => ({
      //   ...prevState,
      //   isLoading: false,
      //   error: "Failed to update like status. Please try again.",
      // }));
    }
  };
console.log(Priority);
  const handlePrevClick = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const openQuickView = (e) => {
    e.stopPropagation();
    setIsQuickViewVisible(true);
  };

  const handleCompareClick = (e) => {
    e.stopPropagation();
    onCompare();
  };

  const closeQuickView = () => {
    setIsQuickViewVisible(false);
  };

  const handleViewMoreClick = (productId) => {
    navigate(`/product?id=${productId}`);
  };


  return (
    <>
      <div 
        className={`product-card1 ${isFeatured ? 'featured' : ''}`} 
        ref={ref} 
        onClick={() => { handleViewMoreClick(productId) }}
        style={{ order: isFeatured ? -1 : 0, position: 'relative' }}
      >
      {isFeatured && (
  <div className="featured-label" style={{
    position: 'absolute',
    top: '10px',
    left: '10px',
    backgroundColor: '#308616',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '0 0 4px 0',
    fontSize: '12px',
    fontWeight: 'bold',
    zIndex: 10
  }}>
    Featured
  </div>
)}
        <div className="carousel-product-card1" style={{ paddingTop: 0 }}>
          <button
            className="product-card1-arrow-btn left"
            style={{ display: currentImageIndex === 0 ? 'none' : 'flex' }}
            onClick={handlePrevClick}
          >
            &larr;
          </button>
          
          <div className="slider-wrapper">
            <div
              className="image-slider"
              style={{
                transform: `translateX(-${currentImageIndex * 100}%)`,
              }}
            >
              {images.map((image, index) => (
                <div
                  key={index}
                  className="slider-image-container"
                >
                  <img
                    src={image || "/placeholder.svg"}
                    alt={`image-${index}`}
                    className="product-image"
                  />
                </div>
              ))}
            </div>
          </div>

          <button
            className="product-card1-arrow-btn right"
            style={{ display: currentImageIndex === images.length - 1 ? 'none' : 'flex' }}
            onClick={handleNextClick}
          >
            &rarr;
          </button>

          <div className="carousel-dots-cont1">
            <div className="carousel-dots">
              {images.map((_, index) => {
                const distance = Math.abs(index - currentImageIndex);
                const size = Math.max(10 - distance * 2, 4);

                return (
                  <span
                    key={index}
                    className={`carousel-dot ${index === currentImageIndex ? 'active' : ''}`}
                    style={{
                      width: `${size}px`,
                      height: `${size}px`,
                    }}
                  ></span>
                );
              })}
            </div>
          </div>
        </div>

        <div className="product-info1">
          <h3 className="product-name1">{name}</h3>
          {/* <p className="product-address1">{address}</p> */}
           <div className="product-card-location">
               <div className="product-card-Icon-l">
              <FaMapMarkerAlt className="loc_icon" />
            </div>
            {address.length <= 30 ? (
              address
            ) : (
              <>
                {address.substring(0, 30)}...
               
              </>
            )}
          </div>
           {/* {address.length > 45 &&<span className="more-text" >
                  more
                </span>} */}
          <p className="product-price1">₹{price}/Day</p>
        </div>

        <div className="product-actions1">
          <div className="actions">
            <button className="quick-view" onClick={openQuickView}>
              <img src={Eye || "/placeholder.svg"} alt="View" />
              <span className="tooltip">Quick View</span>
            </button>
            <div className="actionsearch">
            <button
  className={`compare1 ${isCompared ? 'active' : ''}`}
  onClick={handleCompareClick}
  style={{
    borderRadius: "50%",
    padding: 0,
    width: "30px",
    height: "30px",
    border: "1px solid #DDECF7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.3s ease-in-out",
    position: "relative",
    cursor: "pointer",
    color: "white",
    // filter: isCompared ? "brightness(0) invert(1)" : "none", // Apply filter conditionally
    backgroundColor: isCompared ? "#ff6b4a" : "transparent", // Apply background color conditionally
  }}
>
  <img src={Compare || "/placeholder.svg"} alt="Compare" />
  <span className="tooltip">
    {isCompared ? "Remove from Compare" : "Add to Compare"}
  </span>
</button>

            </div>
            
            <button
              className="like"
              onClick={handleLikeToggle}
              disabled={likeState.isLoading}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: likeState.isLoading ? 'not-allowed' : 'pointer',
              }}
            >
              <img 
                src={likeState.isLiked ? HeartFilled : Heart} 
                alt={likeState.isLiked ? "Unlike" : "Like"} 
                style={{
                  width: '24px',
                  height: '24px',
                  transition: 'transform 0.2s ease',
                  transform: likeState.isLiked ? 'scale(1.2)' : 'scale(1)',
                  opacity: likeState.isLoading ? 0.5 : 1,
                }}
              />
              <span className="tooltip">
                {likeState.isLiked ? 'Unlike' : 'Add to Favorites'}
              </span>
            </button>
          </div>
          
          <p className="views-count">{views} views</p>
        </div>
        {likeState.error && (
          <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{likeState.error}</p>
        )}
      </div>

      {isQuickViewVisible && (
        <QuickViewCard
          data={{ images, name, address, price, category: category, timeAgo: postDate, views: views, email, productId }}
          onClose={closeQuickView}
        />
      )}
      <style jsx>{`
        .featured-label {
          position: absolute;
          top: 10px;
          left: 10px;
          background-color: #308616;
          color: white;
          padding: 5px 10px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          z-index: 10;
        }
        .product-card1.featured {
          border-top: none;
        }
      `}</style>
    </>
  );
});

export default ProductCard;

