import React, { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const DescriptionEditor = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorChange = (state) => {
    setEditorState(state);
  };

  const getFormattedDescription = () => {
    const rawContent = convertToRaw(editorState.getCurrentContent());
    const sections = rawContent.blocks.reduce((acc, block) => {
      if (block.type === "header-one" || block.type === "header-two") {
        // Treat header blocks as headings
        acc.push({ heading: block.text, paragraph: "" });
      } else if (block.type === "unstyled") {
        // Add paragraph text to the last section
        if (acc.length > 0) {
          acc[acc.length - 1].paragraph += block.text + " ";
        }
      }
      return acc;
    }, []);
    return sections;
  };

  const handleSave = () => {
    const descriptionSections = getFormattedDescription();
    console.log("Formatted Description:", descriptionSections);
    // Post descriptionSections to your backend or use it in your application
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={handleEditorChange}
      />
      <button onClick={handleSave}>Save Description</button>
    </div>
  );
};

export default DescriptionEditor;
