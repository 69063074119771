import React, { useState } from "react";
import "../css/imageForProductPage.css";

const SuggestionImageCom = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="prod-page-img-sub-cont-for-suggest">
      <button
        className="Suggest-Prod-arrow-btn left"
        onClick={handlePrevClick}
        style={{ display: currentImageIndex === 0 ? "none" : "flex" }}
      >
        &larr;
      </button>
      <div className="slider-wrapper">
        <div
          className="image-slider"
          style={{
            transform: `translateX(-${currentImageIndex * 100}%)`,
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className="slider-image-container"
            >
              <img
                src={`${image}`}
                alt={`image-${index}`}
                className="Suggest-Prod-slider-image"
              />
            </div>
          ))}
        </div>
      </div>
      <button
        className="Suggest-Prod-arrow-btn right"
        onClick={handleNextClick}
        style={{ display: currentImageIndex === images.length - 1 ? "none" : "flex" }}
      >
        &rarr;
      </button>
      <div className="carousel-dots-cont">
        <div className="carousel-dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`carousel-dot ${
                index === currentImageIndex ? "active" : ""
              }`}
            ></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SuggestionImageCom;
