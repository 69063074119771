import React from "react"
import { X } from "lucide-react"
import { useNavigate } from "react-router-dom"

const CompareView = ({ products, onClose, onRemove }) => {
  const navigate = useNavigate()

  const handleCompareClick = () => {
    navigate("/compare", { state: { products } })
  }

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-2 sm:p-4">
      <div className="bg-white rounded-lg p-3 sm:p-4 w-full max-w-6xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-3 sm:mb-4">
          <h2 className="text-lg sm:text-xl font-bold">Compare Products</h2>
          <button onClick={onClose} className="p-1 sm:p-2 hover:bg-gray-100 rounded-full">
            <X className="w-4 h-4 sm:w-5 sm:h-5" />
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 mb-4">
          {products.map((product) => (
            <div key={product.productId} className="relative">
              <button
                onClick={() => onRemove(product.productId)}
                className="absolute -top-1 -right-1 z-10 bg-white rounded-full shadow-md p-0.5"
              >
                <X className="w-3 h-3 sm:w-4 sm:h-4" />
              </button>
              <div className="border rounded-lg overflow-hidden">
                <div className="relative w-full h-32 sm:h-40">
                  <img
                    src={product.images[0] || "/placeholder.svg"}
                    alt={product.adName}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-2 sm:p-3">
                  <h3 className="font-semibold text-sm sm:text-base truncate">{product.adName}</h3>
                  <p className="text-xs sm:text-sm text-gray-600 truncate">{product.location}</p>
                  <p className="text-sm sm:text-base font-bold text-green-600 mt-1">₹{product.price}</p>
                </div>
              </div>
            </div>
          ))}

          {Array.from({ length: 3 - products.length }).map((_, i) => (
            <div
              key={`placeholder-${i}`}
              className="border-2 border-dashed rounded-lg h-[200px] flex items-center justify-center"
            >
              <p className="text-gray-400 text-sm">Add product to compare</p>
            </div>
          ))}
        </div>

        <div className="flex justify-center">
          <button
            className="bg-green-600 text-white py-2 px-4 sm:px-6 rounded-lg hover:bg-green-700 transition-colors text-sm sm:text-base"
            onClick={handleCompareClick}
          >
            Compare
          </button>
        </div>
      </div>
    </div>
  )
}

export default CompareView

