import React, { useRef, useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Navbar from './navbar';
import { useNavigate } from "react-router-dom";
import "../css/comparison.css";
import Footer from './footer';

const Comparison = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [comparisonData, setComparisonData] = useState(null);
  const [products, setProducts] = useState([]);
  const productsRef = useRef(null);

  useEffect(() => {
    if (state?.products) {
      setProducts(state.products);
    }
  }, [state]);
  const handleViewProduct = (productId) => {
    navigate(`/product?id=${productId}`);
  }
  
  const scrollProducts = (direction) => {
    if (productsRef.current) {
      const scrollAmount = direction === 'left' ? -280 : 280;
      productsRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <>
      <Navbar />
      <div className="compare-container">
        <div className="compare-content">
          <div className="sidebar">
            {/* Sidebar content */}
          </div>

          <div className="products-wrapper">
            <div className="products" ref={productsRef}>
              {products.map((product) => (
                <div key={product.productId} className="product">
                  <div className="product-header">
                    <button className="lock-button">
                      <svg viewBox="0 0 24 24" className="lock-icon" fill="none" stroke="currentColor">
                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
                        <path d="M7 11V7a5 5 0 0110 0v4" />
                      </svg>
                      Lock
                    </button>
                    <button className="close-button">×</button>
                  </div>

                  <div className="product-image">
                    <img src={product.images[0]} alt={`${product.adName}`} />
                  </div>

                  <h2 className="product-title">{product.adName}</h2>

                  <div className="product-details">
                    <div className="detail-row">
                      <div className="label">Price</div>
                      <div className="value price">₹{product.price}</div>
                    </div>
                    <div className="detail-row">
                      <div className="label">Location</div>
                      <div className="value">{product.location}</div>
                    </div>
                    <div className="detail-row">
                      <div className="label">Category</div>
                      <div className="value">{product.category}</div>
                    </div>
                  </div>

                  <button className="view-button" onClick={() => handleViewProduct(product.productId)}>
                    View Ad
                    <span className="arrow">→</span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
{/* 
        <div className="bottom-buttons">
          <button className="demo-button">Try free demo</button>
          <button className="buy-button">Buy Listivo</button>
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default Comparison;
