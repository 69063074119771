import React, { useState ,useEffect} from "react";
import axios from "axios";
import "../css/Contact.css";
import Navbar from "./navbar";
import Footer from "./footer";
import ContactUs from "../Assets/ContactUs.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faPhone, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from 'react-router-dom';
const Contact = () => {
  const navigate=useNavigate()
   useEffect(() => {
      window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!formData.email.endsWith("@gmail.com")) {
      newErrors.email = "Email must end with @gmail.com.";
    }
    if (!/^[0-9]{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits long.";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message cannot be empty.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/contact-us`, formData);
      alert(response.data.message || "Message sent successfully!");
      setFormData({ name: "", email: "", phone: "", message: "" });
      setErrors({});
    } catch (error) {
      alert(error.response?.data?.message || "Something went wrong. Please try again.");
      setFormData({ name: "", email: "", phone: "", message: "" });
    }
  };

  return (
    <>
      <Navbar />
      <div className="contact-container">
        <div className="contact-header">
          <img src={ContactUs} alt="Contact Us" className="contact-image" />
          <h1 className="contact-title">
            Contact <span className="contact-title-span">Us</span>
          </h1>
          <div className="contact-breadcrumb">
            <span className="home-cont" onClick={()=>{navigate('/')}}>Home</span> &gt; <span>Contact Us</span>
          </div>
        </div>

        <div className="contact-content">
          <div className="contact-left">
            <h2 className="contact-subtitle">We Are Ready to Help</h2>
            <p className="contact-text">
              Thank you ever so much for gracing our online rental marketplace
              with your presence. We would be honored to address any inquiries or
              reservations you may have at{" "}
              <a href="mailto:info@example.com" className="contact-link">
                info@goleezy.in
              </a>
              . Our distinguished office is situated in the charming city of
              Ludhiana, and we would relish the opportunity to cater to your
              every need. It is our privilege to serve you, and we eagerly
              anticipate your correspondence.
            </p>
            <div className="contact-socials">
              <FontAwesomeIcon icon={faLinkedin} />
              <FontAwesomeIcon icon={faInstagram} />
              <FontAwesomeIcon icon={faYoutube} />
            </div>
          </div>

          <div className="contact-right">
            <h2 className="contact-subtitle">Send a Message</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group fg-v1">
                <input
                  type="text"
                  name="name"
                  placeholder="Name *"
                  className="form-input"
                  value={formData.name}
                  onChange={handleChange}
                />
                <FontAwesomeIcon icon={faUser} className="form-icon" style={{top:errors.name?"40%":"50%"}}/>
                {errors.name && <span className="error-message">{errors.name}</span>}
              </div>

              <div className="form-group fg-v1">
                <input
                  type="email"
                  name="email"
                  placeholder="Email *"
                  className="form-input"
                  value={formData.email}
                  onChange={handleChange}
                />
                <FontAwesomeIcon icon={faEnvelope} className="form-icon" style={{top:errors.email?"40%":"50%"}}/>
                {errors.email && <span className="error-message">{errors.email}</span>}
              </div>
              <div className="form-group fg-v1">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  className="form-input"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <FontAwesomeIcon icon={faPhone} className="form-icon" style={{top:errors.phone?"40%":"50%"}}/>
                {errors.phone && <span className="error-message">{errors.phone}</span>}
              </div>

              <div className="form-group fg-v1">
                <textarea
                  name="message"
                  placeholder="Message *"
                  className="form-input form-textarea"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {errors.message && <span className="error-message">{errors.message}</span>}
              </div>
              <button type="submit" className="contact-button">
                Send Message <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
