// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-container {
    padding: 20px;
    margin: auto;
    max-width: 900px;
    line-height: 1.6;
    color: #333;
  }
  
  .privacy-h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: #374b5c;
    font-weight: 800;
  }
  
  .privacy-h2 {
    font-size: 2rem;
    margin-top: 20px;
    color: #374b5c;
    font-weight: 800;
  }
  
  .privacy-h3 {
    font-size: 1.6rem;
    margin-top: 15px;
    color: #374b5c;
    font-weight: 800;
  }
  
  .privacy-p {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #73818c;
    text-align: justify;
  }
  
  .privacy-a {
    color: #306816;
    text-decoration: none;
  }
  
  .privacy-a:hover {
    text-decoration: underline;
  }
  
  .privacy-section {
    margin-bottom: 20px;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 15px;
    }
  
    .privacy-h1 {
      font-size: 2rem;
    }
  
    .privacy-h2 {
      font-size: 1.5rem;
    }
  
    .privacy-h3 {
      font-size: 1.2rem;
    }
  
    .privacy-p {
      font-size: 0.9rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/css/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,mBAAmB;EACrB;;EAEA,uBAAuB;EACvB;IACE;MACE,aAAa;IACf;;IAEA;MACE,eAAe;IACjB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;EACF","sourcesContent":[".privacy-policy-container {\n    padding: 20px;\n    margin: auto;\n    max-width: 900px;\n    line-height: 1.6;\n    color: #333;\n  }\n  \n  .privacy-h1 {\n    font-size: 2.5rem;\n    text-align: center;\n    margin-bottom: 20px;\n    color: #374b5c;\n    font-weight: 800;\n  }\n  \n  .privacy-h2 {\n    font-size: 2rem;\n    margin-top: 20px;\n    color: #374b5c;\n    font-weight: 800;\n  }\n  \n  .privacy-h3 {\n    font-size: 1.6rem;\n    margin-top: 15px;\n    color: #374b5c;\n    font-weight: 800;\n  }\n  \n  .privacy-p {\n    font-size: 1.2rem;\n    margin-bottom: 10px;\n    color: #73818c;\n    text-align: justify;\n  }\n  \n  .privacy-a {\n    color: #306816;\n    text-decoration: none;\n  }\n  \n  .privacy-a:hover {\n    text-decoration: underline;\n  }\n  \n  .privacy-section {\n    margin-bottom: 20px;\n  }\n  \n  /* Responsive Styling */\n  @media (max-width: 768px) {\n    .privacy-policy-container {\n      padding: 15px;\n    }\n  \n    .privacy-h1 {\n      font-size: 2rem;\n    }\n  \n    .privacy-h2 {\n      font-size: 1.5rem;\n    }\n  \n    .privacy-h3 {\n      font-size: 1.2rem;\n    }\n  \n    .privacy-p {\n      font-size: 0.9rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
