import Navbar from "./navbar";
import Footer from "./footer";
import React, { useEffect, useState } from "react";
import "../css/Package.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import siteLogo from '../Assets/siteLogo.webp'
import { PDFDocument, rgb } from "pdf-lib";
import { useLocation } from 'react-router-dom';



const Package = ({ id, title, price, features, searchnearyou, onChoosePackage }) => {

  return (
    <>
      <div className="package">
        <div className="packageHead">
          <h3>{title}</h3>
        </div>
        <div className="packageprice">
          <p className="price">₹{price}</p>
        </div>
        <div className="packagebody">
          <button onClick={() => onChoosePackage(id)}>Choose this package</button>
          <div className="packagesearch">
            <h2>{searchnearyou}</h2>
          </div>
          <ul>
            {features.map((feature, index) => (
              <li key={index}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: "8px" }} />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

const Packages = () => {
  const location = useLocation();
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/order/packages`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          setPackages(response.data);
        }
      } catch (error) {
        console.error("Error fetching packages:", error);
        setError("Failed to load packages. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  const handleChoosePackage = async (id) => {
    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get('prodId'); // Get the 'id' query parameter

  if(productId){
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/order/packages/${id}`, {
        withCredentials: true,
      });

      if (response.status === 200) {
        console.log(response.data," selectedPkg")
        setSelectedPackage(response.data);
       
      }
    } catch (error) {
      console.error("Error fetching package details:", error);
      alert("Failed to fetch package details. Please try again.");
    }
  }else{
    alert("Please select a product first From My ads by hitting promote button for particular product")
  }
  };

useEffect(()=>{
  if(selectedPackage && selectedPackage!=null){
   createRazorpayOrder()
  }
},[selectedPackage])

  const createRazorpayOrder = () => {
 
  
   
    let data = JSON.stringify({
      amount: selectedPackage.price* 100,
      currency: "INR",
      id:selectedPackage.id
    })
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACK_URL}/api/order/gateway/payment`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data,
      withCredentials: true 
    }
  
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data))
      if(response.status===200)
      {
      handleRazorpayScreen(response)
      
      // paymentFetch('randomNumber12345')
      }else{
        // failed
        alert('failed')
      }
    })
    .catch((error) => {
      console.log("error at", error)
    })
  }

  const [responseId,setResponseId]=useState()

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script);
    })
  }
  
  const handleRazorpayScreen = async(response) => {
    const { key_id, amount} = response.data;
    const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js")
  
    if (!res) {
      alert("Some error at razorpay screen loading")
      return;
    }
  
    const options = {
      key: key_id,
      amount: amount,
      currency: 'INR',
      name: selectedPackage.name,
      description: `payment to Goleezy`,
      image:`${siteLogo}`,
      handler: function (response){
        setResponseId(response.razorpay_payment_id)
        paymentFetch(response.razorpay_payment_id)
      },
      prefill: {
        name: "Goleezy",
        email: `${process.env.REACT_APP_EMAIL}`
      },
      theme: {
        color: "#F4C430"
      }
    }
  
    const paymentObject = new window.Razorpay(options)
    paymentObject.open()
  }

  const [userData, setUserData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);

  const paymentFetch = (data) => {
  
    const paymentId =data;
    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get('prodId'); // Get the 'id' query parameter

    console.log(data)
    const packageId=selectedPackage.id;
  
    axios.get(
      `${process.env.REACT_APP_BACK_URL}/api/order/gateway/payment/${paymentId}`,
      {
        params: {packageId,productId}, // Sending the additional field in the query
        withCredentials: true,
      }
    )
      .then((response) => {
      console.log(response.data);
      if(response.status && response.data.paymentStatus==='authorized' && response.data.order.status==='completed')
      {
        // handleConfirmationResponse()
        setOrderData(response.data.order)
        setUserData(response.data.user)
      }else{
        alert('Something went wrong')
      }
    })
    .catch((error) => {
      console.log("error occures", error)
    })
  }

  useEffect(()=>{
    if(userData){
        createPDF()
    }
  },[userData,setUserData])

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits for day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits for month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  
  // Example usage


  const createPDF = async () => {
    try {
      const pdfUrl = "/assets/invoice.pdf";
      const existingPdfBytes = await fetch(pdfUrl).then((res) => {
        if (!res.ok) throw new Error("Failed to fetch the PDF file");
        return res.arrayBuffer();
      });
  
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const { height,width } = firstPage.getSize();

      
     // **************** for Invoice uppr part **************

     firstPage.drawText(`${orderData.id.slice(1)}`, {
      x: 120,
      y: height-89,
      size: 24,
      // font: boldFont,
      color: rgb(0, 0, 0),
    });


    let xPositionForInvoUppr = 180;
      let yPositionForInvoUppr = height-140;
      const lineHeightInvoUppr = 18; // Space between lines
  
      // Draw Display Name
      firstPage.drawText(`${orderData.id.slice(1)}`, {
        x: xPositionForInvoUppr,
        y: yPositionForInvoUppr,
        size: 12,
        color: rgb(0, 0, 0),
      });
  
      yPositionForInvoUppr -= lineHeightInvoUppr;

     
      firstPage.drawText(`${orderData.createdAt}`, {
        x: xPositionForInvoUppr,
        y: yPositionForInvoUppr,
        size: 12,
        color: rgb(0, 0, 0),
      });

      yPositionForInvoUppr -= lineHeightInvoUppr;

      
      firstPage.drawText(`${orderData.transactionId}`, {
        x: xPositionForInvoUppr,
        y: yPositionForInvoUppr,
        size: 12,
        color: rgb(0, 0, 0),
      });

      yPositionForInvoUppr -= lineHeightInvoUppr;

      // Draw Address Parts
      firstPage.drawText(`${orderData.paymentMethod}`, {
        x: xPositionForInvoUppr,
        y: yPositionForInvoUppr,
        size: 12,
        color: rgb(0, 0, 0),
      });




      // **************** for seller **************
      const seller = {
        name: "Shubham Sharma",
        address: {
          part1: "Curepipe, Ludhiana",
          part2: "Curepipe, 141010, Mauritius",
        },
        email: "subamsharmaa@gmail.com",
        phone: "9821592852",
      };

      // Starting position for the first line
      let xPositionForSeller = 25;
      let yPositionForSeller = 585;
      const lineHeight = 20; // Space between lines
  
      // Draw Display Name
      firstPage.drawText(`${seller.name}`, {
        x: xPositionForSeller,
        y: yPositionForSeller,
        size: 12,
        color: rgb(0, 0, 0),
      });
  
      yPositionForSeller -= lineHeight;

      // Draw Address Parts
      firstPage.drawText(`${seller.address.part1}`, {
        x: xPositionForSeller,
        y: yPositionForSeller,
        size: 12,
        color: rgb(0, 0, 0),
      });
  
      yPositionForSeller -= lineHeight;
  
      firstPage.drawText(`${seller.address.part2}`, {
        x: xPositionForSeller,
        y: yPositionForSeller,
        size: 12,
        color: rgb(0, 0, 0),
      });

      yPositionForSeller -= lineHeight;
      
      // Draw Email
      firstPage.drawText(`${seller.email}`, {
        x: xPositionForSeller,
        y: yPositionForSeller,
        size: 12,
        color: rgb(0, 0, 0),
      });

  
      yPositionForSeller -= lineHeight;
  
      // Draw Phone
      firstPage.drawText(`Phone: ${seller.phone}`, {
        x: xPositionForSeller,
        y: yPositionForSeller,
        size: 12,
        color: rgb(0, 0, 0),
      });
      

      // ************ for purchaser *************

      // Starting position for the first line
      let xPositionForPurchaser = 297;
      let yPositionForPurchaser = 585;
  
      // Draw Display Name
      firstPage.drawText(`${userData.displayName}`, {
        x: xPositionForPurchaser,
        y: yPositionForPurchaser,
        size: 12,
        color: rgb(0, 0, 0),
      });
  
      // Split the address into two parts
      const addressParts = userData.address.split(',');
      const firstPartPurchaserAddr = addressParts.slice(0, 2).join(',').trim(); // First two parts
      const secondPartPurchaserAddr = addressParts.slice(2).join(',').trim(); // Remaining parts
  
      yPositionForPurchaser -= lineHeight;

      // Draw Address Parts
      firstPage.drawText(`${firstPartPurchaserAddr}`, {
        x: xPositionForPurchaser,
        y: yPositionForPurchaser,
        size: 12,
        color: rgb(0, 0, 0),
      });
  
      yPositionForPurchaser -= lineHeight;
  
      firstPage.drawText(`${secondPartPurchaserAddr}`, {
        x: xPositionForPurchaser,
        y: yPositionForPurchaser,
        size: 12,
        color: rgb(0, 0, 0),
      });

      yPositionForPurchaser -= lineHeight;
  
      // Draw Email
      firstPage.drawText(`${userData.email}`, {
        x: xPositionForPurchaser,
        y: yPositionForPurchaser,
        size: 12,
        color: rgb(0, 0, 0),
      });

  
      yPositionForPurchaser -= lineHeight;
  
      // Draw Phone
      firstPage.drawText(`Phone: ${userData.phone}`, {
        x: xPositionForPurchaser,
        y: yPositionForPurchaser,
        size: 12,
        color: rgb(0, 0, 0),
      });



      // ********** invoice items **********

      let xPositionForInvoItem=350
      firstPage.drawText(`${orderData.packageName}`, {
        x:30,
        y: xPositionForInvoItem,
        size: 12,
        color: rgb(0, 0, 0),
      });

      const timestamp = orderData.expiredAt;
      const formattedDate = formatTimestampToDate(timestamp);

      firstPage.drawText(`${formattedDate}`, {
        x:188,
        y: xPositionForInvoItem,
        size: 12,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${orderData.packagePrice} INR`, {
        x:280,
        y: xPositionForInvoItem,
        size: 12,
        color: rgb(0, 0, 0),
      });

      

      firstPage.drawText(`${orderData.packagePrice} INR`, {
        x:width-90,
        y: xPositionForInvoItem,
        size: 12,
        color: rgb(0, 0, 0),
      });

      firstPage.drawText(`${orderData.packagePrice} INR`, {
        x:width-90,
        y:298 ,
        size: 15,
        color: rgb(0, 0, 0),
      });
  
      // Save the updated PDF
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      const file = new File([blob], "invoice.pdf", { type: "application/pdf" });
      sendInvoice(file)
  
      // Automatically trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "invoice.pdf"; // Set the name for the downloaded file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
      
    } catch (error) {
      console.error("Error updating PDF:", error);
    }
  };

  const sendInvoice = async (file) => {
    try {
      // Create a FormData object to handle file uploads
      const formData = new FormData();
      
      // Append the PDF file
      formData.append("pdf", file);
      
      // Append the order data and user data
      formData.append("orderData", JSON.stringify(orderData));
      formData.append("userData", JSON.stringify(userData));
      
      // Call the API
      const response = await axios.post(
        `${process.env.REACT_APP_BACK_URL}/api/order/send-invoice`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true, // Send cookies along with the request
        }
      );
  
       console.log(response.data," sendInvoice")
    } catch (error) {
      console.error("Error sending invoice:", error);
  
    }
  };



  if (loading) return <p>Loading packages...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <Navbar />
      <div className="packages-container">
        <div className="headingprofile">
          <h1>Purchase New Package</h1>
        </div>
        {packages.map((pkg) => (
          <Package
            key={pkg.id}
            id={pkg.id}
            title={pkg.name}
            price={pkg.price}
            features={[
              `Duration: ${pkg.duration}`,
              `Featured: ${pkg.featured}`,
              `Bump up: ${pkg.bumpUp}`,
              `Bump up every: ${pkg.bumpUpEvery}`,
            ]}
            searchnearyou={`Top of the search near you for ${pkg.duration}`}
            onChoosePackage={handleChoosePackage}
          />
        ))}
        {/* {selectedPackage && (
          <div className="selected-package">
            <h2>Selected Package</h2>
            <p>Name: {selectedPackage.name}</p>
            <p>Price: ₹{selectedPackage.price}</p>
            <p>Duration: {selectedPackage.duration}</p>
            <p>Featured: {selectedPackage.featured}</p>
            <p>Bump Up: {selectedPackage.bumpUp}</p>
            <p>Bump Up Every: {selectedPackage.bumpUpEvery}</p>
          </div>
        )} */}
      </div>
      <Footer />
    </>
  );
};

export default Packages;
