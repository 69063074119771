import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ProductCard from './productCardSearch';
import axios from 'axios';
import compare from "../Assets/git-compare-svgrepo-com.svg";
import CompareView from './compare-view';
import Footer from './footer';
import Navbar from './navbar';
import useStore from '../components/authmiddle/store';
// import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const ProductList = () => {
  const { state } = useLocation(); // Get state from the URL
  const [activeCategory, setActiveCategory] = useState('All');
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(state?.query || ''); // Get query from location state
  const [location, setLocation] = useState(''); // State for location input
  const [distance, setDistance] = useState('+16 km'); // State for distance dropdown
  const [fetchedIds, setFetchedIds] = useState([]); // Track fetched product IDs
  const productListRef = useRef(null);
  const [productCardWidth, setProductCardWidth] = useState(0);
  const firstProductCardRef = useRef(null);
  const [compareProducts, setCompareProducts] = useState([]);
  const [showComparison, setShowComparison] = useState(false);
  const { isAuthenticated } = useStore();
  const [loading, setLoading] = useState(false); // State for loading indicator
 const [queryResults, setQueryResults] = useState([]);
 const [suggestions, setSuggestions] = useState([]);
 const [lat, setLat] = useState(null); // State to store latitude
 const [lng, setLng] = useState(null); // State to store longitude
 const [categoryList,setCategoryList]=useState([])


 // Fetch suggestions for the entered location
 const fetchSuggestions = async (input) => {
   if (!input) {
     setSuggestions([]);
     return;
   }

   const autocompleteService = new window.google.maps.places.AutocompleteService();

   autocompleteService.getPlacePredictions(
     { input, types: ['(cities)'] },
     (predictions, status) => {
       if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
         const results = predictions.map((prediction) => ({
           description: prediction.description,
           place_id: prediction.place_id,
         }));
         setSuggestions([{ description: 'My Location', place_id: 'my-location' }, ...results]);
       }
     }
   );
 };

// Fetch coordinates for a selected location
const fetchCoordinates = async (placeId) => {
  if (placeId === 'my-location') {
    if (!navigator.geolocation) {
      alert('Geolocation is not supported by your browser.');
      return;
    }
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;
        setLat(latitude); // Update latitude state
        setLng(longitude); // Update longitude state

        // Use reverse geocoding to get the actual address
        try {
          const geocoder = new window.google.maps.Geocoder();
          const response = await geocoder.geocode({
            location: { lat: latitude, lng: longitude },
          });
          
          if (response.results && response.results[0]) {
            fetchProducts(query, latitude,longitude, [],1,distance,[]);
            setLocation(response.results[0].formatted_address); // Set actual address in the search field
          } else {
            setLocation('Unable to fetch location address');
          }
        } catch (error) {
          console.error('Error fetching address:', error);
          setLocation('Error retrieving address');
        }
      },
      (error) => {
        console.error('Error fetching location:', error);
        alert('Unable to fetch your location.');
      }
    );
  } else {
    try {
      
      const geocoder = new window.google.maps.Geocoder();
      const response = await geocoder.geocode({ placeId });
      const { lat, lng } = response.results[0].geometry.location;
      console.log(lat(),lng())
      let latitude=lat()
      let longitude=lng()
      fetchProducts(query, latitude,longitude, [],1,distance,[]);
      setLat(lat()); // Update latitude state
      setLng(lng()); // Update longitude state
      setLocation(response.results[0].formatted_address); // Set actual address
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  }
};





 // Handle suggestion click
 const handleSuggestionClick = (suggestion) => {
   setSuggestions([]);
   fetchCoordinates(suggestion.place_id);
 };

 // Handle input change
 const handleLocationChange = (e) => {
   const input = e.target.value;
   setLocation(input);
   fetchSuggestions(input);
 };


  const fetchData = async (query) => {
    try {
        if (!query) {
            setQueryResults([]);
            return;
        }
        const response = await axios.get(
            `${process.env.REACT_APP_BACK_URL}/api/prod/get-products?data=${query}`
        );
        setQueryResults(response.data.results || []);
    } catch (error) {
        console.error("Error fetching search results:", error);
    }
};




  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const handleCompare = (product) => {
    if (compareProducts.find(p => p.productId === product.productId)) {
      setCompareProducts(compareProducts.filter(p => p.productId !== product.productId));
    } else if (compareProducts.length < 3) {
      setCompareProducts([...compareProducts, product]);
    } else {
      alert('You can compare up to 3 products');
    }
  };

  useEffect(() => {
    if (firstProductCardRef.current) {
      setProductCardWidth(firstProductCardRef.current.clientWidth);
    }
  }, []);

  const removeFromComparison = (productId) => {
    setCompareProducts(compareProducts.filter(p => p.productId !== productId));
  };



  const fetchProducts = async (searchQuery, lat,lng, fetchedIdsList,page,distance,categoryListArray) => {
    console.log(fetchedIdsList," ids before call ",fetchedIds)
    // alert(page)
    setPage(page)
    if (loading) return; // Prevent fetch if already loading

    if (!searchQuery && !lat) {
      alert('Please fill at least one field (query or location).');
      return;
    }

    setLoading(true); // Set loading to true before fetching
    try {
      let response;
      
      const params = {
        query: searchQuery || undefined,
        lat: lat || undefined,
        lng:lng || undefined,
        page,
        distance,
        fetchedIds: fetchedIdsList.join(','), // Pass as a comma-separated string
        categoryList:categoryListArray.join(','),
      };

      if (!isAuthenticated) {
        response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/prod/fetchProdForSearch`, { params });
      } else {
        response = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/prod/fetchProdForSearchauthenticated`, params, {
          withCredentials: true, // This will include cookies and other credentials with the request
        });
      }

      const { data, fetchedIds,categoryList } = response.data;
      console.log(fetchedIds," get from backend",fetchedIds)
      if (page === 1) {
        setProducts(data);
        setFetchedIds(fetchedIds)
        setCategoryList(categoryList)
        console.log("ye update ho rha page 1")
      } else {
        setProducts(prevProducts => [...prevProducts, ...data]);
        setFetchedIds(prevIds => [...new Set([...prevIds, ...fetchedIds])]);
        setCategoryList(prevIds => [...new Set([...prevIds, ...categoryList])])
        console.log("purane mein addd")
      }

      // Only update fetched IDs if there are new IDs to add
      

    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(()=>{
       console.log(products," useEffect prod")
  },[products])

  useEffect(()=>{
     console.log(fetchedIds," useEfffect")
  },[fetchedIds])

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      if (field === 'query') {
        
        setFetchedIds([])
        setCategoryList([])
        setQueryResults([]);
        setProducts([])
        fetchProducts(query, lat,lng, [],1,distance,[]);
      } else if (field === 'location') {
      
        setFetchedIds([])
        setCategoryList([])
        setQueryResults([]);
        setProducts([])
        fetchProducts(query, lat,lng, [],1,distance,[]);
      }
    }
  };


  const loadMore = () => {
    if (products.length > 0) {
      setPage(prevPage => prevPage + 1);

    }
  };

  useEffect(()=>{
    if(page>1)
    {
      fetchProducts(query, lat,lng, fetchedIds,page,distance,categoryList);
    }

  },[page])



  const clearInput = (field) => {
    if (field === 'query')
      {
         setQuery('');
         setQueryResults([])
      }
    
    if (field === 'location')
      { 
         setLocation('');
         setLat(null)
         setLng(null)
      }
  };

  // useEffect(() => {
  //   if(page<=1){
  //   (query || (lat && lng) ) {
  //     setFetchedIds([])
  //     fetchProducts(query, lat,lng, []);
  //   }
  // }
  // }, [query, lat,lng,distance]); 

  useEffect(()=>{
    if(query)
    {
      fetchProducts(query, lat,lng, [],1,distance,[]);
    }

  },[])


  const handleQueryChange=(e)=>{
    const value=e.target.value;
    setQuery(value);
    fetchData(value);
    // setProducts([])
  }

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? (
            <span key={index} className="highlight">{part}</span>
        ) : (
            part
        )
    );
};

const handleQueryResultClick = (result) => {
  setQuery(result);
  setQueryResults([]);
  setCategoryList([])
  setFetchedIds([])
  fetchProducts(result, lat,lng, [],1,distance,[]);
};

const handleDistanceChange=(e)=>{
  const dist=e.target.value;
  setDistance(dist);
  if(location && location!=''){
    setCategoryList([])
    setFetchedIds([])
    fetchProducts(query, lat,lng, [],1,dist,[]);
   
  }
}
  return (
    <>
      <Navbar />

      <div className="search-box-container">
        {/* Query Input */}
        <div className="search-box">
          <span className="search-icon">💡</span>
          <input
            type="text"
            placeholder="Search for a product"
            className="search-input"
            value={query}
            onChange={(e) => handleQueryChange(e)}
            onKeyDown={(e) => handleKeyDown(e, 'query')}
          />
          <span className="clear-icon" onClick={() => clearInput('query')}>✖</span>

          {queryResults.length > 0 && (
                        <div className="query-results-wrapper">
                            {queryResults.map((result, index) => (
                                <div
                                    key={index}
                                    className="result-item"
                                    onClick={() => handleQueryResultClick(result)}
                                >
                                    <h4 className="result-title">
                                        {highlightMatch(result, query)}
                                    </h4>
                                </div>
                            ))}
        </div>)}
        </div>

       
      <div className="search-box">
        <span className="search-icon">📍</span>
        <input
          type="text"
          placeholder="Enter location"
          className="search-input"
          value={location}
          onChange={handleLocationChange}
        />
        <span className="clear-icon" onClick={() => setLocation('')}>✖</span>
        {suggestions.length > 0 && (
          <ul className="suggestions-list1">
            {suggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion.description}
              </li>
            ))}
          </ul>
        )}
        <select
          className="distance-dropdown"
          value={distance}
          onChange={handleDistanceChange}
        >
          <option value="+16 km">+16 km</option>
          <option value="+32 km">+32 km</option>
          <option value="+48 km">+48 km</option>
          <option value="+80 km">+80 km</option>
          <option value="+120 km">+120 km</option>
          <option value="+160 km">+160 km</option>
          <option value="+320 km">+320 km</option>
          <option value="+800 km">+800 km</option>
        </select>
      </div>
     
    </div>
 

      <div className='product-Supreme1'>
        <div className="product-list1-container">
          {compareProducts.length > 1 && (
            <button
              className="compare-button"
              onClick={() => setShowComparison(true)}
              disabled={compareProducts.length < 2}
            >
              <img src={compare} alt="Compare" className="compare-icon" />
              Compare ({compareProducts.length})
            </button>
          )}
          <div className="product-list-wrapper">
            <div className="product-list1" ref={productListRef}>
              {products.map((product, index) => {
                const parsedImages = typeof product.images === "string" ? JSON.parse(product.images) : product.images;
                return (
                  <ProductCard
                    key={product.productId}
                    ref={index === 0 ? firstProductCardRef : null}
                    images={parsedImages}
                    name={product.adName}
                    address={product.location}
                    price={product.price}
                    views={product.views}
                    category={product.category}
                    Priority={product.Priority}
                    isliked={product.isLiked}
                    postDate={product.postDate}
                    initialLikes={product.likes}
                    BumpUp={product.bumpUp}
                    email={product.email}
                    expiredAT={product.expiredAt}
                    productId={product.productId}
                    onCompare={() => handleCompare({
                      productId: product.productId,
                      adName: product.adName,
                      images: parsedImages,
                      price: product.price,
                      category: product.category,
                      location: product.location
                    })}
                    isCompared={compareProducts.some(p => p.productId === product.productId)}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {showComparison && compareProducts.length > 1 && (
          <CompareView
            products={compareProducts}
            onClose={() => setShowComparison(false)}
            onRemove={removeFromComparison}
          />
        )}
        <div className="load-more-container">
          <button className="load-more-button" onClick={loadMore} disabled={loading}>
            {loading ? "Loading..." : "Load More"}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductList;
