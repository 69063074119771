import React, { useState } from 'react';
import '../css/ProfileHeading.css';
import { useNavigate } from 'react-router-dom';

const  ProfileHeading = ({ activeTab = 'Add New',isButton=true }) => {
  const navigate=useNavigate()
  const [activeButton, setActiveButton] = useState('myPackages');
  
const handleMyPackage=()=>{
  // setActiveButton('myPackages');
  // console.log("hello")
  navigate("/profile", { state: { fromButton: "My Orders" } });
}

const handleBuyPackage=()=>{
  navigate('/package')
}
  return (
    <div className="settings-container">
      <h2 className="settings-title">{activeTab}</h2>
      {isButton &&<div className="settings-buttons">
        <button
          className={`settings-button ${activeButton === 'myPackages' ? 'active' : ''}`}
          onClick={() => handleMyPackage()}
        >
          My Packages
        </button>
        <button
          className={`settings-button ${activeButton === 'buyPackage' ? 'active' : ''}`}
          onClick={()=>{handleBuyPackage()}}
        >
          Buy the Package
        </button>
      </div>}
    </div>
  );
};

export default ProfileHeading;
