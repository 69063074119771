import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCellsLarge, faChevronDown, faBars } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import FavoriteCard from '../../components/FavoritePageCard';
import '../../css/favorites.css';

function Favorites() {
    const [sortOption, setSortOption] = useState('Newest');
    const [isListView, setIsListView] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [results, setResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.post(
                    `${process.env.REACT_APP_BACK_URL}/api/favourite`,
                    {},
                    { withCredentials: true }
                );
                const products = response.data?.products;
                if (Array.isArray(products)) {
                    setResults(products);
                } else {
                    console.error("Unexpected response format:", response.data);
                    setResults([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setResults([]);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const handleSortChange = (value) => {
        setSortOption(value);
        setIsOpen(false);
        // Add sorting logic here if needed
    };

    const toggleView = (view) => {
        setIsListView(view === 'list');
    };

    return (
        <div className="favorites">
            <div className="favorites-header">
                <span className="favorites-result-count">
                    {isLoading ? 'Loading...' : `${results.length} Results`}
                </span>
                <div className="favorites-header-right">
                    <div className="favorites-sort-options">
                        <label htmlFor="favorites-sort-select" className="favorites-sort-select-span">
                            Sort by:
                        </label>
                        <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
                            <div className="selected-option">{sortOption}</div>
                            {isOpen && (
                                <ul className="custom-select-options">
                                    <li onClick={() => handleSortChange('Newest')}>Newest</li>
                                    <li onClick={() => handleSortChange('Oldest')}>Oldest</li>
                                </ul>
                            )}
                            <FontAwesomeIcon icon={faChevronDown} className="favorites-select-icon" />
                        </div>
                    </div>
                    <div className="favorites-view-toggle">
                        <button
                            className={`favorites-toggle-button ${!isListView ? 'favorites-active' : ''}`}
                            onClick={() => toggleView('grid')}
                        >
                            <FontAwesomeIcon icon={faTableCellsLarge} className="favorites-ico" />
                        </button>
                        <button
                            className={`favorites-toggle-button ${isListView ? 'favorites-active' : ''}`}
                            onClick={() => toggleView('list')}
                        >
                            <FontAwesomeIcon icon={faBars} className="favorites-ico" />
                        </button>
                    </div>
                </div>
            </div>
            <div className={`favorite-list ${isListView ? 'list-view' : 'grid-view'}`}>
                {!isLoading && results.length === 0 ? (
                    <p className="no-favorites">No favourites found.</p>
                ) : (
                    results.map((result, index) => (
                        <FavoriteCard key={index} data={result} />
                    ))
                )}
            </div>
        </div>
    );
}

export default Favorites;

