import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Cursor from "../src/pages/cursorfollower"
import reportWebVitals from './reportWebVitals';
import Chatbot from './pages/chatbot';
// import 'bootstrap/dist/css/bootstrap.min.css';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // <React>
//   <>
//   <Cursor/>
//     <App />
//     </>
//   // </React>
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <>
  <Cursor/>
    <App />
    <Chatbot/>
    </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
