

import React, { useState, useEffect } from "react";
import '../css/navbar.css';
import VoiceIconImage from '../Assets/voice-icon.png';
import SearchIconImage from '../Assets/searching-magnifying-glass.png';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const SearchComponent = () => {
    const [searchText, setSearchText] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [showBorder, setShowBorder] = useState(false);
    const [gifUrl, setGifUrl] = useState('');
    const [results, setResults] = useState([]);
    const [selectedResult, setSelectedResult] = useState(null);  // Store the selected result
    const [placeholders, setPlaceholders] = useState([]);
    const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);

    useEffect(() => {
        const fetchPlaceholders = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACK_URL}/api/placeholders`);
                const fetchedPlaceholders = response.data.map((item) => item.text);
                setPlaceholders(fetchedPlaceholders);
            } catch (error) {
                console.error("Error fetching placeholder texts:", error);
            }
        };

        fetchPlaceholders();
    }, []);

    useEffect(() => {
        if (placeholders.length > 0) {
            const interval = setInterval(() => {
                setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
            }, 3000); // Change placeholder every 3 seconds
            return () => clearInterval(interval);
        }
    }, [placeholders]);


    useEffect(() => {
        if (showBorder) {
            const timer = setTimeout(() => {
                setShowBorder(false);
                setGifUrl('none');
            }, 6500);

            return () => clearTimeout(timer);
        } else if (!isFocused) {
            setShowBorder(true);
            setGifUrl(`https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/searchbar_border4.gif?${new Date().getTime()}`);
        }
    }, [showBorder, isFocused]);

    const handleFocus = () => {
        setGifUrl('none');
        setIsFocused(true);
        setShowBorder(false);
    };

    const handleBlur = () => {
        setGifUrl(`https://akam.cdn.jdmagicbox.com/images/icontent/newwap/web2022/searchbar_border4.gif?${new Date().getTime()}`);
        setShowBorder(true);
        setIsFocused(false);
    };

    const handleVoiceInput = () => {
        if (!('webkitSpeechRecognition' in window)) {
            alert("Your browser does not support voice recognition.");
            return;
        }

        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = "en-US";
        recognition.continuous = false;
        recognition.interimResults = false;

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setSearchText(transcript);
            fetchData(transcript);
        };

        recognition.onerror = (error) => {
            console.error("Voice recognition error:", error);
        };

        recognition.start();
    };

    const fetchData = async (query) => {
        try {
            if (!query) {
                setResults([]);
                return;
            }
            const response = await axios.get(
                `${process.env.REACT_APP_BACK_URL}/api/prod/get-products?data=${query}`
            );
            setResults(response.data.results || []);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    };


    const handleChange = (e) => {
      
        const value = e.target.value;
        setSearchText(value);
        fetchData(value);
    };

    const navigate=useNavigate()
    const handleResultClick = (result) => {
        setSelectedResult(result);  // Store the selected result
        navigate("/search", { state: { query: result } });  // Pass selected result to search page
    };

    const highlightMatch = (text, query) => {
        if (!query) return text;
        const parts = text.split(new RegExp(`(${query})`, "gi"));
        return parts.map((part, index) =>
            part.toLowerCase() === query.toLowerCase() ? (
                <span key={index} className="highlight">{part}</span>
            ) : (
                part
            )
        );
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (searchText.trim()) {
                navigate("/search", { state: { query: searchText.trim() } });
            }
        }
      };

    return (
        <div className="OneBefo">
            <div className={`${showBorder ? 'twoBefore' : 'twoBefore'}`} style={{ backgroundImage: `url(${gifUrl})` }}>
                <div
                    className={`search-container-mobile ${isFocused ? 'no-animation' : ''}`}
                    style={{ border: isFocused ? '1px solid #308616' : '0.8px solid #717171' }}
                >
                    <img
                        style={{ height: '20px' }}
                        src={SearchIconImage}
                        alt="search-icon"
                        className="icon search-icon"
                    />
                    <input
                        className="search-input-mobile"
                        // placeholder="Find cars, bikes, freelancers..."
                        placeholder={
                                placeholders.length > 0
                                    ? `Search "${placeholders[currentPlaceholderIndex]}"`
                                    : "Search ..."
                            }
                        value={searchText}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                    />
                    <img
                        style={{ height: '20px' }}
                        src={VoiceIconImage}
                        alt="voice-icon"
                        className="icon mic-icon-mobile"
                        onClick={handleVoiceInput}
                    />
                </div>
            </div>
            {results.length > 0 && (
                        <div className="results-wrapper">
                            {results.map((result, index) => (
                                <div
                                    key={index}
                                    className="result-item"
                                    onClick={() => handleResultClick(result)}  // Handle result click
                                >
                                    <h4 className="result-title">
                                        {highlightMatch(result, searchText)}
                                    </h4>
                                </div>
                            ))}
                        </div>
                    )}
        </div>
    );
};

export default SearchComponent;
