import React, { useEffect, useState } from "react";
import "../css/cursorfollower.css";

const CursorFollower = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [dotPosition, setDotPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    let animationFrameId;

    const animate = () => {
      setDotPosition((prev) => ({
        x: prev.x + (position.x - prev.x) * 0.04, // Higher multiplier for faster response
        y: prev.y + (position.y - prev.y) * 0.04,
      }));
      animationFrameId = requestAnimationFrame(animate); // Continuously request next frame
    };

    animate(); // Start the animation loop
    return () => cancelAnimationFrame(animationFrameId); // Cleanup on unmount
  }, [position]);

  return (
    <div
      className="cursor-dot"
      style={{
        left: `${dotPosition.x}px`,
        top: `${dotPosition.y}px`,
      }}
    ></div>
  );
};

export default CursorFollower;
