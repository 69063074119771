import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faComment, faAt, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../css/auth.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TermsConditions from './TermsConditions.js';
import useStore from './authmiddle/store.js';
export default function Register() {
  const setIsAuthenticated = useStore((state) => state.setIsAuthenticated);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [toggleOtp, setToggleOtp] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [emailOtp, setEmailOtp] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timer, setTimer] = useState(0);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const startOtpTimer = () => {
    if(isTimerRunning)
    {
      return;
    }
    setTimer(80); // Set timer to 80 seconds
    setResendEnabled(false);
    setIsTimerRunning(true);
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setResendEnabled(true);
          setIsTimerRunning(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleResendOtp = () => {
    handleGetPhoneOtp(); // Re-request OTP
    setOtp(''); // Clear the OTP input field
    setEmailOtp('');
  };

  const handleVerifyPhone = async () => {
    if (!otp || !emailOtp) {
      setErrors({ otp: !otp ? 'OTP is required.' : '', emailOtp: !emailOtp ? 'Email OTP is required.' : '' });
      return;
    }

    try {
      const result = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/verify-otp-register`, {
        identifier: phone,
        otpCode: otp,
        otpCodeEmail: emailOtp,
        email,
        name,
        password
      }, { withCredentials: true });
      console.log(result);
      if (result.status === 200) {
        // Redirect to home or dashboard
        setIsAuthenticated(true)
        // alert('You are Registered Successfully!')
        navigate('/');
    }
    } catch (error) {
      if(error.response.data.message==='phone')
      {
        setErrors({ ...errors, otp: 'Invalid Otp' });
     
      }else if(error.response.data.message==='email')
      {
        setErrors({ ...errors, emailOtp: 'Invalid Otp' });
      }
      else{
      console.error("Error verifying OTP!", error);
      window.location.reload();
      }
    }
  };

  const isPasswordValid = (password) => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern.test(password);
  };

  const handleGetPhoneOtp = async (e) => {
    if (!phone || !password || !email || !name) {
      setErrors({
        phone: !phone ? 'Phone is required.' : '',
        password: !password ? 'Password is required.' : '',
        email: !email ? 'Email is required.' : '',
        name: !name ? 'Name is required.' : ''
      });
      return;
    }

    if (!isPasswordValid(password)) {
      setErrors({ ...errors, password: 'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.' });
      return;
    }

    if (!agreedToTerms) {
      setErrors({ ...errors, terms: 'You must accept the Terms and Conditions to proceed.' });
      return;
    }

    try {
      if(!isTimerRunning){
      const result = await axios.post(`${process.env.REACT_APP_BACK_URL}/api/auth/get-phone-email-otp`, { phone, email }, { withCredentials: true });
      console.log(result);
      if(result.status===201)
      {
      setToggleOtp(true);
      setDisableFields(true);
      startOtpTimer();
      }
    }
    } catch (error) {
      console.error("Error getting OTP!", error);
      window.location.reload();
    }
  };

  const handlePhoneChange = (e) => {
    setErrors({ ...errors, phone: '' });
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
    }
  };

  return (
    <>
    <div className="input-group">
        <input
          type="name"
          placeholder=" "
          className={`input-field ${errors.name ? 'input-error' : ''}`}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setErrors({ ...errors, name: '' });
          }}
          disabled={disableFields}
        />
        <label className={`${errors.name? 'label-error-floating':'label-floating' }`}>Name</label>
        {errors.name && <p className="error-message">{errors.name}</p>}
      </div>

      <div className="input-group">
        <input
          type="email"
          placeholder=" "
          className={`input-field ${errors.email ? 'input-error' : ''}`}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrors({ ...errors, email: '' });
          }}
          disabled={disableFields}
        />
        <label className={`${errors.email? 'label-error-floating':'label-floating' }`}>Email</label>
        {errors.email && <p className="error-message">{errors.email}</p>}
      </div>

      <div className="input-group">
        <input
          type="text"
          placeholder=" "
          className={`input-field ${errors.phone ? 'input-error' : ''}`}
          value={phone}
          onChange={handlePhoneChange}
          disabled={disableFields}
        />
        <label className={`${errors.phone? 'label-error-floating':'label-floating' }`}>Phone</label>
        {errors.phone && <p className="error-message">{errors.phone}</p>}
      </div>

      <div className="input-group">
        <input
          type="password"
          placeholder=" "
          className={`input-field ${errors.password ? 'input-error' : ''}`}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setErrors({ ...errors, password: '' });
          }}
          disabled={disableFields}
        />
        <label className={`${errors.password? 'label-error-floating':'label-floating' }`}>Password</label>
        {errors.password && <p className="error-message">{errors.password}</p>}
      </div>

      {toggleOtp && (
        <>
          <div className="input-group">
            <input
              type="text"
              placeholder=" "
              className={`input-field ${errors.otp ? 'input-error' : ''}`}
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
                setErrors({ ...errors, otp: '' });
              }}
            />
            <label className={`${errors.otp? 'label-error-floating':'label-floating' }`}>OTP For Phone</label>
            {errors.otp && <p className="error-message">{errors.otp}</p>}
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder=" "
              className={`input-field ${errors.emailOtp ? 'input-error' : ''}`}
              value={emailOtp}
              onChange={(e) => {
                setEmailOtp(e.target.value);
                setErrors({ ...errors, emailOtp: '' });
              }}
            />
            <label className={`${errors.emailOtp? 'label-error-floating':'label-floating' }`}>OTP For Email</label>
            {errors.emailOtp && <p className="error-message">{errors.emailOtp}</p>}
          </div>
        </>
      )}

      <div className="reg-footer ">
        <label className="terms-checkbox">
          <input
            type="checkbox"
            checked={agreedToTerms}
            onChange={() => {
              setAgreedToTerms(!agreedToTerms);
              setErrors({ ...errors, terms: '' });
            }}
            style={{ marginRight: '8px' }}
          />
          I agree to the
          <span className="terms-popup-link"  onClick={() => setIsModalOpen(true)}> Terms and Conditions</span>
        </label>
        {errors.terms && <p className="error-terms">{errors.terms}</p>}
      </div>

      <TermsConditions isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

      {!toggleOtp && (
        <button className="auth-button" onClick={handleGetPhoneOtp}>
          Send OTP
        </button>
      )}
      {toggleOtp && (
        <button className="auth-button" onClick={handleVerifyPhone}>
          Verify OTP
        </button>
      )}

      {toggleOtp && timer > 0 && (
        <div className="otp-timer">
          Resend OTP in {timer} seconds
        </div>
      )}

      {toggleOtp && resendEnabled && (
        <button 
          className='auth-button resend-button' 
          onClick={handleResendOtp}
        >
          Resend OTP
        </button>
      )}
    </>
  );
}
